import styled from "styled-components"

const Wrapper = styled.div`
    padding: 0.4em 1em;
    background: #2f2f2f;
    width: fit-content;
    font-size: 28px;
    border-radius: 5px;
    margin: 0.25em auto;
    border: solid 2px #FFFFFF;
    cursor: pointer;
`

const InlineWrapper = styled.div`
    text-align: center;
    & > div {
        display: inline-block;
        margin: 0.5em;
    }
`

export const Button = (props) => {
    return <Wrapper className="cmp-button" {...props}>
        {props.children}
    </Wrapper>
}

export const Buttons = ({ children }) => {
    return <InlineWrapper>
        {children}
    </InlineWrapper>
}