import { Capacitor } from "@capacitor/core";
import "cordova-plugin-purchase"
import { RegisterProduct } from "./api";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

const allChars = [
  "bracket-boy", "jelly-princess", "combo-dia", "swallia",
  "note-eater", "pink-void", "delta-6"
]

export const InitializeIAP = () => {
  const { store, ProductType, LogLevel } = CdvPurchase // eslint-disable-line
  const products = []
  store.verbosity = LogLevel.DEBUG
  Array(...allChars).map(char => {
    products.push({
      id: ReaperKeyToProductId(char),
      type: ProductType.NON_CONSUMABLE,
      platform: getStorePlatform(),
    })
  })
  products.push(...[
    {
      id: "remove_ads",
      type: ProductType.PAID_SUBSCRIPTION,
      platform: "ios-appstore",
    },
    {
      id: "no_ads",
      type: ProductType.PAID_SUBSCRIPTION,
      platform: "android-playstore",
    }
  ])
  store.register(products);
  store.initialize([getStorePlatform()]).catch(err => {
    console.error(err)
  });
  store.ready(() => { })
}


const getStorePlatform = () => {
  return {
    'ios': "ios-appstore",
    'android': "android-playstore",
  }[Capacitor.getPlatform()] || "test"
}

export const ReaperKeyToProductId = (key) => {
  if (!key) return
  switch (getStorePlatform()) {
    case "ios-appstore":
      return key.split('-').map(w => {
        return w.charAt(0).toUpperCase() + w.slice(1)
      }).join('') + 'Character'
    case "android-playstore":
      return key.split('-').join('_') + '_character'
  }
}

const allCharProducts = allChars.map(c => ReaperKeyToProductId(c))

export const ProductIdToReaperKey = (productId) => {
  if (!productId) return
  for (let i = 0; i < allChars.length; i++) {
    if (productId === allCharProducts[i])
      return allChars[i]
  }
}

export const IsProductOwned = ({ key }) => {
  const store = CdvPurchase.store // eslint-disable-line
  return store.owned({
    id: ReaperKeyToProductId(key),
    platform: getStorePlatform()
  })
}

export const PurchaseProduct = async ({ key, unlockedChars, setUnlockedChars, onUnlock }) => {
  if (!key) return
  const { store } = CdvPurchase // eslint-disable-line
  const productId = ReaperKeyToProductId(key)
  const product = store.get(productId)?.offers[0]
  return store.order(product).then(resp => {
    if (resp?.isError) {
      console.warn(resp.message)
      return
    }
    unlockedChars[key] = true
    setUnlockedChars({ ...unlockedChars })
    RegisterProduct(productId)
    if (onUnlock) onUnlock(key)
  }).catch(err => {
    console.error(err)
  })
}

export const SubscribeProduct = async ({ productId, onUnlock, setNoAds }) => {
  if (!productId) return
  const { store } = CdvPurchase // eslint-disable-line
  const product = store.get(productId)?.offers[0]
  store.manageSubscriptions()
  return store.order(product).then(resp => {
    if (resp?.isError) {
      console.warn(resp.message)
      return
    }
    RegisterProduct(productId)
    SecureStoragePlugin.set({ key: productId, value: "true" })
    setNoAds(true)
    if (onUnlock) onUnlock(productId)
  }).catch(err => {
    console.error(err)
  })
}

export const RestoreProducts = async () => {
  const { store } = CdvPurchase // eslint-disable-line
  store.restorePurchases()
}
