import { getReaperSkillTargets, getReaperSkillDP } from "./reapers";
import { applyTrapEffects, getArtifactTargets, getTrapCardsFromHand } from "./artifact";
import { Modal, ModalBottom } from "components/modal";
import { Buttons, Button } from "components/button";
import { Cards, Card } from "components/cards";

import $ from 'jquery'
import intl from 'react-intl-universal';

import AttackMP3 from 'assets/sounds/attack.mp3'
import ErrorMP3 from 'assets/sounds/error.mp3'
import ButtonMP3 from 'assets/sounds/button.mp3'
import SelectMP3 from 'assets/sounds/select.mp3'
import TrapMP3 from 'assets/sounds/trap.mp3'

export const orbToSkillIdx = (key, randomGold) => {
    switch (key) {
        case 'green-orb':
            return 0
        case 'blue-orb':
            return 1
        case 'red-orb':
            return 2
        case 'gold-orb':
            return randomGold ? Math.floor(Math.random() * 3) : -1
    }
    return undefined
}

export const spiritToSPCount = (key, sole, ignoreProtection) => {
    switch (key) {
        case 'infinity-spirit':
            return { sp: 0, pp: Infinity }
        case 'lesser-spirit':
            return { sp: 1, pp: 0 }
        case 'half-alive-spirit':
            return { sp: 1, pp: ignoreProtection ? 0 : 1 }
        case 'half-dead-spirit':
            return sole ? { sp: 1, pp: 0 } : { sp: 0, pp: ignoreProtection ? 0 : 1, undead: true }
        case 'mini-spirit':
            return { sp: 1, pp: 0 }
        case 'ordinary-spirit':
            return { sp: 2, pp: 0 }
        case 'greater-spirit':
            return { sp: 3, pp: 0 }
        case 'snow-clown-spirit':
            return { sp: 0, pp: 0 }
        default:
            return { sp: -1, pp: -1 }
    }
}

export const cardToDPCount = (card, ignoreProtection) => {
    if (ignoreProtection) return { dp: 0 }
    if (card.original && card.type === 'orb') {
        return getReaperSkillDP[card.original.key](card.original.skillIdx)
    }
    switch (card.key) {
        case 'anti-chip-damage-charm':
            return { dp: 1 }
        case 'blade-crusher':
            return { dp: 99 }
    }
    return {}
}


export const validateMaxSpiritsRule = (field, card) => {
    const count = field.filter(c => c.type === 'spirit').length
    if (card.type === 'spirit')
        return count < 3 && count !== 0
    return false
}

export const getDragTargets = (params) => {
    const { card, reapers, username, orbReaperIdx, cardState, turnState, hands } = params;
    if (card.type === 'orb' || cardState !== undefined) {
        return getReaperSkillTargets[reapers[username][orbReaperIdx].key](params)
    }
    switch (card.type) {
        case 'spirit':
            if (turnState === 'interrupt') {
                return []
            }
            return reapers[username].map((_, i) => {
                return `#${username}_${i}`
            })
        case 'artifact':
            return getArtifactTargets({ reapers, username, card, cardState, turnState })
    }
    return false
}

export const selfReceiveDamage = ({ selfPlayerName, currPlayer, players, setCurrPlayer, hands, setHands, targetUsername, targetReaperIdx, setGallery, setGalleryIdx, turnState, setTurnState, cardParams, setCardParams, reapers, setReapers, setModalContent, setDragAndDropReminder, graveyard, setGraveyard, setPlayedCard, setHint, setDragTargets, setSfx, skipHint }) => {
    const reaper = reapers[targetUsername][targetReaperIdx]
    const cardTexts = intl.get('CARDS.reaper')

    let absorbed = 0
    const spirits = reaper.field.filter(c => c.type === 'spirit')
    for (let i = 0; i < (reaper.damageTargets || []).length; i++) {
        if (absorbed >= reaper.damage) break
        reaper.field = reaper.field.filter(c => {
            if (c.type !== 'spirit') return true
            const ct = spiritToSPCount(c.key, spirits.length === 1)
            const damagedBy = reaper.damageCausedBy
            const ignoreProtection = damagedBy ? reapers[damagedBy.username][damagedBy.reaperIdx].ignoreProtection : false
            const pp = ignoreProtection ? 0 : (ct.pp || 0)
            const isTarget = c.cardIdx === reaper.damageTargets[i].cardIdx
            const isBlocked = (absorbed + pp) >= reaper.damage && !ignoreProtection
            if (c.cardIdx === reaper.damageTargets[i].cardIdx) absorbed += pp + ct.sp
            if (isTarget && !ct.undead && !isBlocked) {
                graveyard.push({ ...c })
                return false
            }
            return true
        })
    }

    if (absorbed >= reaper.damage && reaper.discard === 0 && reaper.damageCausedBy) {
        const caster = reapers[reaper.damageCausedBy.username][reaper.damageCausedBy.reaperIdx]
        if (caster.computer) setTurnState('play-or-draw')
        reapers[targetUsername][targetReaperIdx].damage = 0
        reapers[targetUsername][targetReaperIdx].damageCausedBy = null
        setGraveyard([...graveyard])
        setReapers({ ...reapers })
        return
    }
    const choices = [...reaper.field, ...getTrapCardsFromHand(hands[selfPlayerName]).map((c) => {
        c.selectable = true
        c.mustBeSolelySelected = true
        c.trap = true
        return c
    })]
    setGraveyard([...graveyard])
    setGallery(choices)
    setGalleryIdx(0)
    setDragAndDropReminder(false)
    if (!skipHint) {
        setHint(reaper.damageCausedBy ? intl.get("HINT.attacked_by", {
            user: reaper.damageCausedBy?.username,
            damage: reaper.damage,
            reaper: intl.get('CARDS.reaper')[reaper.key].name
        }) : intl.get("HINT.effects_damage_applied", {
            user: selfPlayerName,
            damage: reaper.damage,
            reaper: intl.get('CARDS.reaper')[reaper.key].name
        }))
    }

    setModalContent(
        <Modal>
            <ModalBottom>
                <Cards className="field-cards">
                    {choices.map((c, i) => {
                        const selectable = c.type === 'spirit' || (Object.keys(cardToDPCount(c)).length > 0 && !reaper.ignoreProtection) || c.selectable
                        const spiritsCount = reaper.field.filter((sc) => sc.type === 'spirit').legnth
                        return <Card key={i} card={c} i={i} className={selectable ? "glow" : ""}
                            onClick={(e) => {
                                if (!selectable) return
                                setGalleryIdx(i)
                                const ele = $(e.target).closest('.cmp-card');
                                const eles = $('.selected')
                                let mustBeSolelySelected
                                absorbed = 0
                                eles.each((idx, ele) => {
                                    if (absorbed >= reaper.damage || mustBeSolelySelected) return
                                    const meta = JSON.parse(ele.dataset.meta);
                                    if (meta.mustBeSolelySelected) {
                                        mustBeSolelySelected = true
                                        return
                                    }
                                    const defense = meta.type === 'spirit' ? spiritToSPCount(meta.key, spiritsCount === 1, reaper.ignoreProtection) : cardToDPCount(meta, reaper.ignoreProtection)
                                    absorbed += (((defense.pp || 0)) + (defense.sp || 0) + (defense.dp || 0))
                                })
                                if (mustBeSolelySelected) {
                                    eles.not(ele).removeClass('selected')
                                } else if (absorbed >= reaper.damage) {
                                    if (ele.hasClass('selected'))
                                        ele.removeClass('selected')
                                    else {
                                        setHint(intl.get('HINT.sacrifice_sufficient'))
                                        setSfx(ErrorMP3)
                                    }
                                    return
                                }
                                setSfx(SelectMP3)
                                ele.toggleClass('selected')
                            }}></Card>
                    })}
                </Cards>
                <Buttons>
                    <Button onClick={() => {
                        const eles = $('.selected')
                        const consumedCards = []
                        if (eles.length === 1) {
                            const meta = JSON.parse(eles[0].dataset.meta);
                            if (meta.trap) {
                                setSfx(TrapMP3)
                                setGallery([])
                                setGalleryIdx(0)
                                setModalContent(undefined)
                                applyTrapEffects({
                                    username: targetUsername, currPlayer, players, setCurrPlayer, idx: targetReaperIdx, hands, setHands, reapers, setReapers, graveyard, setGraveyard, setHint, turnState, setTurnState, setPlayedCard, cardParams, setCardParams, consumedCards,
                                })
                                return
                            }
                        }

                        absorbed = 0
                        const spiritsCount = reaper.field.filter((sc) => sc.type === 'spirit').length
                        eles.each((idx, ele) => {
                            if (absorbed >= reaper.damage) return
                            const meta = JSON.parse(ele.dataset.meta);
                            if (meta.ignoreAllEffects) {
                                reaper.discard = 0
                                setTurnState('discard')
                            }
                            const defense = meta.type === 'spirit' ? spiritToSPCount(meta.key, spiritsCount === 1, reaper.ignoreProtection) : cardToDPCount(meta, reaper.ignoreProtection)
                            const damagedBy = reaper.damageCausedBy
                            const ignoreProtection = damagedBy ? reapers[damagedBy.username][damagedBy.reaperIdx].ignoreProtection : false
                            if (absorbed + (ignoreProtection ? 0 : (defense.pp || 0)) >= reaper.damage) {
                                absorbed += (defense.pp || 0)
                                setSfx(AttackMP3)
                                setHint(intl.get('HINT.damage_blocked', {
                                    user: selfPlayerName,
                                    damage: defense.pp,
                                    name: cardTexts[reaper.key].name
                                }))
                                return
                            }
                            const isBlocked = (absorbed + defense.pp) >= reaper.damage && !ignoreProtection
                            absorbed += ((defense.pp || 0) + (defense.sp || 0) + (defense.dp || 0))
                            if (defense.undead || isBlocked) {
                                return
                            }
                            consumedCards.push(meta)
                        })
                        if (absorbed < reaper.damage) {
                            if (eles.length === spiritsCount) {
                                consumedCards.map((meta, j) => {
                                    reapers[targetUsername][targetReaperIdx].field.splice(meta.idx - j, 1)
                                })
                                reapers[targetUsername][targetReaperIdx].damage = 0
                                reapers[targetUsername][targetReaperIdx].damageCausedBy = null
                                graveyard.push(...consumedCards)
                                reapers[targetUsername][targetReaperIdx].trapApplied = false
                                setGallery([])
                                setGalleryIdx(0)
                                setModalContent(undefined)
                                setReapers({ ...reapers })
                                setGraveyard([...graveyard])
                                setTurnState('discard')
                                return
                            }
                            setSfx(ErrorMP3)
                            setHint(intl.get('HINT.damage_left', { damage: reaper.damage - absorbed }))
                            return
                        }
                        reapers[targetUsername][targetReaperIdx].damage = 0
                        reapers[targetUsername][targetReaperIdx].damageCausedBy = null
                        consumedCards.map((meta, j) => {
                            reapers[targetUsername][targetReaperIdx].field.splice(meta.idx - j, 1)
                        })
                        graveyard.push(...consumedCards)
                        reapers[targetUsername][targetReaperIdx].trapApplied = false
                        if (reaper.discard > 0) {
                            setDragTargets(['#graveyard'])
                            setDragAndDropReminder(true)
                            setGallery([...hands[targetUsername]])
                            setGalleryIdx(0)
                            setModalContent(undefined)
                            setReapers({ ...reapers })
                            setGraveyard([...graveyard])
                            setCardParams({ ...cardParams, discard: reaper.discard })
                            setHint(intl.get('HINT.discard_cards', { count: reaper.discard }))
                            return
                        }
                        const damageCausedBy = reapers[targetUsername][targetReaperIdx]?.damageCausedBy
                        if (damageCausedBy) {
                            const caster = reapers[damageCausedBy.username][damageCausedBy.reaperIdx]
                            if (caster.computer) setTurnState('play-or-draw')
                        }
                        if (cardParams.afterState && !reapers[targetUsername][targetReaperIdx].trapApplied) cardParams.afterState('self-damage')
                        setGallery([])
                        setGalleryIdx(0)
                        setModalContent(undefined)
                        setReapers({ ...reapers })
                        setGraveyard([...graveyard])
                        setSfx(ButtonMP3)
                        if (turnState === 'trap-activated')
                            setTurnState('discard')
                    }}>{`${intl.get('ACTIONS.sacrifice')} (${reaper.damage})`}</Button>
                </Buttons>
            </ModalBottom>
        </Modal>
    )
}

export const isUserAlive = ({ hands, reapers, username }) => {
    return (reapers[username].filter((reaper, idx) => {
        return reaper.field.filter(c => c.type === 'spirit').length > 0
    }).length > 0 || hands[username].filter((c, i) => {
        return c.key === 'everdream'
    }).length === 1)
}

export const applyArtifactInterrupt = (params) => {

}