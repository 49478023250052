import styled from 'styled-components';
import intl from 'react-intl-universal';
import { isMobile } from "lib/utils";

import YellowArrowIcon from 'assets/icons/yellow_arrow.svg';
import DragAndDropIcon from 'assets/icons/drag-and-drop.png';
import SwitchIcon from 'assets/icons/switch_24px.svg';
import FowardIcon from 'assets/icons/forward_24px.svg';
import CardBackImg from 'assets/cards/back.png';
import { orbToSkillIdx } from 'lib/field';
import { useSafearea } from './page';

const CardEle = styled.div.attrs(({ $size }) => ({
    $size
}))`
    display: inline-block;
    margin: 0.25em;
`

const CardWrapper = styled.div.attrs(({ $size }) => ({
    $size
}))`
    height: ${({ $size }) => $size === 'big' ? "" : "109px"};
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
`

const CardArt = styled.img.attrs(({ $size }) => ({
    $size
}))`
    pointer-events: none;
    position: absolute;
    width: ${({ $size }) => $size === 'big' ? "243.75px" : "65px"};
    left: ${({ $size }) => $size === 'big' ? "28.125px" : "7.5px"};
    top: ${({ $size }) => $size === 'big' ? "28.125px" : "7.5px"};
    border-bottom: solid 1px #3f3f3f;
`

const CardTemplate = styled.img.attrs(({ $size }) => ({
    $size
}))`
    width: ${({ $size }) => $size === 'big' ? "300px" : "80px"};
    border-radius: ${({ $size }) => $size === 'big' ? "18.75px" : "5px"};
`

const CardType = styled.div.attrs(({ $size }) => ({
    $size
}))`
    pointer-events: none;
    background-color: #4B4B4B;
    border-radius: ${({ $size }) => $size === 'big' ? "3.75px" : "1px"};
    font-size: ${({ $size }) => $size === 'big' ? "11.25px" : "3px"};
    padding: ${({ $size }) => $size === 'big' ? "3.75px 22.5px 3.75px 7.5px" : "1px 6px 1px 2px"};
    top: ${({ $size }) => $size === 'big' ? "18.75px" : "5px"};
    left: ${({ $size }) => $size === 'big' ? "33.75px" : "9px"};
    width: fit-content;
    position: absolute;
`

const CardSubType = styled.div.attrs(({ $size }) => ({
    $size
}))`
    pointer-events: none;
    background-color: #4B4B4B;
    border-radius: ${({ $size }) => $size === 'big' ? "3.75px" : "1px"};
    font-size: ${({ $size }) => $size === 'big' ? "11.25px" : "3px"};
    padding: ${({ $size }) => $size === 'big' ? "3.75px 11.25px 3.75px 11.25px" : "1px 3px 1px 3px"};
    top: ${({ $size }) => $size === 'big' ? "18.75px" : "5px"};
    right: ${({ $size }) => $size === 'big' ? "33.75px" : "9px"};
    width: fit-content;
    position: absolute;
`

const CardTypeIcon = styled.img.attrs(({ $size }) => ({
    $size
}))`
    width: ${({ $size }) => $size === 'big' ? "11.25px" : "3px"};
    height: ${({ $size }) => $size === 'big' ? "11.25px" : "3px"};
    position: absolute;
    margin-top: ${({ $size }) => $size === 'big' ? "-1.5px" : "0px"};
    margin-left: ${({ $size }) => $size === 'big' ? "6px" : "1.5px"};
`

const CardName = styled.div.attrs(({ $size }) => ({
    $size
}))`
    pointer-events: none;
    position: absolute;
    bottom: ${({ $size }) => $size === 'big' ? "113px" : "29px"};
    font-size: ${({ $size }) => $size === 'big' ? "18.75px" : "5px"};
    font-family: Atalon;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
`

const CardDesc = styled.div.attrs(({ $size }) => ({
    $size
}))`
    pointer-events: none;
    position: absolute;
    color: #4B4B4B;
    left: ${({ $size }) => $size === 'big' ? "37.5px" : "10px"};
    bottom: ${({ $size }) => $size === 'big' ? "60px" : "8px"};
    font-size: ${({ $size, type }) => $size === 'big' ? type === 'reaper' ? "11.25px" : "13px" : "3px"};
    text-align: center;
    width: ${({ $size }) => $size === 'big' ? "calc(100% - 75px)" : "calc(100% - 20px)"};
    height: 20px;

    > div {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        white-space: normal;
    }
`

const Skill = styled.div`
    position: relative;
`

const SkillSelector = styled.img`
    position: absolute;
    width: 30px;
    left: -30px;
    top: -6px;
`

const ScrollableCards = styled.div`
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: auto;

    &.selected {
        width: calc(100% - 4px);
    }
`

const CardBackWrapper = styled.div`
    display: inline-block;
    margin: ${({ $size }) => $size === 'small' ? "5px" : 0};
`

const CardBackImgEle = styled.img.attrs(({ $size }) => ({ $size }))`
    pointer-events: ${({ $selectable }) => $selectable ? "initial" : "none"};
    filter: drop-shadow(0 0.1em 0.15rem #111);
    width: ${({ $size }) => $size === 'small' ? "80px" : "120px"};
`

const CardNumber = styled.div`
    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: solid 2px white;
        background: #2f2f2f;
        line-height: 40px;
    }
`

export const CardBack = (props) => {
    return <CardBackWrapper className={props.className + " cmp-card-back"} $size={props.$size} onClick={props.onClick} data-meta={JSON.stringify({ ...props.card, idx: props.i })} >
        <CardBackImgEle src={CardBackImg} $size={props.$size} />
    </CardBackWrapper>
}

export const Cards = (props) => {
    return <ScrollableCards {...props}>{props.children}</ScrollableCards>
}

export const Card = ({ card, i, className, onClick, skillSelected, setSkillSelected, yourTurn, turnState, cardDragged, cardNumber, size }) => {
    const cardTexts = intl.get('CARDS')
    const cardTemplate = {
        'artifact': require('assets/card-templates/artifact.jpg'),
        'orb': require('assets/card-templates/orb.jpg'),
        'reaper': require('assets/card-templates/reaper.jpg'),
        'spirit': require('assets/card-templates/spirit.jpg'),
    }[card.type];
    const cardTypeIcon = {
        'artifact': require('assets/card-type-icons/artifact.png'),
        'orb': require('assets/card-type-icons/orb.png'),
        'reaper': require('assets/card-type-icons/reaper.png'),
        'spirit': require('assets/card-type-icons/spirit.png'),
    }[card.type];
    const cardArt = card.overrideArt || require(`assets/cards/${card.type}/${card.key}.jpg`);
    return <CardEle key={i} className={className ? `cmp-card ${className}` : "cmp-card"} data-meta={JSON.stringify({ ...card, idx: i })} onClick={(e) => {
        if (onClick) onClick(e, i)
    }} $size={size}>
        <CardWrapper className="card-wrapper" $size={size}>
            <CardArt src={cardArt} $size={size} />
            <CardTemplate src={cardTemplate} $size={size}></CardTemplate>
            <CardType $size={size}>
                {intl.get(`CARD_TYPE.${card.type}`)}
                <CardTypeIcon $size={size} src={cardTypeIcon} />
            </CardType>
            {card.sub_type ? <CardSubType $size={size}>
                {intl.get(`CARD_TYPE.${card.sub_type}`)}
            </CardSubType> : ""}
            <CardName $size={size}>{cardTexts[card.type][card.key].name}</CardName>
            <CardDesc type={card.type} $size={size}><div>
                {(() => {
                    switch (card.type) {
                        case 'reaper':
                            return cardTexts[card.type][card.key].skills.map((s, j) => {
                                const showArrow = yourTurn && turnState === 'play-or-draw' && !card.opponent && (card.idx === -1 ? skillSelected === j : card.idx === j)
                                const isPlaceholder = !(card.idx === -1 ? skillSelected === j : card.idx === j) && card.idx !== undefined && card.idx !== -1;
                                return <Skill key={j} className={isPlaceholder ? "placeholder clickable" : "clickable"} onClick={() => {
                                    setSkillSelected(j);
                                }} $size={size}>
                                    <b>{s.name}</b>: {s.desc}
                                    {showArrow && !cardDragged ? <SkillSelector className="float-left" src={YellowArrowIcon} $size={size}></SkillSelector> : ''}
                                </Skill>
                            })
                        case 'orb':
                            return card.description || cardTexts[card.type][card.key].description
                        default:
                            return card.description || cardTexts[card.type][card.key].description
                    }
                })()}
            </div></CardDesc>
            <CardNumber className='card-number'>{cardNumber ? <div>{cardNumber}</div> : ''}</CardNumber>
        </CardWrapper>
    </CardEle>
}

const BigCardWrapper = styled.div.attrs(({ $top }) => ({
    $top,
}))`
    position: fixed;
    left: 50%;
    top: calc(40px + ${({ $top }) => $top}px);
    z-index: 1;
    transform: ${isMobile() ? "translateX(-50%)" : "translate(-50%, -5%) scale(0.9)"};
`


const DragAndDropReminder = styled.img`
    position: absolute;
    width: 240px;
    z-index: 1;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
`


const IconButton = styled.img`
    background: #4B4B4B;
    padding: 0.5em;
    margin: 0 0.5em;
    width: 30px;
    border: solid 0.5px #888;
    border-radius: 5px;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
`


const SwitchIconButton = styled(IconButton)`
    position: absolute;
    right: 4px;
    bottom: -4px;
`

const NextSkillButton = styled(IconButton)`
    position: absolute;
    left: 4px;
    bottom: -4px;
    width: 22px;
    padding: 0.75em;
`


export const BigCard = (props) => {
    const { eventHandlers, gallery, galleryIdx, reaper, cardDragged, dragAndDropReminder, viewOption, setViewOption, skillIdx, cardRenderParams, yourTurn, turnState, skillSelected, setSkillSelected } = props;
    const { safearea } = useSafearea();
    return <BigCardWrapper id={props.id || 'big-card'} {...eventHandlers} $top={safearea.top}>
        {!cardDragged && dragAndDropReminder ? <DragAndDropReminder className="reminder" src={DragAndDropIcon}></DragAndDropReminder> : ""}
        <Card {...cardRenderParams} size="big" card={gallery[galleryIdx]?.type === 'orb' && viewOption ? {
            type: 'reaper',
            key: reaper.key,
            idx: skillIdx,
            original: gallery[galleryIdx],
        } : gallery[galleryIdx]} i={galleryIdx} />
        {gallery[galleryIdx]?.type === 'orb' && !cardDragged && viewOption && skillIdx === -1 && yourTurn && turnState === 'play-or-draw' ? <NextSkillButton className="clickable" src={FowardIcon} onClick={() => {
            setSkillSelected((skillSelected + 1) % 3)
        }}></NextSkillButton> : ''}
        {gallery[galleryIdx]?.type === 'orb' && !cardDragged ? <SwitchIconButton className="clickable" src={SwitchIcon} onClick={() => {
            setViewOption(!viewOption)
        }}></SwitchIconButton> : ''}
    </BigCardWrapper>
}

const DisplayCardWrapper = styled.div.attrs(({ $visible }) => ({ $visible }))`
    opacity: ${({ $visible }) => $visible ? 1 : 0};
    transition: transform 0.4s;
    position: fixed;
    transform: translate(-50%, -50%) scale(${({ $visible }) => $visible ? 1.2 : 0});
    top: 50%;
    left: 50%;
    z-index: 10;
`

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
`

const ButtonsWrapper = styled.div`
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    bottom: 65px;
`

export const PlayedCard = ({ card, children }) => {
    const newCard = { ...card }
    if (card?.type === 'orb' && card?.reaper) {
        const reaper = intl.get('CARDS.reaper')[card.reaper]
        const skill = reaper.skills[orbToSkillIdx(card.key)]
        if (!newCard.description) newCard.description = `${skill?.name}: ${skill?.desc}`
        newCard.overrideArt = require(`assets/cards/reaper/${card.reaper}.jpg`)
    }
    return <>
        {card?.show ? <Backdrop /> : ''}
        <DisplayCardWrapper $visible={card}>
            {card?.show ? <Card size="big" card={newCard} /> : ''}
        </DisplayCardWrapper>
        <ButtonsWrapper>{card?.show ? children : ''}</ButtonsWrapper>
    </>
}