import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import intl from 'react-intl-universal';
import $ from 'jquery';
import Prando from 'prando';

import { Modal, PlayerTurnModal } from "components/modal";
import { Card, Cards, BigCard, CardBack, PlayedCard } from "components/cards";
import { Button } from "components/button";
import { Label } from "components/common";
import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'
import { SettingsButton } from "components/settings";

import { shuffle, isMobile } from "lib/utils";
import { orbToSkillIdx, getDragTargets, validateMaxSpiritsRule, selfReceiveDamage, isUserAlive, spiritToSPCount } from "lib/field";
import { applyEffects, applySkill, interruptableCards } from 'lib/reapers';
import { applyArtifact, applyTrapEffects, cancelTrapCard } from 'lib/artifact';

import "anim.css"
import DeckRules from 'assets/data/deck-rules.json';
import { npcReceiveDamage, npcPlay, npcApplyInterrupt } from "lib/computer";
import { Backdrop, Padding, useSafearea } from "components/page";
import { VSScreen } from "components/vs";
import { useAppContext } from "components/context";
import { Defeat, Tie, Victory } from "components/gameover";
import { StoryTexts } from "lib/story";

import LastHPMP3 from 'assets/sounds/last_hp.mp3'
import VictoryMP3 from 'assets/sounds/victory.mp3'
import DefeatMP3 from 'assets/sounds/defeat.mp3'
import TieMP3 from 'assets/sounds/tie.mp3'

import AttackMP3 from 'assets/sounds/attack.mp3'
import ErrorMP3 from 'assets/sounds/error.mp3'
import ButtonMP3 from 'assets/sounds/button.mp3'
import SelectMP3 from 'assets/sounds/select.mp3'
import TrapMP3 from 'assets/sounds/trap.mp3'

let hintDebounce

const startingSpirits = [
    {
        type: 'spirit',
        key: 'lesser-spirit'
    }, {
        type: 'spirit',
        key: 'lesser-spirit'
    }, {
        type: 'spirit',
        key: 'ordinary-spirit'
    },
]

const PlayArea = styled.div.attrs(({ $top, $bottom }) => ({
    $top,
    $bottom,
}))`
    width: 100%;
    height: calc(100vh - 125px - ${({ $top, $bottom }) => $top + $bottom}px);
    position: relative;
`

const Deck = styled.div`
    position: absolute;
    left: 30%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;

    img {
        width: 120px;
    }
`

const Graveyard = styled.div`
    position: absolute;
    left: 70%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: ${props => props.selected ? "solid 3px #FFFFFF" : "solid 3px #555555"};
    width: 120px;
    height: 164px;
    border-radius: 5px;

    .card-wrapper {
        -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
        -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
        box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    }
`

const Hand = styled.div`
    max-width: calc(100% - 10px);
    width: fit-content;
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    overflow: auto;
    text-align: center;
    margin: 5px 3px 5px 3px;
    border: solid 2px #555;
    border-radius: 5px;
    transform: translate(-50%);
    left: 50%;

    &.selected {
        border: solid 2px #FFFFFF;
    }
`

const SelfReapers = styled.div`
    position: absolute;
    bottom: 5px;
    width: 100%;
    max-width: 600px;
    left: 50%;
    transform: translateX(-50%);
`

const OpponentReapers = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 600px;
    left: 50%;
    transform: translateX(-50%);
`

const Reaper = styled.div.attrs(({ $dead }) => ({
    $dead: $dead
}))`
    position: relative;
    white-space: nowrap;
    overflow: auto;
    display: inline-block;
    width: fit-content;
    border: solid 2px #4B4B4B;
    border-radius: 5px;
    margin: 0.15em;
    opacity: ${({ $dead }) => $dead ? 0.5 : 1};

    &.selected {
        border: solid 2px #FFFFFF;
    }
`

const DeckCardsCount = styled.div`
    position: absolute;
    bottom: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    background-color: #222;
    font-size: 24px;
    border-radius: 40px;
    border: solid 2px #FFF;
    
    div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`

const BackButton = styled(Button)`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
`

const CardAnim = styled.div`
    position: fixed;
    pointer-events: none;
    z-index: 1;
`

const GalleryOptions = styled.div`
    position: absolute;
    bottom: ${isMobile() ? "7.5vh" : "9vh"};
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    text-align: center;
`

const GraveyardCard = styled.div.attrs(({ $degree }) => ({
    $degree: $degree
}))`
    transform: scale(1.5) rotate(${({ $degree }) => $degree || 0}deg);
    position: absolute;
    left: 12.5%;
    top: 12.5%;
`

const ReaperOptions = styled.div`
    position: absolute;
    bottom: ${isMobile() ? "21.5vh" : "26vh"};
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    text-align: center;

    @media screen and (min-aspect-ratio: 3/2) {
        bottom: 26vh;
    }
`

const HintWrapper = styled.div.attrs(({ $top, $bottom }) => ({
    $top, $bottom,
}))`
    position: fixed;
    transition: bottom 0.5s;
    bottom: calc(-20vh + ${({ $bottom }) => $bottom}px);
    border-radius: 100px;
    background: #2f2f2f;
    border: solid 2px white;
    font-size: 24px;
    padding: 0.5em 1em;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    min-width: 75%;
    text-align: center;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);

    &.show {
        bottom: calc(10px + ${({ $bottom }) => $bottom}px);
    }
`

const IntroWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 1;
`

const ExplanationWrapper = styled.div`
    position: absolute;
    left: 50%;
    bottom: calc(${({ offset }) => offset});
    transform: translateX(-50%);
    transition: bottom 0.5s;
    max-width: 600px;
    width: 80%;
`

const ExplanationText = styled.div`
    text-align: center;
    background: #2f2f2f;
    font-size: 24px;
    padding: 0.5em;
    border: solid 2px #555;
    border-radius: 10px;
`

const ViewingWrapper = styled.div.attrs(({ $bottom }) => ({
    $bottom
}))`
    position: absolute;
    bottom: calc(${({ $bottom }) => $bottom}px + 0.5em);
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    padding: 0.25em;
    background-color: rgb(128 41 41);
    border-radius: 5px;
    outline: 2px solid white;
    text-align: center;
    width: 92.5%;
    font-size: 20px;
`


const PromptWrapper = styled.div.attrs(({ $bottom }) => ({
    $bottom
}))`
    position: absolute;
    bottom: calc(${({ $bottom }) => $bottom}px + 25vh);
    width: 100%;
    z-index: 10;
    padding: 0.5em 0;
    background-color: rgb(128 41 41);
    text-align: center;
    font-size: 24px;
`

let movingCardTimer, params

export const BattlePage = () => {
    const navigate = useNavigate();
    const { acctUsername, chars, isLoggedIn, scene, level, discoveredCards, setBgm, setSfx } = useAppContext();
    useEffect(() => {
        if (isLoggedIn === false) navigate('/login')
    }, [isLoggedIn])

    const { safearea } = useSafearea();
    const [turnState, setTurnState] = useState('setup');
    const [introState, setIntroState] = useState(-1);
    const [hideHint, setHideHint] = useState(false);
    const [preventDrawOnPlay, setPreventDrawOnPlay] = useState(false);
    const [preventPlayOnPlay, setPreventPlayOnPlay] = useState(false);
    const [resumeIntro, setResumeIntro] = useState(false);
    const [currPlayer, setCurrPlayer] = useState(0);
    const [players, setPlayers] = useState([
        { name: acctUsername, type: 'pc' },
        { name: 'computer', type: 'npc' },
    ]);
    const [reapers, setReapers] = useState(scene?.reapers || {
        [acctUsername]: chars.map((key, i) => {
            return {
                key: key,
                field: [...startingSpirits],
                damage: 0,
                discard: 0,
            }
        }),
        // DEBUG
        computer: shuffle(Object.keys(intl.get('CARDS.reaper'))).slice(0, chars.length).map((key, i) => {
            return {
                key: key,
                field: [...startingSpirits],
                damage: 0,
                discard: 0,
                computer: true
            }
        })
    });
    const [hands, setHands] = useState({
        [acctUsername]: [],
        computer: [],
    });
    const [deck, setDeck] = useState([]);
    const [graveyard, setGraveyard] = useState([]);
    const [deckRules, setDeckRules] = useState(DeckRules);

    const [prompt, setPrompt] = useState(undefined);
    const [viewing, setViewing] = useState(undefined);
    const [hint, setHint] = useState(undefined);
    const [movingCard, setMovingCard] = useState(false);
    const [cardMovePos, setCardMovePos] = useState({});
    const [cardMoveEle, setCardMoveEle] = useState(<CardBack />);
    const [onCardMoveEnd, setOnCardMoveEnd] = useState(() => () => { });

    const [playedCard, setPlayedCard] = useState(undefined);
    const [gallery, setGallery] = useState([]);
    const [galleryIdx, setGalleryIdx] = useState(0);
    const [cardDragged, setCardDragged] = useState(false);
    const [dragTargets, setDragTargets] = useState(undefined);
    const [graveyardSelected, setGraveyardSelected] = useState(false);
    const [orbReaperIdx, setOrbReaperIdx] = useState(0);
    const [viewOption, setViewOption] = useState(true);
    const [skillSelected, setSkillSelected] = useState(0);
    const [dragAndDropReminder, setDragAndDropReminder] = useState(false);
    const [cardParams, setCardParams] = useState({});
    const [modalContent, setModalContent] = useState(null);
    const [effects, setEffects] = useState([])

    const cardTexts = intl.get('CARDS')
    const selfPlayerName = acctUsername;
    const currPlayerName = players[currPlayer]?.name;
    const rng = new Prando(0);
    const yourTurn = selfPlayerName === currPlayerName;
    const canDraw = turnState === 'draw' || (!preventDrawOnPlay && turnState === 'play-or-draw' && hands[currPlayerName].filter(c => c.mustPlay).length === 0);
    const reapersCount = Object.values(reapers).map(v => v.length).reduce((cumsum, l) => cumsum + l, 0);
    const cardRenderParams = { skillSelected, setSkillSelected, yourTurn, turnState, cardDragged };
    const mustPlayCards = gallery.filter((c, j) => c.mustPlay)

    // Setup
    useEffect(() => {
        if (turnState !== 'setup') return;

        setBgm(LastHPMP3)

        let newDeck = Array(deckRules.spirit['lesser-spirit'] - reapersCount * 2).fill({
            type: 'spirit',
            key: 'lesser-spirit'
        }).concat(Array(deckRules.spirit['ordinary-spirit'] - reapersCount).fill({
            type: 'spirit',
            key: 'ordinary-spirit'
        })).concat(Array(deckRules.spirit['greater-spirit']).fill({
            type: 'spirit',
            key: 'greater-spirit'
        }));
        const specialSpirits = [];
        Object.entries(deckRules.special_spirit).forEach(([key, count]) => {
            specialSpirits.push(...Array(count).fill({
                type: 'spirit',
                key: key,
            }))
        })
        newDeck.push(...shuffle(specialSpirits).slice(0, deckRules.special_spirit_count));
        Object.entries(deckRules.orb).forEach(([key, count]) => {
            newDeck.push(...Array(count).fill({
                type: 'orb',
                key: key,
            }))
        })
        newDeck = shuffle(newDeck.concat(shuffle(Object.entries(cardTexts.artifact).map(([key, value]) => {
            return {
                type: 'artifact',
                key: key,
                sub_type: value.sub_type,
            }
        })).slice(0, deckRules.artifact_count)))
        newDeck = newDeck.filter(c => {
            return discoveredCards[c.key]
        })

        const newHands = {};
        Object.keys(hands).forEach((player) => {
            newHands[player] = [...newDeck.splice(0, 4)];
        })

        // DEBUG
        // newHands['wywfalcon'][0] = { key: 'dream-sucker', type: 'artifact', sub_type: 'curse' }


        setTurnState('vs')
        setHands({ ...(scene?.hands || newHands) })
        setGraveyard([])
        setDeck([...(scene?.deck || newDeck)])
        document.addEventListener("dragover", e => {
            e.preventDefault();
        }, false);

        setTimeout(() => {
            if (scene?.intro) setIntroState(0)
            setTurnState(scene?.intro ? 'intro' : 'next-player')
        }, 3000)
    }, [turnState])

    // Self Player
    useEffect(() => {
        if (turnState === 'victory' || turnState === 'tie' || turnState === 'defeat') return;
        if (!isUserAlive({ hands, reapers, username: selfPlayerName })) {
            setCurrPlayer((currPlayer + 1) % players.length)
            return
        }
        const reaperName = reapers[currPlayerName][cardParams.casterReaperIdx] ? intl.get('CARDS.reaper')[reapers[currPlayerName][cardParams.casterReaperIdx].key].name : ""
        console.log(currPlayerName, turnState, reaperName, cardParams.cardState, cardParams.skillState)
        if (currPlayerName !== selfPlayerName) return;

        switch (turnState) {
            case 'force-discard':
                if (hands[currPlayerName].length === 0) {
                    if (cardParams.afterState)
                        cardParams.afterState('pre-self-force-discard')
                    else
                        setTurnState('discard')
                    break
                }
                setGallery([...hands[currPlayerName]])
                setGalleryIdx(0)
                setDragTargets(['#graveyard'])
                setHint(intl.get('HINT.discard_cards', { count: cardParams.discardCount || 1 }))
                setDragAndDropReminder(true)
                break
            case 'discard':
                if (hands[currPlayerName].length > 4) {
                    setHint(intl.get('HINT.discard_until_4'))
                    setDragAndDropReminder(true)
                    setGallery([...hands[currPlayerName]])
                    setGalleryIdx(0)
                    setDragTargets([])
                } else {
                    setCurrPlayer((currPlayer + 1) % players.length)
                    setTurnState('next-player')
                    setGallery([])
                    setGalleryIdx(0)
                }
                break
        }
    }, [turnState])

    // NPC
    useEffect(() => {
        if (turnState === 'victory' || turnState === 'tie' || turnState === 'defeat') return;
        if (!isUserAlive({ hands, reapers, username: currPlayerName })) {
            setCurrPlayer((currPlayer + 1) % players.length)
            return
        }
        if (players[currPlayer].type !== 'npc') return;
        npcPlay({
            hands, setHands, reapers, setReapers, graveyard, setGraveyard, deck, setDeck,
            turnState, setTurnState, cardParams, setCardParams, setPlayedCard, effects, setEffects,
            cardState: cardParams.cardState || 0, skillState: cardParams.skillState || 0, setSfx,
            players, npcPlay, currPlayer, setCurrPlayer, currPlayerName, gallery, setGallery, galleryIdx, setGalleryIdx,
            dragTargets, setDragTargets, setDragAndDropReminder, setModalContent, clearReaperState, cancelTrapCard,
            setCardMoveEle, setCardMovePos, setMovingCard, setOnCardMoveEnd, setHint, playedCard, setPlayedCard, setHint,
        })
    }, [turnState, cardParams])

    // Card and skill states
    useEffect(() => {
        if (turnState === 'force-discard') return
        if (cardParams.cardState || cardParams.skillState) {
            switch (cardParams.cardStateType) {
                case 'artifact':
                    applyArtifact(cardParams)
                    break
                case 'skill':
                    applySkill(cardParams)
                    break
            }
            return
        }
    }, [cardParams?.cardState, cardParams?.skillState])

    // Damage and trap effects
    useEffect(() => {
        if (turnState === 'victory' || turnState === 'tie' || turnState === 'defeat') return;
        const consumedCards = []
        let noChangeTurnState
        noChangeTurnState = applyEffects({
            selfPlayerName, currPlayerName,
            casterUsername: cardParams?.casterUsername, casterReaperIdx: cardParams?.casterReaperIdx,
            targetUsername: cardParams?.targetUsername, targetReaperIdx: cardParams?.targetReaperIdx,
            effects, setEffects, graveyard, setGraveyard, reapers, setReapers, setGallery,
            turnState, setTurnState, cardParams, setCardParams, clearReaperState, setHint
        })
        if (noChangeTurnState) {
            if (!cardParams?.casterUsername && !cardParams?.casterReaperIdx) return
            const reaper = reapers[cardParams?.casterUsername][cardParams?.casterReaperIdx]
            if (selfPlayerName !== cardParams?.casterUsername) {
                setHint(intl.get("HINT.attacked_by", {
                    user: cardParams?.casterUsername,
                    damage: reapers[cardParams?.targetUsername][cardParams?.targetReaperIdx].damage,
                    reaper: intl.get('CARDS.reaper')[reaper.key].name
                }))
            }
        }

        let damageChanged = false, hasSelfDamage = false
        Object.keys(reapers).map(username => {
            reapers[username].map((reaper, idx) => {
                if (reaper.damage === 0) return
                if (username === selfPlayerName) hasSelfDamage = true
            })
        })
        Object.keys(reapers).map(username => {
            reapers[username].map((reaper, idx) => {
                if (reaper.damage === 0) {
                    if (reaper.damageChanged) damageChanged = true
                    return
                }
                if (applyTrapEffects({ selfPlayerName, currPlayer, players, setCurrPlayer, hands, setHands, username, idx, reapers, setReapers, graveyard, setGraveyard, setHint, turnState, setTurnState, setPlayedCard, cardParams, setCardParams, clearReaperState })) {
                    setSfx(TrapMP3)
                    return
                }
                consumedCards.push(...(reaper.computer ?
                    npcReceiveDamage({ hasSelfDamage, username, idx, hands, setHands, reapers, setReapers, graveyard, setGraveyard, setCardMoveEle, setCardMovePos, setMovingCard, setOnCardMoveEnd, setHint, setTurnState, cardParams, setCardParams }) :
                    selfReceiveDamage({ selfPlayerName, currPlayer, players, setCurrPlayer, targetUsername: username, targetReaperIdx: idx, turnState, setTurnState, hands, setHands, cardParams, setCardParams, graveyard, setGraveyard, gallery, setGallery, galleryIdx, setGalleryIdx, reapers, setReapers, setModalContent, setDragAndDropReminder, setPlayedCard, setHint, setDragTargets, setSfx, skipHint: noChangeTurnState })
                ) || [])
            })
        })
        Object.keys(hands).map(username => {
            reapers[username].map((reaper, idx) => {
                if (applyTrapEffects({ selfPlayerName, currPlayer, players, setCurrPlayer, hands, setHands, username, idx, reapers, setReapers, graveyard, setGraveyard, setHint, turnState, setTurnState, setPlayedCard, cardParams, setCardParams, consumedCards, clearReaperState }))
                    setSfx(TrapMP3)
            })
        })
        if (cardParams.afterState && damageChanged) {
            cardParams.afterState('damage-applied')
        }
    }, [reapers, hands])

    // On interrupt
    useEffect(() => {
        if (turnState !== 'interrupt') return
        setModalContent(undefined)
        setGallery([])
    }, [turnState])

    // End Game
    useEffect(() => {
        if (turnState === 'cause-damage' || turnState === 'trap-activated') return
        if ((turnState === 'draw' || turnState === 'play-or-draw') && deck.length === 0) {
            const reapersSpTotal = Object.keys(reapers).map((username) => {
                return {
                    username,
                    sp: reapers[username].map((r) => {
                        const sole = r.field.filter(c => c.type === 'spirit').length === 1
                        return r.field.map(c => {
                            if (c.type !== 'spirit') return 0
                            return spiritToSPCount(c.key, sole).sp
                        }).reduce((cumsum, v) => cumsum + v, 0)
                    }).reduce((cumsum, v) => cumsum + v, 0)
                }
            }).sort((a, b) => {
                return b.sp - a.sp
            })
            if (reapersSpTotal[0].sp === reapersSpTotal[1].sp) {
                setTurnState('tie')
                setSfx(TieMP3)
            } else {
                setTurnState(reapersSpTotal[0].username === selfPlayerName ? 'victory' : 'defeat')
                setSfx(reapersSpTotal[0].username === selfPlayerName ? VictoryMP3 : DefeatMP3)
            }
            setBgm(false)
            return
        }
        const usersAlive = Object.keys(reapers).filter((username) => {
            return isUserAlive({ hands, reapers, username })
        })
        if (usersAlive.length === 1) {
            setTurnState(usersAlive[0] === selfPlayerName ? 'victory' : 'defeat')
            setSfx(usersAlive[0] === selfPlayerName ? VictoryMP3 : DefeatMP3)
            setBgm(false)
        } else if (usersAlive.length === 0) {
            setTurnState('tie')
            setSfx(TieMP3)
            setBgm(false)
        }
    }, [turnState])

    // Card Movement
    useEffect(() => {
        if (turnState === 'victory' || turnState === 'tie' || turnState === 'defeat') return;
        switch (movingCard) {
            case 'start':
                $('#card-anim').css('left', parseInt(cardMovePos.startX))
                $('#card-anim').css('top', parseInt(cardMovePos.startY))
                setMovingCard('moving');
                $("#card-anim").animate({
                    left: parseInt(cardMovePos.endX),
                    top: parseInt(cardMovePos.endY),
                }, 500);
                if (movingCardTimer) clearTimeout(movingCardTimer)
                movingCardTimer = setTimeout(() => {
                    setMovingCard('end');
                    setCardMovePos({})
                }, 500)
                break;
            case 'end':
                if (onCardMoveEnd) onCardMoveEnd();
                setMovingCard(false);
                setCardMovePos({})
                setCardMoveEle(<CardBack />)
                setOnCardMoveEnd(false);
                break;
        }
    }, [movingCard])

    // Hint
    useEffect(() => {
        if (!hint) return
        if (hideHint) {
            setHint('')
            return
        }
    }, [hint])

    // Prompt
    useEffect(() => {
        if (currPlayerName !== selfPlayerName) {
            setPrompt(undefined)
            setViewing(undefined)
            return
        }
        switch (turnState) {
            case 'draw':
            case 'play-or-draw':
                if (preventPlayOnPlay)
                    setPrompt(intl.get('PROMPT')['draw-another'])
                else
                    setPrompt(intl.get('PROMPT')[turnState])
                break
            case 'discard':
                setViewing(intl.get('PROMPT')[turnState])
                break
            default:
                setPrompt(undefined)
        }
    }, [turnState])

    // Show next player
    useEffect(() => {
        if (turnState !== 'next-player') return
        setOnCardMoveEnd(false)
        setTimeout(() => {
            clearReaperState()
            setCardParams({})
            setTurnState('draw')
        }, 1500)
    }, [turnState])

    const clearReaperState = () => {
        const newReapers = {}
        Object.keys(reapers).map((username) => {
            newReapers[username] = reapers[username].map((r, rIdx) => {
                return {
                    key: r.key,
                    field: [...r.field],
                    damage: 0,
                    discard: 0,
                    computer: r.computer,
                }
            })
        })

        setReapers({ ...newReapers })
    }

    const onDragCard = (e) => {
        if (dragTargets === undefined || !dragAndDropReminder) return;
        const ele = document.getElementById('big-card');
        const x = (e.touches ? e.touches[0].pageX : e.pageX);
        const y = (e.touches ? e.touches[0].pageY : e.pageY);
        if (x === 0 && y === 0) return;
        ele.style.transform = "translate(-50%, -50%) scale(0.38)";
        ele.style.left = x + 'px';
        ele.style.top = y + 'px';
        ele.style.pointerEvents = 'none';
        setCardDragged(true);
        let target, targets;
        const card = gallery[galleryIdx];
        const skillIdx = orbToSkillIdx(card.key);

        switch (turnState) {
            case 'interrupt':
                targets = getDragTargets({ card, reapers, username: selfPlayerName, orbReaperIdx, skillIdx, turnState, hands })
                break;
            case 'cause-damage':
            case 'play-or-draw':
                targets = dragTargets.length > 0 ? dragTargets : getDragTargets({ card, reapers, username: currPlayerName, orbReaperIdx, skillIdx: skillIdx !== -1 ? skillIdx : skillSelected, turnState, hands })
                break
        }

        switch (turnState) {
            case 'interrupt':
            case 'cause-damage':
            case 'play-or-draw':
                setGraveyardSelected(false);
                $(targets.join(',')).removeClass("selected");
                for (let i = 0; i < targets.length; i++) {
                    target = $(document.elementFromPoint(x, y)).closest(targets[i]);
                    $(targets[i]).addClass('glow target');
                    if (target.length === 0) {
                        continue
                    }
                    if (targets[i] === '#graveyard') setGraveyardSelected(true);
                    $(target).addClass("selected");
                    $(target).closest('.reapers').scrollLeft(Math.max(0, 3 * (x - 100)));
                    break;
                }
                break;
            case 'discard':
            case 'force-discard':
                $('#graveyard').addClass('glow');
                target = $(document.elementFromPoint(x, y)).closest('#graveyard');
                setGraveyardSelected(target.length > 0);
                break;
        }
    }

    const onDragCardEnd = (e) => {
        $('.target').removeClass("glow");
        if (dragTargets === undefined || !dragAndDropReminder) return;
        const ele = document.getElementById('big-card');
        ele.style.transform = isMobile() ? "translateX(-50%)" : "translate(-50%, -5%) scale(0.9)";
        ele.style.left = "50%";
        ele.style.top = `calc(40px + ${safearea.top}px)`;
        ele.style.pointerEvents = 'initial';
        switch (turnState) {
            case 'interrupt':
                if (!graveyardSelected) break
                const currTargets = dragTargets.length > 0 ? dragTargets : getDragTargets({ card, reapers, username: currPlayerName, orbReaperIdx, skillIdx: skillIdx !== -1 ? skillIdx : skillSelected, turnState, hands })
                if (currTargets.length === 0) return
                const meta = JSON.parse($(e.target).closest('.cmp-card')[0].dataset.meta)
                const cardMeta = meta.original || meta
                graveyard.push(...hands[cardMeta.username].splice(meta.idx, 1), ...hands[currPlayerName].splice(cardMeta.playedCard.cardIdx, 1))
                setGraveyard([...graveyard])
                setHands({ ...hands })
                setGallery([])
                setGalleryIdx(0)
                setTurnState('next-player')
                setSfx(TrapMP3)
                break
            case 'play-or-draw':
                const card = gallery[galleryIdx];
                const skillIdx = cardParams.skillIdx || orbToSkillIdx(card.key)
                const targets = dragTargets.length > 0 ? dragTargets : getDragTargets({ card, reapers, username: currPlayerName, orbReaperIdx, skillIdx: skillIdx !== -1 ? skillIdx : skillSelected, turnState, hands })
                const target = $(targets.join(',')).filter((i, ele) => {
                    return $(ele).hasClass('selected')
                })
                $(targets.join(',')).removeClass('selected')

                if (npcApplyInterrupt({ reapers, setReapers, hands, setHands, graveyard, setGraveyard, casterUsername: selfPlayerName, setTurnState, cardIdx: galleryIdx, setPlayedCard, cancelTrapCard, setGallery, setHint, clearReaperState, setModalContent })) {
                    break
                }

                if (targets.length === 0 && card.mustPlay) {
                    hands[selfPlayerName][galleryIdx].mustPlay = false;
                    graveyard.push(...hands[selfPlayerName].splice(galleryIdx, 1))
                    setHands({ ...hands })
                    setGraveyard([...graveyard])
                    setTurnState('discard')
                    setHint(intl.get('HINT.no_effect'))
                    setSfx(ErrorMP3)
                    break
                }
                if (target.length === 0) {
                    if (cardDragged) setSfx(ErrorMP3)
                    break
                }
                const [username, idx] = target[0].id.split('_')
                if (cardParams.cardState !== undefined || cardParams.skillState !== undefined) {
                    cardParams.selectedEle = target
                    cardParams.gallery = gallery
                    cardParams.galleryIdx = galleryIdx
                    switch (cardParams.cardStateType) {
                        case "skill":
                            applySkill(cardParams)
                            break;
                        case "artifact":
                            applyArtifact(cardParams)
                            break;
                    }
                } else {
                    params = {
                        casterUsername: selfPlayerName, casterReaperIdx: orbReaperIdx,
                        targetUsername: username, targetReaperIdx: idx, selectedEle: target,
                        card, cardIdx: galleryIdx, skillIdx: skillIdx !== -1 ? skillIdx : skillSelected,
                        hands, setHands, reapers, setReapers, graveyard, setGraveyard, deck, setDeck,
                        turnState, setTurnState, selectedCards: [], cardParams, setCardParams, setPlayedCard,
                        cardState: cardParams.cardState || 0, skillState: cardParams.skillState || 0,
                        players, currPlayer, setCurrPlayer, gallery, setGallery, galleryIdx, setGalleryIdx,
                        dragTargets, setDragTargets, setDragAndDropReminder, setModalContent, setEffects,
                        setCardMovePos, setMovingCard, setOnCardMoveEnd, setHint, clearReaperState, cancelTrapCard
                    }
                    switch (card.type) {
                        case 'orb':
                            params.cardStateType = 'skill'
                            params.originalCard = { ...card }
                            applySkill(params)
                            setSfx(AttackMP3)
                            break;
                        case 'spirit':
                            if (validateMaxSpiritsRule(reapers[username][idx].field, card)) {
                                hands[selfPlayerName].splice(galleryIdx, 1)
                                setHands({ ...hands })
                                reapers[username][idx].field = [...reapers[username][idx].field, card]
                                setReapers({ ...reapers })
                                setTurnState('discard')
                                setSfx(AttackMP3)
                            } else if (card.mustPlay) {
                                graveyard.push(...hands[selfPlayerName].splice(galleryIdx, 1))
                                setHands({ ...hands })
                                setGraveyard([...graveyard])
                                setTurnState('discard')
                                setHint(intl.get('HINT.no_effect'))
                                setSfx(ErrorMP3)
                            } else {
                                setHint(intl.get('HINT.spirits_max'))
                                setSfx(ErrorMP3)
                            }
                            break;
                        case 'artifact':
                            params.cardStateType = 'artifact'
                            params.originalCard = { ...card }
                            applyArtifact(params)
                            setSfx(AttackMP3)
                            break
                    }
                }
                if (cardParams.onDragCardEnd) cardParams.onDragCardEnd(target);
                setViewing(undefined);
                break
            case 'cause-damage':
                if (cardParams?.discard > 0) {
                    graveyard.push(...hands[selfPlayerName].splice(galleryIdx, 1))
                    setHands({ ...hands })
                    setGraveyard([...graveyard])
                    setGalleryIdx(0)
                    setGallery([])
                    if (cardParams.discard === 1) setTurnState('discard')
                    else setCardParams({ ...cardParams, discard: cardParams.discard - 1 })
                }
                break
            case 'force-discard':
                if (!graveyardSelected) break
                graveyard.push(...hands[selfPlayerName].splice(galleryIdx, 1))
                setHands({ ...hands })
                setGraveyard([...graveyard])
                setGalleryIdx(0)
                setGallery([])
                if (cardParams.afterState)
                    cardParams.afterState('self-force-discard')
                else
                    setTurnState('discard')
                break;
            case 'discard':
                if (!graveyardSelected) break
                graveyard.push(...hands[selfPlayerName].splice(galleryIdx, 1))
                setHands({ ...hands })
                setGraveyard([...graveyard])
                setGalleryIdx(0)
                if (hands[selfPlayerName].length <= 4) {
                    setCurrPlayer((currPlayer + 1) % players.length)
                    setGallery([])
                    setTurnState('next-player')
                } else {
                    setGallery([...hands[selfPlayerName]])
                }
                setCardParams({})
                break;
        }
        setGraveyardSelected(false);
        setCardDragged(false);
    }

    const { introEles, introExplOffsets, onIntroState } = (StoryTexts({ level, acctUsername }) || {})
    useEffect(() => {
        if (introState < 0) return
        $(introEles.join(',')).css('z-index', '').css('pointer-events', 'unset');
        if (turnState !== 'intro') return
        $(introEles[introState]).css('z-index', 9999).css('pointer-events', 'none');
    }, [introState, turnState])
    useEffect(() => {
        if (!resumeIntro || resumeIntro?.state !== turnState || (resumeIntro?.isOpponentTurn && selfPlayerName === currPlayerName) || (resumeIntro?.isYourTurn && selfPlayerName !== currPlayerName)) return
        setTurnState('intro')
    }, [turnState])

    return <div className="no-select">
        {turnState === 'intro' ? <IntroWrapper>
            <Backdrop />
            <ExplanationWrapper className="fade-in" offset={`${(introExplOffsets)[introState]}vh + ${safearea.bottom}px`} >
                <ExplanationText>{intl.get('LEVELS')[level][introState]}</ExplanationText>
                <Padding $height={5} />
                <Button onClick={() => {
                    setSfx(ButtonMP3)
                    setIntroState(introState + 1)
                    if (onIntroState) onIntroState({ introState, setTurnState, setResumeIntro, setHideHint, setPreventDrawOnPlay, setPreventPlayOnPlay })
                }}>{intl.get('ACTIONS.next')}</Button>
            </ExplanationWrapper>
        </IntroWrapper> : ''}
        <SettingsButton />
        {scene?.showPrompts && prompt && !viewing ? <PromptWrapper $bottom={safearea.bottom}>{prompt}</PromptWrapper> : ''}
        {scene?.showPrompts && viewing ? <ViewingWrapper $bottom={safearea.bottom}>{viewing}</ViewingWrapper> : ''}
        {turnState === 'victory' ? <Victory setHint={setHint} /> : ''}
        {turnState === 'tie' ? <Tie setHint={setHint} /> : ''}
        {turnState === 'defeat' ? <Defeat setHint={setHint} /> : ''}
        {turnState === 'vs' ? <VSScreen reapers={reapers} players={players} /> : ''}
        <DeadNightSky />
        <div className="bg-smoke" />
        <Moon>
            <Eclipse />
        </Moon>
        <Mountain />
        <div className="fg-smoke" />
        <HintWrapper id="hint" className={hint ? 'show' : ""} $bottom={safearea.bottom} $top={safearea.top} onClick={() => {
            setHint('')
        }}>{hint || ""}</HintWrapper>
        <PlayerTurnModal show={turnState === 'next-player'} user={currPlayerName} />
        <PlayedCard card={playedCard}>
            {
                reapers[selfPlayerName].map((r, idx) => {
                    const cards = interruptableCards(r, hands[selfPlayerName])
                    if (cards?.length > 0)
                        return <Button key={idx} className="glow" onClick={() => {
                            setGallery(hands[selfPlayerName].map((c, cardIdx) => {
                                c.username = selfPlayerName
                                c.reaperIdx = idx
                                c.idx = cardIdx
                                c.playedCard = { ...playedCard }
                                return c
                            }))
                            setGalleryIdx(0)
                            setTurnState('interrupt')
                            setPlayedCard(undefined)
                            setOrbReaperIdx(idx)
                            cancelTrapCard()
                            graveyard.push(...hands[selfPlayerName].splice(cards[0].cardIdx, 1))
                            setGraveyard([...graveyard])
                            setHands({ ...hands })
                            setCardParams({})
                        }}>{intl.get('ACTIONS.interrupt')}</Button>
                })
            }
        </PlayedCard>
        {movingCard ? <CardAnim
            id="card-anim"
        >
            {cardMoveEle}
        </CardAnim> : ""}
        <PlayArea $top={safearea.top} $bottom={safearea.bottom}>
            <Deck id="deck" className={yourTurn && canDraw && gallery.length === 0 ? 'glow clickable' : ''} onClick={() => {
                if (turnState === 'intro') return
                if (!(yourTurn && canDraw) || movingCard) return;
                const drawnCard = deck.pop();
                setDeck([...deck]);
                const deckPos = document.getElementById('deck')?.getBoundingClientRect();
                const yourHandPos = document.getElementById('your-hand')?.getBoundingClientRect();
                setCardMovePos({ startX: deckPos.x, startY: deckPos.y, endX: yourHandPos.x + yourHandPos.width / 2 - 60, endY: yourHandPos.y })
                setMovingCard('start')
                setOnCardMoveEnd(() => () => {
                    hands[selfPlayerName].push(drawnCard)
                    setHands({ ...hands })
                    switch (turnState) {
                        case 'draw':
                            setTurnState('play-or-draw');
                            break;
                        case 'play-or-draw':
                            if (hands[selfPlayerName].length > 4)
                                setTurnState('discard');
                            else {
                                setCurrPlayer((currPlayer + 1) % players.length);
                                setTurnState('next-player');
                            }
                            break;
                    }
                })
            }}>
                <CardBack />
                <DeckCardsCount><div>{deck.length}</div></DeckCardsCount>
            </Deck>
            <Graveyard id="graveyard" selected={graveyardSelected}>
                {graveyard.map((card, i) => {
                    return <GraveyardCard key={i} $degree={(i % 2 === 0 ? 1 : -1) * rng.nextInt(0, 4)}>
                        <Card key={i} {...cardRenderParams} card={card} i={i} />
                    </GraveyardCard>
                })}
            </Graveyard>
            <OpponentReapers id="opponent-reapers">
                {
                    Object.entries(reapers).map(([username, cs], i) => {
                        if (username === selfPlayerName) return;
                        return <div key={i} id={username}>
                            <Label $center $emphasize={selfPlayerName === username}>{username} ({hands[username].length} {intl.get('CARDS_IN_HAND')})</Label>
                            <Cards className="reapers">
                                {
                                    cs.map((r, j) => {
                                        const reaperCard = { key: r.key, type: 'reaper', opponent: true };
                                        return <Reaper key={j} id={`${username}_${j}`} className="reaper clickable" onClick={() => {
                                            if (turnState === 'intro') return
                                            setDragAndDropReminder(false);
                                            setGallery([reaperCard, ...r.field]);
                                            setSfx(SelectMP3)
                                        }} $dead={r.field.filter(c => c.type === 'spirit').length === 0}>
                                            <Card key={j} {...cardRenderParams} card={reaperCard} />
                                            {r.field.map((fc, k) => {
                                                return <Card key={k} {...cardRenderParams} card={fc} i={k} />
                                            })}
                                        </Reaper>
                                    })
                                }
                            </Cards>
                        </div>
                    })
                }
            </OpponentReapers>
            <SelfReapers id="self-reapers">
                <Label $center $emphasize={true}>{selfPlayerName} ({intl.get("YOU")})</Label>
                <Cards className="reapers">
                    {
                        reapers[selfPlayerName].map((r, i) => {
                            const reaperCard = { key: r.key, type: 'reaper' };
                            return <Reaper key={i} id={`${selfPlayerName}_${i}`} className="reaper clickable" onClick={() => {
                                setDragAndDropReminder(false);
                                setGallery([reaperCard, ...r.field]);
                                setSfx(SelectMP3)
                            }} $dead={r.field.filter(c => c.type === 'spirit').length === 0}>
                                <Card {...cardRenderParams} key={i} card={reaperCard} />
                                {
                                    r.field.map((fc, j) => {
                                        return <Card {...cardRenderParams} key={j} card={fc} i={j} />
                                    })
                                }
                            </Reaper>
                        })
                    }
                </Cards>
            </SelfReapers>
        </PlayArea>
        <Hand
            id="your-hand"
            className={yourTurn && (turnState === 'play-or-draw' || turnState === 'discard') && gallery.length === 0 && !preventPlayOnPlay ? 'glow clickable' : ''}
            onClick={() => {
                if (turnState === 'intro' || preventPlayOnPlay) return
                const draggable = yourTurn && (turnState === 'play-or-draw' || turnState === 'discard');
                setDragAndDropReminder(draggable);
                setGallery(hands[selfPlayerName]);
                setDragTargets(draggable ? [] : undefined);
                setSfx(SelectMP3)
                setViewing(intl.get('PROMPT.drag-to-play'))
            }}
        >
            {hands[selfPlayerName].map((c, i) => {
                return <Card key={i} {...cardRenderParams} isHand={true} card={c} i={i} />
            })}
        </Hand>

        <>
            {
                (() => {
                    if (!gallery[galleryIdx]) return ""
                    const reaper = reapers[selfPlayerName][orbReaperIdx];
                    const skillIdx = orbToSkillIdx(gallery[galleryIdx].key);
                    const eventHandlers = isMobile() ? {
                        onTouchMove: onDragCard,
                        onTouchEnd: onDragCardEnd,
                    } : {
                        onDrag: onDragCard,
                        onDragEnd: onDragCardEnd,
                    }
                    return <BigCard {...{ eventHandlers, gallery, galleryIdx, reaper, cardDragged, dragAndDropReminder, viewOption, setViewOption, skillIdx, cardRenderParams, yourTurn, turnState, skillSelected, setSkillSelected }} />
                })()
            }
            {modalContent && !cardDragged ? modalContent : gallery.length > 0 && !cardDragged ? <Modal>
                <GalleryOptions>
                    {gallery.map((card, i) => {
                        return <Card
                            key={i}
                            card={card}
                            i={i}
                            className={(mustPlayCards.length > 0 ? mustPlayCards[0].idx === i : i === galleryIdx) ? "selected card-option" : "clickable"}
                            onClick={() => {
                                if (mustPlayCards.length > 0) return
                                setSfx(SelectMP3)
                                setGalleryIdx(i);
                            }}
                        />
                    })}
                </GalleryOptions>
                {gallery[galleryIdx]?.type === 'orb' && yourTurn && turnState === 'play-or-draw' ? <ReaperOptions>
                    {reapers[selfPlayerName].map((r, i) => {
                        if (r.field.filter(c => c.type === 'spirit').length === 0) return
                        return <Card
                            key={i}
                            card={{
                                type: 'reaper',
                                key: r.key,
                            }}
                            i={i}
                            className={i === orbReaperIdx ? "selected" : "clickable"}
                            onClick={() => {
                                setSfx(SelectMP3)
                                setOrbReaperIdx(i);
                            }}
                        />
                    })}
                </ReaperOptions> : ""}
                {turnState !== 'cause-damage' && turnState !== 'force-discard' && turnState !== 'interrupt' && mustPlayCards.length === 0 ? <BackButton onClick={() => {
                    setSfx(ButtonMP3)
                    setGallery([])
                    setGalleryIdx(0)
                    setDragAndDropReminder(false)
                    setViewing(undefined)
                }}>{intl.get('ACTIONS.back')}</BackButton> : ""}
            </Modal> : ''}
        </>
    </div>
};