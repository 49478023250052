import styled from 'styled-components'
import intl from 'react-intl-universal'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { AdMob } from '@capacitor-community/admob';

import { useAppContext } from './context'
import { shuffle } from 'lib/utils'
import { CardsDiscovered, LevelsProgress, ProductsUnlocked, RankApi } from 'lib/storage'

import { Button } from './button'
import { ExperienceBar } from './rank'
import { Padding } from './page'

import 'anim.css'
import DividerImg from 'assets/misc/divider.png'
import VictoryOrbImg from 'assets/misc/victory.png'
import DefeatOrbImg from 'assets/misc/defeat.png'
import LogoGif from 'assets/misc/logo-animation.gif'
import ButtonMP3 from 'assets/sounds/button.mp3'
import UnlockMP3 from 'assets/sounds/unlock.mp3'
import { Card } from './cards';
import { PurchaseProduct, SubscribeProduct } from 'lib/store';

const Backdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.8);
`

const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 10;
`

const Orb = styled.div`
    position: relative;
    background-size: contain;
    width: 200px;
    height: 200px;
    border-radius: 200px;
    left: 50%;

    @keyframes appear {
        0% {
            transform: translate(-50%, -30%) scale(0);
        }

        5% {
            transform: translate(-50%, 10%) scale(0.6);
        }

        7.5% {
            transform: translate(-50%, -5%) scale(0.9);
        }

        10% {
            transform: translate(-50%, -10%) scale(1.1);
        }

        100% {
            transform: translate(-50%, 0%) scale(1);
        }
    }
`

const TieOrb = styled(Orb)`
    background-image: url(${VictoryOrbImg});
    animation: 1.5s appear forwards;
`

const VictoryOrb = styled(Orb)`
    background-image: url(${VictoryOrbImg});
    animation: 1.5s appear forwards, 0.3s plain-glow infinite;

    @keyframes plain-glow {
        0% {
            -webkit-box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
            -moz-box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
            box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
        }

        33% {
            -webkit-box-shadow: 0px 5px 15px 0px rgba(82, 255, 145, 1);
            -moz-box-shadow: 0px 5px 15px 0px rgba(82, 255, 145, 1);
            box-shadow: 0px 5px 15px 0px rgba(82, 255, 145, 1);
        }

        100% {
            -webkit-box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
            -moz-box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
            box-shadow: 0px 5px 10px 0px rgba(82, 255, 145, 0.5);
        }
    }
`

const DefeatOrb = styled(Orb)`
    background-image: url(${DefeatOrbImg});
    animation: 1.5s appear forwards;
`

const ContentWrapper = styled.div`
    position: absolute;
    width: 100%;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

const Divider = styled.img`
    width: 100%;
    margin-top: 2em;
    margin-bottom: 1em;
`

const Text = styled.div`
    font-size: 60px;
    text-align: center;
    margin-bottom: 0.5em;
`

const VictoryText = styled(Text)`
    animation: shine 3s forwards;
`

const MediumText = styled.div`
    font-size: 30px;
    margin: 0.25em;
    text-align: center;
    width: 100%;
`

const CaptionText = styled.div`
    font-size: 20px;
    margin: 1em;
`

const LogoAnim = styled.img`
    position: absolute;
    width: 150px;
    height: 150px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const DiscoveredCards = styled.div`
    padding: 1em 0;
    text-align: center;
    z-index: 10;
    width: 90%;
    outline: solid 2px;
    border-radius: 10px;
    margin: 1.5em auto;

    & > .cmp-card {
        animation: outer-shine 3s forwards;
    }
`

const bestowDiscovery = ({ exp, discoveredCards, setDiscoveredCards, rank, setRank }) => {
    const newRank = RankApi.calcRank(exp)
    setRank(newRank)
    if (newRank.currRank > rank.currRank) {
        const cards = intl.get('CARDS')
        const cardValues = [
            ...Object.keys(cards['orb']).map(k => ({ key: k, type: 'orb', name: cards['orb'][k].name })),
            ...Object.keys(cards['spirit']).map(k => ({ key: k, type: 'spirit', name: cards['spirit'][k].name })),
            ...Object.keys(cards['artifact']).map(k => ({ key: k, type: 'artifact', name: cards['artifact'][k].name })),
        ]
        let card
        shuffle(cardValues).map((c) => {
            if (discoveredCards[c.key]) return
            card = c
        })
        CardsDiscovered.set([card.key], setDiscoveredCards)
        return card
    }
}

export const Victory = ({ setHint }) => {
    const navigate = useNavigate();
    const { level, completedLevels, setCompletedLevels, discoveredCards, setDiscoveredCards, rank, setRank, scene, setSfx, setBgm, setPlayingAd, noAds, setNoAds } = useAppContext();
    const [leveledUp, setLeveledUp] = useState(false)
    const [state, setState] = useState('end')
    useEffect(() => {
        if (scene) {
            if (scene?.newCards?.length > 0) {
                CardsDiscovered.set(scene.newCards.map(c => {
                    return c.key
                }), setDiscoveredCards)
            }
            if (scene?.unlocks?.length > 0) {
                ProductsUnlocked.unlockProducts(scene.unlocks)
                setHint(intl.get('YOU_UNLOCKED', {
                    characters: scene.unlocks.map(p => intl.get('CARDS.reaper')[p.key].name).join(', ')
                }))
            }
            LevelsProgress.completeLevel(level + 1)
            completedLevels[level + 1] = true
            setCompletedLevels({ ...completedLevels })
        } else {
            RankApi.gainExp('victory').then(exp => {
                setLeveledUp(bestowDiscovery({ exp, discoveredCards, setDiscoveredCards, setHint, rank, setRank }))
            })
        }
    }, [])

    return <Wrapper className="cmp-end-screen fade-in">
        <Backdrop />
        <ContentWrapper>
            {(() => {
                switch (state) {
                    case 'end':
                        return <>
                            <VictoryOrb>
                                <LogoAnim src={LogoGif} />
                            </VictoryOrb>
                            <Divider src={DividerImg} />
                            <VictoryText>{intl.get('VICTORY')}</VictoryText>
                            {scene ? <>
                                <Button onClick={() => {
                                    setState('reward')
                                }}>{intl.get('ACTIONS.get_rewards')}</Button>
                            </> : <>
                                <ExperienceBar leveledUp={leveledUp} />
                                <Padding $height={50} />
                                {leveledUp ? <Button onClick={() => {
                                    setSfx(UnlockMP3)
                                    setBgm(false)
                                    setState('reward')
                                    setHint(intl.get('YOU_DISCOVERED'))
                                }}>{intl.get('ACTIONS.get_rewards')}</Button> : <>
                                    {!noAds ? <Button onClick={() => {
                                        setSfx(ButtonMP3)
                                        SubscribeProduct({
                                            productId: 'remove_ads', onUnlock: (key) => {
                                                navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                            }, noAds, setNoAds
                                        })
                                    }}>{intl.get('ACTIONS.no_ads')}</Button> : ''}
                                    <Button onClick={() => {
                                        if (!noAds) {
                                            AdMob.showInterstitial();
                                            setPlayingAd(true)
                                        }
                                        setSfx(ButtonMP3)
                                        navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                    }}>{intl.get('ACTIONS.continue')}</Button>
                                </>}
                            </>}
                        </>
                    case 'reward':
                        return <>
                            {scene?.newCards ? <DiscoveredCards>
                                <MediumText>{intl.get('CARDS_UNLOCKED')}</MediumText>
                                {scene?.newCards.map((c, i) => {
                                    return <Card key={i} card={c} />
                                })}
                                <CaptionText>{intl.get('TRY_IN_SKIRMISH')}</CaptionText>
                            </DiscoveredCards> : ''}
                            {leveledUp ? <>
                                <Card className="unlock" size="big" card={leveledUp} />
                                <CaptionText>{intl.get('TRY_IN_SKIRMISH')}</CaptionText>
                            </> : ''}
                            <>
                                {!noAds ? <Button onClick={() => {
                                    setSfx(ButtonMP3)
                                    SubscribeProduct({
                                        productId: 'remove_ads', onUnlock: () => {
                                            navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                        }, noAds, setNoAds
                                    })
                                }}>{intl.get('ACTIONS.no_ads')}</Button> : ''}
                                <Button onClick={() => {
                                    window.open("https://a.co/d/6W4vY18", '_target')
                                }}>{intl.get('ACTIONS.get_on_amazon')}</Button>
                                <Button onClick={() => {
                                    if (!noAds) {
                                        AdMob.showInterstitial();
                                        setPlayingAd(true)
                                    }
                                    setSfx(ButtonMP3)
                                    navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                }}>{intl.get('ACTIONS.continue')}</Button>
                            </>
                        </>
                }
            })()}
        </ContentWrapper>
    </Wrapper>
}


export const Tie = ({ setHint }) => {
    const navigate = useNavigate();
    const { scene, discoveredCards, setDiscoveredCards, rank, setRank, setSfx, setBgm, setPlayingAd, noAds, setNoAds } = useAppContext();
    const [leveledUp, setLeveledUp] = useState(false)
    const [state, setState] = useState('end')
    useEffect(() => {
        if (scene) return
        RankApi.gainExp('tie').then(exp => {
            setLeveledUp(bestowDiscovery({ exp, discoveredCards, setDiscoveredCards, setHint, rank, setRank }))
        })
    }, [])
    return <Wrapper className="cmp-end-screen fade-in">
        <Backdrop />
        <ContentWrapper>
            {(() => {
                switch (state) {
                    case 'end':
                        return <>
                            <TieOrb>
                                <LogoAnim src={LogoGif} />
                            </TieOrb>
                            <Divider src={DividerImg} />
                            <Text>{intl.get('TIE')}</Text>
                            {scene ? <>
                                <Button onClick={() => {
                                    if (!noAds) {
                                        AdMob.showInterstitial();
                                        setPlayingAd(true)
                                    }
                                    setSfx(ButtonMP3)
                                    navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                }}>{intl.get('ACTIONS.continue')}</Button>
                            </> : <>
                                <ExperienceBar leveledUp={leveledUp} />
                                <Padding $height={50} />
                                {leveledUp ? <Button onClick={() => {
                                    setSfx(UnlockMP3)
                                    setBgm(false)
                                    setState('reward')
                                    setHint(intl.get('YOU_DISCOVERED'))
                                }}>{intl.get('ACTIONS.get_rewards')}</Button> : <Button onClick={() => {
                                    if (!noAds) {
                                        AdMob.showInterstitial();
                                        setPlayingAd(true)
                                    }
                                    setSfx(ButtonMP3)
                                    navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                }}>{intl.get('ACTIONS.continue')}</Button>}
                            </>}
                        </>
                    case 'reward':
                        return <>
                            {leveledUp ? <>
                                <Card className="unlock" size="big" card={leveledUp} />
                                <CaptionText>{intl.get('TRY_IN_SKIRMISH')}</CaptionText>
                            </> : ''}
                            <>
                                {!noAds ? <Button onClick={() => {
                                    setSfx(ButtonMP3)
                                    SubscribeProduct({
                                        productId: 'remove_ads', onUnlock: () => {
                                            navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                        }, noAds, setNoAds
                                    })
                                }}>{intl.get('ACTIONS.no_ads')}</Button> : ''}
                                <Button onClick={() => {
                                    window.open("https://a.co/d/6W4vY18", '_target')
                                }}>{intl.get('ACTIONS.get_on_amazon')}</Button>
                                <Button onClick={() => {
                                    if (!noAds) {
                                        AdMob.showInterstitial();
                                        setPlayingAd(true)
                                    }
                                    setSfx(ButtonMP3)
                                    navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                }}>{intl.get('ACTIONS.continue')}</Button>
                            </>
                        </>
                }
            })()}
        </ContentWrapper>
    </Wrapper>
}

export const Defeat = ({ setHint }) => {
    const navigate = useNavigate();
    const { scene, discoveredCards, setDiscoveredCards, rank, setRank, setSfx, setBgm, setPlayingAd, noAds, setNoAds } = useAppContext();
    const [leveledUp, setLeveledUp] = useState(false)
    const [state, setState] = useState('end')
    useEffect(() => {
        if (scene) return
        RankApi.gainExp('defeat').then(exp => {
            setLeveledUp(bestowDiscovery({ exp, discoveredCards, setDiscoveredCards, setHint, rank, setRank }))
        })
    }, [])
    return <Wrapper className="cmp-end-screen fade-in">
        <Backdrop />
        <ContentWrapper>
            {(() => {
                switch (state) {
                    case 'end':
                        return <>
                            <DefeatOrb>
                                <LogoAnim src={LogoGif} />
                            </DefeatOrb>
                            <Divider src={DividerImg} />
                            <Text>{intl.get('DEFEAT')}</Text>
                            {scene ? <>
                                <Button onClick={() => {
                                    setState('reward')
                                }}>{intl.get('ACTIONS.continue')}</Button>
                            </> : <>
                                <ExperienceBar leveledUp={leveledUp} />
                                <Padding $height={50} />
                                {leveledUp ? <Button onClick={() => {
                                    setSfx(UnlockMP3)
                                    setBgm(false)
                                    setState('reward')
                                    setHint(intl.get('YOU_DISCOVERED'))
                                }}>{intl.get('ACTIONS.get_rewards')}</Button> : <Button onClick={() => {
                                    if (!noAds) {
                                        AdMob.showInterstitial();
                                        setPlayingAd(true)
                                    }
                                    setSfx(ButtonMP3)
                                    navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                }}>{intl.get('ACTIONS.continue')}</Button>}
                            </>}
                        </>
                    case 'reward':
                        return <>
                            {leveledUp ? <>
                                <Card className="unlock" size="big" card={leveledUp} />
                                <CaptionText>{intl.get('TRY_IN_SKIRMISH')}</CaptionText>
                            </> : ''}
                            <>
                                {!noAds ? <Button onClick={() => {
                                    setSfx(ButtonMP3)
                                    SubscribeProduct({
                                        productId: 'remove_ads', onUnlock: () => {
                                            navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                        }, noAds, setNoAds
                                    })
                                }}>{intl.get('ACTIONS.no_ads')}</Button> : ''}
                                <Button onClick={() => {
                                    window.open("https://a.co/d/6W4vY18", '_target')
                                }}>{intl.get('ACTIONS.get_on_amazon')}</Button>
                                <Button onClick={() => {
                                    if (!noAds) {
                                        AdMob.showInterstitial();
                                        setPlayingAd(true)
                                    }
                                    setSfx(ButtonMP3)
                                    navigate(scene ? '/progress?next=campaigns' : '/progress?next=char-select')
                                }}>{intl.get('ACTIONS.continue')}</Button>
                            </>
                        </>
                }
            })()}
        </ContentWrapper>
    </Wrapper>
}