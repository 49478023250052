import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import intl from 'react-intl-universal'

import { Page } from "components/page";
import { AppProvider } from "components/context";
import { TitlePage } from "pages/title";
import { MenuPage } from "pages/menu";
import { CharacterSelectPage } from "pages/char-select";
import { BattlePage } from "pages/battle";
import { ProgressPage } from "pages/progress";
import { LoginPage } from "pages/login";
import { CampaignsPage } from "pages/campaigns";
import { DeleteAccountPage } from "pages/delete-account";

import EnUSLocale from 'assets/data/en-US.json'
import "./index.css";
import { StorePage } from "pages/store";


// Language
const locale = navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
const locales = {}
const localeHandler = data => {
    locales[locale] = data
    intl.init({
        currentLocale: locale, locales,
        warningHandler: e => {
            if (!e.includes('format message failed')) console.warn(e)
        }
    })
}

localeHandler(EnUSLocale)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Router>
            <AppProvider>
                <Page>
                    <Routes>
                        <Route path="/" element={<TitlePage />} />
                        <Route path="/game" element={<TitlePage isGame={true} />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/delete-account" element={<DeleteAccountPage />} />
                        <Route path="/menu" element={<MenuPage />} />
                        <Route path="/campaigns" element={<CampaignsPage />} />
                        <Route path="/char-select" element={<CharacterSelectPage />} />
                        <Route path="/battle" element={<BattlePage />} />
                        <Route path="/progress" element={<ProgressPage />} />
                        <Route path="/store" element={<StorePage />} />
                        <Route path="/char-unlocked" element={<CharacterSelectPage unlockedCharsPage />} />
                    </Routes>
                </Page>
            </AppProvider>
        </Router>
    </React.StrictMode>
);
