import styled from 'styled-components'
import { useAppContext } from './context'

const ExpWrapper = styled.div`
    width: 300px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
`

const ExpBarWrapper = styled.div`
    position: absolute;
    width: 300px;
    height: 20px;
    background: #2f2f2f;
    border-radius: 100px;
    border: solid 2px #555555;
`

const ExpProgress = styled.div.attrs(({ $prog }) => ({
    $prog
}))`
    position: absolute;
    top: 0;
    width: 0;
    height: 20px;
    background: rgb(253,253,11);
    border-radius: 100px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(253,253,11,1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(253,253,11,1);
    box-shadow: 0px 0px 10px 0px rgba(253,253,11,1);
    animation: 1.5s expand forwards;

    @keyframes expand {
        from {
            width: 0;
        }

        to {
            width: ${({ $prog }) => $prog === 0 ? 3 : parseInt($prog * 300)}px;
        }
    }
`

const Rank = styled.div`
    position: relative;
    font-size: 24px;
    text-align: right;
`

const Exp = styled.div`
    position: absolute;
    font-size: 20px;
    text-align: left;
    bottom: 0;
    left: 0.25em;
`

const Percent = styled.div`
    position: absolute;
    font-size: 20px;
    text-align: left;
    left: 0.5em;
    z-index: 1;
    color: #222;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #fff;
`

export const ExperienceBar = ({ leveledUp }) => {
    const { rank } = useAppContext();
    return <ExpWrapper>
        <Exp>Exp. {rank.currExp} → {rank.nextRankExp}</Exp>
        <Rank >{leveledUp ? "↑" : ''} Lvl. {rank.currRank || 1}</Rank>
        <ExpBarWrapper>
            <Percent>{parseInt(100 * (rank.progress < 0 ? 0 : rank.progress))}%</Percent>
            <ExpProgress $prog={rank.progress || 0} />
        </ExpBarWrapper>
    </ExpWrapper>
}