import styled from "styled-components"
import intl from "react-intl-universal"
import $ from 'jquery'

import { Cards, Card } from "./cards"


const Wrapper = styled.div`
    margin: 0.5em;
    overflow: auto;
    padding: 1em;
    background: #2f2f2f;
    border-radius: 8px;
    font-size: 20px;

    & > div {
        display: inline;
        line-height: 32px;
    }
`

const SkillName = styled.div`
    border: solid 1px white;
    width: fit-content;
    padding: 0.25em 0.5em;
    border-radius: 8px;
    margin-right: 0.5em;
`

export const SkillChoices = ({ reaper, className, onClick }) => {
    const reaperTexts = intl.get('CARDS.reaper')[reaper]
    return <div>
        {reaperTexts.skills.map((s, i) => {
            return <Wrapper
                key={i} className={`cmp-choice ${className || ""}`} onClick={onClick}
                data-meta={JSON.stringify({
                    reaper,
                    skillIdx: i
                })}
            >
                <SkillName>{s.name}</SkillName>
                <div>{s.desc}</div>
            </Wrapper>
        })}
    </div>
}

const TextChoice = styled.div`
    text-align: center;
    padding: 1em;
    font-size: 32px;
`

export const TargetChoices = ({ targets, reapers, className, onClick }) => {
    return targets.map((idStr, j) => {
        let username, idx
        const id = idStr.slice(1)
        const idParts = id.split('_')
        if (idParts.length === 2) {
            username = idParts[0]
            idx = idParts[1]
        }
        return <div
            key={j} className={`cmp-choice glow ${className || ""}`} onClick={(e) => {
                if (onClick) onClick(e)
                $('.cmp-choice').removeClass("selected")
                $(e.target).closest('.cmp-choice').toggleClass('selected')
            }}
            data-meta={JSON.stringify({
                id,
                username,
                idx
            })}
        >
            {(() => {
                switch (id) {
                    case 'your-hand':
                        return <TextChoice>{intl.get('YOUR_HAND')}</TextChoice>
                    case 'graveyard':
                        return <TextChoice>{intl.get('GRAVEYARD')}</TextChoice>
                    default:
                        const reaperIdx = parseInt(idx)
                        return <Cards>
                            <Card key={-1} card={{
                                type: 'reaper',
                                key: reapers[username][reaperIdx].key
                            }} i={-1} />
                            {reapers[username][reaperIdx].field.map((c, i) => {
                                return <Card key={i} card={c} i={i} />
                            })}
                        </Cards>
                }
            })()}
        </div>



    })
}