import styled from "styled-components"

export const Label = styled.div.attrs(({ $emphasize, $center }) => ({
    $emphasize, $center
}))`
    font-size: 20px;
    margin: ${({ $center }) => $center ? '0.5em auto' : '0.5em'};
    width: fit-content;
    border: solid 1px #FFF;
    padding: 0.1em 0.5em;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.75);
    background: ${({ $emphasize }) => $emphasize ? "rgba(98, 126, 191, 0.9)" : "rgba(50,50,50,0.9)"};
`
