import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { useAppContext } from "components/context";
import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'
import { useSafearea } from "components/page";

import ButtonMP3 from 'assets/sounds/button.mp3'
import { Button, Buttons } from "components/button";
import { Modal } from "components/modal";
import { ListPurchasedProducts, RegisterProduct } from "lib/api";
import { ProductIdToReaperKey, RestoreProducts, SubscribeProduct } from "lib/store";
import { ProductsUnlocked } from "lib/storage";
import { SettingsButton } from "components/settings";

const Wrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
`

const BigButton = styled.div`
    font-size: 40px;
    margin: 0.5em auto;
`

const HintWrapper = styled.div.attrs(({ $bottom }) => ({
    $bottom,
}))`
    position: fixed;
    transition: bottom 0.5s;
    bottom: calc(-20vh + ${({ $bottom }) => $bottom}px);
    border-radius: 100px;
    background: #2f2f2f;
    border: solid 2px white;
    font-size: 24px;
    padding: 0.5em 1em;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    min-width: 75%;
    text-align: center;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);

    &.show {
        bottom: calc(5px + ${({ $bottom }) => $bottom}px);
    }
`

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #222222;
    padding: 2em;
    border-radius: 5px;
    width: 60%;
    max-width: 350px;

    & .cmp-button {
        font-size: 20px;
        margin-top: 1em;
    }
`

const Prompt = styled.div`
    font-size: 24px;
    text-align: center;
`


let hintDebounce

export const StorePage = () => {
    const navigate = useNavigate();
    const { isLoggedIn, acctEmail, setSfx, noAds, setNoAds } = useAppContext();
    const { safearea } = useSafearea();
    const [restoreModal, setRestoreModal] = useState(false);
    const [adsModal, setAdsModal] = useState(false);
    const [hint, setHint] = useState('')

    useEffect(() => {
        if (!isLoggedIn) navigate('/login')
    }, [isLoggedIn])

    useEffect(() => {
        if (!hint) return
        if (hintDebounce) clearTimeout(hintDebounce)
        hintDebounce = setTimeout(() => {
            setHint('')
        }, 3000)
    }, [hint])


    return (
        <>
            <DeadNightSky />
            <div className="bg-smoke" />
            <Moon>
                <Eclipse />
            </Moon>
            <Mountain />
            <div className="fg-smoke" />
            <SettingsButton />
            <HintWrapper id="hint" className={hint ? 'show' : ""} $bottom={safearea.bottom} onClick={() => {
                setHint('')
            }}>{hint || ""}</HintWrapper>
            <Wrapper>
                <BigButton className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    navigate('/char-unlocked')
                }}>{intl.get('STORE.CHARACTERS')}</BigButton>
                {/* <BigButton className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    setAdsModal(true)
                }}>{intl.get('STORE.MANAGE_ADS')}</BigButton> */}
                <BigButton className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    setRestoreModal(true)
                }}>{intl.get('STORE.RESTORE_PURCHASES')}</BigButton>
            </Wrapper>
            {restoreModal ? <Modal>
                <ModalWrapper>
                    <Prompt>
                        {intl.get('RESTORE_PURCHASES', { email: acctEmail })}
                    </Prompt>
                    <Buttons>
                        <Button onClick={() => {
                            ListPurchasedProducts().then((products) => {
                                Object.keys(products).map((productId) => {
                                    RegisterProduct(productId)
                                })
                                ProductsUnlocked.unlockProducts(Object.keys(products).map((productId) => {
                                    return { key: ProductIdToReaperKey(productId) }
                                }))
                                RestoreProducts().then(() => {
                                    setHint(intl.get('PURCHASES_RESTORED'))
                                })
                                setRestoreModal(false)
                            })
                        }}>{intl.get('ACTIONS.confirm')}</Button>
                        <Button onClick={() => {
                            setRestoreModal(false)
                        }}>{intl.get('ACTIONS.cancel')}</Button>
                    </Buttons>
                </ModalWrapper>
            </Modal> : ''}
            {adsModal ? <Modal>
                <ModalWrapper>
                    <Prompt>
                        {noAds ? intl.get('STORE.ALREADY_SUBSCRIBED') : intl.get('STORE.REMOVE_ADS_PROMPT')}
                    </Prompt>
                    {!noAds ? <Buttons>
                        <Button onClick={() => {
                            SubscribeProduct({
                                productId: 'remove_ads', noAds, setNoAds
                            })
                        }}>{intl.get('STORE.REMOVE_ADS')}</Button>
                        <Button onClick={() => {
                            setAdsModal(false)
                        }}>{intl.get('ACTIONS.cancel')}</Button>
                    </Buttons> : ''}
                </ModalWrapper>
            </Modal> : ''}
        </>
    );
};