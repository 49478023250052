import intl from "react-intl-universal"
import $ from 'jquery'

import { applyArtifactInterrupt, orbToSkillIdx, spiritToSPCount, validateMaxSpiritsRule } from "./field"
import { Card } from "components/cards"
import { applySkillInterrupt, applySkill, getReaperSkillTargets, applyEffects } from "./reapers"
import { randomElement, shuffle } from "./utils"
import { applyArtifact, getArtifactTargets } from "./artifact"

import AttackMP3 from 'assets/sounds/attack.mp3'

export const npcReceiveDamage = ({ username, idx, hands, setHands, reapers, setReapers, graveyard, setGraveyard, setCardMoveEle, setCardMovePos, setMovingCard, setOnCardMoveEnd, setHint, setTurnState, cardParams, hasSelfDamage }) => {
    const reaper = reapers[username][idx]
    let absorbed = 0
    let consumedCards = []
    let maxSp = 0
    let spirits = reapers[username][idx].field.filter(c => c.type === 'spirit')
    reaper.field.map((c, i) => {
        c.cardIdx = i
        switch (c.type) {
            case 'orb':
            case 'artifact':
                if (!reaper.ignoreProtection && c.damageReduced && absorbed < reaper.damage) {
                    absorbed += c.damageReduced
                    consumedCards.push({ ...c })
                }
                break
            case 'spirit':
                const s = spiritToSPCount(c.key)
                if (s.sp > maxSp) maxSp = s.sp
                break
        }
    })

    if (absorbed >= reaper.damage) {
        reapers[username][idx].field = reapers[username][idx].field.filter((c, i) => !consumedCards.map((v) => v.cardIdx).includes(i))
        reapers[username][idx].damage = 0
        reapers[username][idx].damageCausedBy = null
        setReapers({ ...reapers })
        setGraveyard([...graveyard, ...consumedCards])
        setTurnState('discard')
        return consumedCards
    }

    const computeDamage = (spiritOrderPreference) => {
        for (let i = 0; i < (reaper.damageTargets || []).length; i++) {
            if (absorbed >= reaper.damage) break
            reapers[username][idx].field.map(c => {
                if (c.type !== 'spirit') return
                const ct = spiritToSPCount(c.key, spirits.length === 1)
                const damagedBy = reaper.damageCausedBy
                const ignoreProtection = damagedBy ? reaper.ignoreProtection : false
                const pp = ignoreProtection ? 0 : (ct.pp || 0)
                const isTarget = c.cardIdx === reaper.damageTargets[i].cardIdx
                const isBlocked = (absorbed + pp) >= reaper.damage && !ignoreProtection
                if (c.cardIdx === reaper.damageTargets[i].cardIdx) absorbed += pp + ct.sp
                if (isTarget && !ct.undead && !isBlocked) {
                    consumedCards.push({ ...c })
                }
            })
        }
        for (let i = 0; i < spiritOrderPreference.length; i++) {
            if (absorbed >= reaper.damage) break
            reapers[username][idx].field.map(c => {
                if (c.type !== 'spirit') return
                if (absorbed < reaper.damage && c.key === spiritOrderPreference[i]) {
                    const ct = spiritToSPCount(spiritOrderPreference[i], spirits.length === 1)
                    const damagedBy = reaper.damageCausedBy
                    const ignoreProtection = damagedBy ? reaper.ignoreProtection : false
                    const pp = ignoreProtection ? 0 : (ct.pp || 0)
                    const isBlocked = (absorbed + pp) >= reaper.damage && !ignoreProtection
                    if (c.key === 'snow-clown-spirit') ct.sp = maxSp
                    if (isBlocked) {
                        absorbed += pp
                        setHint(intl.get('HINT.damage_blocked', {
                            user: username,
                            name: intl.get('CARDS.reaper')[reaper.key].name,
                            damage: pp
                        }))
                        return
                    }
                    absorbed += pp + ct.sp
                    if (!ct.undead) consumedCards.push({ ...c })
                    return
                }
                return
            })
        }
    }

    switch (reaper.damage - absorbed) {
        case 1:
            computeDamage(['half-dead-spirit', 'half-alive-spirit', 'lesser-spirit', 'mini-spirit', 'ordinary-spirit', 'snow-clown-spirit', 'greater-spirit', 'infinity-spirit'])
            break
        case 2:
            computeDamage(['half-dead-spirit', 'ordinary-spirit', 'lesser-spirit', 'mini-spirit', 'half-alive-spirit', 'snow-clown-spirit', 'greater-spirit', 'infinity-spirit'])
            break
        default:
            computeDamage(['half-dead-spirit', 'greater-spirit', 'infinity-spirit', 'snow-clown-spirit', 'ordinary-spirit', 'half-alive-spirit', 'lesser-spirit', 'mini-spirit'])
            break
    }

    if (absorbed >= reaper.damage) {
        reapers[username][idx].field = reapers[username][idx].field.filter((c, idx) => !consumedCards.map((v) => v.cardIdx).includes(idx))
    } else {
        reapers[username][idx].field = []
    }
    reapers[username][idx].damage = 0
    setReapers({ ...reapers })

    if (reaper.discard) {
        hands[username].splice(parseInt(Math.random() * hands[username]), 1)
        setHands({ ...hands })
    }
    if (consumedCards.length > 0) {
        const fieldPos = document.getElementById(`${username}_${idx}`)?.getBoundingClientRect();
        const graveyardPos = document.getElementById('graveyard')?.getBoundingClientRect();
        setCardMovePos({ startX: fieldPos.x + fieldPos.width / 2, startY: fieldPos.y, endX: graveyardPos.x, endY: graveyardPos.y })
        setCardMoveEle(<Card card={{ ...consumedCards[0] }} i={-1} />)
        setMovingCard('start')
        setOnCardMoveEnd(() => () => {
            setGraveyard([...graveyard, ...consumedCards])
            setOnCardMoveEnd(false)
            reapers[username][idx].trapApplied = false
            setReapers({ ...reapers })
            if (hasSelfDamage) return
            if (cardParams.afterState && !reapers[username][idx].trapApplied) cardParams.afterState('computer-damage')
            else setTurnState('discard')
        })
    } else {
        setTurnState('discard')
    }
    return consumedCards
}


export const npcPlay = (params) => {
    const { currPlayer, setCurrPlayer, currPlayerName, setGallery, players, hands, setHands, deck, setDeck, reapers, setReapers, setCardMovePos, setMovingCard, setOnCardMoveEnd, cardParams, setCardParams, turnState, setTurnState, playedCard, setPlayedCard, setHint, graveyard, setGraveyard, setSfx, clearReaperState, setModalContent } = params
    switch (turnState) {
        case 'draw':
            setPlayedCard(undefined)
            drawCard({ currPlayerName, setCardMovePos, setMovingCard, deck, setDeck, hands, setHands, setTurnState, setOnCardMoveEnd, afterDrawState: 'action' })
            break;
        case 'action':
            let applied
            let mustPlayCardIdx = -1
            hands[currPlayerName].map((c, i) => {
                c.cardIdx = i
                if (c.mustPlay) mustPlayCardIdx = i
            })
            const shuffledHand = mustPlayCardIdx !== -1 ? [hands[currPlayerName][mustPlayCardIdx]] : shuffle([...hands[currPlayerName]])
            params.casterUsername = currPlayerName
            do { params.casterReaperIdx = Math.floor(Math.random() * reapers[currPlayerName].length) }
            while (reapers[currPlayerName][params.casterReaperIdx].field.filter(c => c.type === 'spirit').length === 0)

            // DEBUG
            // let effectiveHand = [...hands[currPlayerName]]
            // if (shuffledHand.length > 0)
            //     effectiveHand = [{ key: 'hanging-wire', type: 'artifact', sub_type: 'spell' }]
            // // effectiveHand = [{ key: 'green-orb', type: 'orb' }]
            // hands['computer'] = [...effectiveHand]
            // setHands({ ...hands })

            let effectiveHand = shuffledHand
            reapers[currPlayerName].map((r) => {
                const spiritsCount = r.field.filter(c => c.type === 'spirit').length
                const spCount = r.field.map(c => {
                    return (c.type === 'spirit' && spiritToSPCount(c.key, spiritsCount === 1).sp) || 0
                }).reduce((cumsum, v) => cumsum + v, 0)
                if (spCount <= 2 || spiritsCount === 1) {
                    effectiveHand = [...effectiveHand.sort((a, b) => {
                        return a.type === 'spirit' ? -1 : 1
                    })]
                }
            })
            effectiveHand.map((c, i) => {
                if (applied) return
                c.cardIdx = i
                c.reaper = reapers[currPlayerName][params.casterReaperIdx].key
                c.show = true
                params.cardIdx = c.cardIdx
                params.card = c
                params.originalCard = c
                params.dryRun = true
                switch (c.type) {
                    case 'orb':
                        computeCardTarget(params)
                        if (applySkill(params)) applied = true;
                        break;
                    case 'spirit':
                        if (validateMaxSpiritsRule(reapers[params.casterUsername][params.casterReaperIdx].field, c)) applied = true;
                        break;
                    case 'artifact':
                        computeCardTarget(params)
                        if (applyArtifact(params)) applied = true;
                        break;
                }
                if (mustPlayCardIdx !== -1) applied = true;
            })
            if (!applied) {
                drawCard({ currPlayerName, setCardMovePos, setMovingCard, deck, setDeck, hands, setHands, setTurnState, setOnCardMoveEnd, afterDrawState: 'discard' })
                setHint(intl.get('HINT.drawn_another', { user: currPlayerName }))
                break
            }
            if (!params?.overridePlayedCard) {
                const playedCard = params.originalCard || params.card
                setPlayedCard({ ...playedCard })
                setHint(intl.get('HINT.played_card', {
                    user: currPlayerName,
                    card: intl.get('CARDS')[playedCard.type][playedCard.key].name
                }))
                params.overridePlayedCard = false
            }
            setReapers({ ...reapers })
            setSfx(AttackMP3)
            setTimeout(() => {
                params.card.show = false
                setPlayedCard({ ...params.card })
                setCardParams({ ...params })
                setTurnState('play-or-draw')
            }, 2000)
            break
        case 'play-or-draw':
            if (Object.keys(cardParams).length === 0 || !playedCard) {
                setTurnState('action')
                break
            }
            cardParams.dryRun = false
            switch (playedCard.type) {
                case 'orb':
                    for (let k = 0; k < (reapers[currPlayerName][cardParams.casterReaperIdx]?.field || []).length; k++) {
                        const card = reapers[currPlayerName][cardParams.casterReaperIdx].field[k]
                        if (card.skipPlayOrDraw) {
                            graveyard.push(...reapers[currPlayerName][cardParams.casterReaperIdx].field.splice(k, 1))
                            setGraveyard([...graveyard])
                            setTurnState('discard')
                            return
                        }
                    }
                    applySkill(cardParams)
                    break;
                case 'spirit':
                    hands[cardParams.casterUsername].splice(cardParams.cardIdx, 1)
                    if (validateMaxSpiritsRule(reapers[cardParams.casterUsername][cardParams.casterReaperIdx].field, cardParams.card))
                        reapers[cardParams.casterUsername][cardParams.casterReaperIdx].field.push({ ...cardParams.card })
                    else {
                        graveyard.push({ ...cardParams.card })
                        setGraveyard([...graveyard])
                    }
                    setHands({ ...hands })
                    setReapers({ ...reapers })
                    setTurnState('discard')
                    setCardParams({})
                    break
                case 'artifact':
                    applyArtifact({ ...params, ...cardParams })
                    break
            }
            break;
        case 'force-discard':
            hands[currPlayerName].splice(Math.floor(Math.random() * hands[currPlayerName].length), 1)
            setPlayedCard(undefined)
            setGallery([])
            setHands({ ...hands })
            if (cardParams.afterState) {
                cardParams.afterState('computer-force-discard')
            } else
                setTurnState('discard')
            break
        case 'discard':
            while (hands[currPlayerName].length > 4)
                hands[currPlayerName].splice(Math.floor(Math.random() * hands[currPlayerName].length), 1)
            setPlayedCard(undefined)
            setGallery([])
            setCurrPlayer((currPlayer + 1) % players.length)
            setTurnState('next-player')
            setHands({ ...hands })
            setCardParams({})
            break
        case 'interrupt':
            setPlayedCard(undefined)
            setGallery([])
            setCurrPlayer((currPlayer + 1) % players.length)
            setTurnState('next-player')
            clearReaperState()
            setCardParams({})
            setModalContent(undefined)
            break
    }
}

const drawCard = ({ currPlayerName, setCardMovePos, setMovingCard, deck, setDeck, hands, setHands, setTurnState, setOnCardMoveEnd, afterDrawState }) => {
    const deckPos = document.getElementById('deck')?.getBoundingClientRect();
    const fieldPos = document.getElementById(`${currPlayerName}_0`)?.getBoundingClientRect();
    setCardMovePos({ startX: deckPos.x, startY: deckPos.y, endX: ($(window).width() - $('#deck').width()) / 2, endY: fieldPos.y })
    setMovingCard('start')
    const drawnCard = deck.pop();
    setDeck([...deck]);
    setTurnState('drawing')
    setOnCardMoveEnd(() => () => {
        drawnCard.username = currPlayerName;
        drawnCard.idx = hands[currPlayerName].length
        hands[currPlayerName].push(drawnCard)
        setHands({ ...hands })
        setTurnState(afterDrawState)
        setOnCardMoveEnd(false)
    })
}

export const computeCardTarget = (params) => {
    const { reapers, casterUsername, casterReaperIdx, card } = params;
    const targetReapers = []
    switch (card.type) {
        case 'orb':
            params.skillIdx = orbToSkillIdx(card.key, true)
            params.skillState = 0
            const t = intl.get('CARDS.reaper')[reapers[casterUsername][casterReaperIdx].key]
            params.card.description = `${t.name} - ${t.skills[params.skillIdx].name}: ${t.skills[params.skillIdx].desc}`
            getReaperSkillTargets[card.reaper]({
                reapers, username: casterUsername, orbReaperIdx: casterReaperIdx, skillIdx: params.skillIdx, skillState: params.skillState
            }).map((id) => {
                const parts = id.split('_')
                if (parts.length === 2) {
                    targetReapers.push({ username: parts[0].slice(1), reaperIdx: parts[1] })
                }
            })
            break
        case 'artifact':
            const id = randomElement(getArtifactTargets({
                reapers, username: casterUsername, card
            }))
            const parts = id.split('_')
            if (parts.length === 2) {
                targetReapers.push({ username: parts[0].slice(1), reaperIdx: parts[1] })
            }
            params.selectedEle = $(id)
            break;
    }

    if (targetReapers.length > 0) {
        const chosenTarget = targetReapers[Math.floor(Math.random() * targetReapers.length)]
        params.targetUsername = chosenTarget.username
        params.targetReaperIdx = chosenTarget.reaperIdx
        return [chosenTarget.username, chosenTarget.reaperIdx]
    }
    return []
}

export const npcApplyInterrupt = (params) => {
    const { hands, reapers, casterUsername } = params;
    let interrupted
    Object.keys(hands).map(username => {
        if (username === casterUsername) return
        params.targetUsername = username
        reapers[username].map((r, reaperIdx) => {
            if (interrupted) return
            params.targetReaperIdx = reaperIdx
            hands[username].map((c, cardIdx) => {
                params.targetCardIdx = cardIdx
                params.card = c
                switch (c.type) {
                    case 'orb':
                        interrupted = applySkillInterrupt(params)
                        break
                    case 'artifact':
                        interrupted = applyArtifactInterrupt(params)
                        break
                }
            })
        })
    })
    return interrupted
}