import styled from 'styled-components'

export const Text = styled.div.attrs(({ $size }) => ({
    $size
}))`
    font-size: ${({ $size }) => $size === 'sm' ? '20px' : '28px'};
`

const StyledInput = styled.input`
    font-size: 20px;
    padding: 0.5em 1em;
    border-radius: 4px;
    line-height: 24px;
`

export const Input = (props) => {
    return <div>
        <StyledInput {...props} />
    </div>
}

export const Error = styled.div`
    color: red;
    font-size: 20px;
`

export const Button = styled.div`
    font-size: 24px;
    background: #2f2f2f;
    margin: 0.5em auto;
    width: fit-content;
    padding: 0.25em 0.5em;
    border-radius: 4px;
    border: solid 1px #ffffff;
    cursor: pointer;
`