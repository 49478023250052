import styled from 'styled-components'

import MountainImg from 'assets/misc/mountain.png'

export const Moon = styled.div`
    position: absolute;
    background-color: #ffe7a6;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
    -moz-box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
    box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
    width: 50vw;
    height: 50vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50vw;
    animation: slow-glow 5s infinite, slow-oscillate 40s linear forwards;
    aniamtion-iteration-count: 1;

    @keyframes slow-glow {
        0% {
            -webkit-box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
            -moz-box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
            box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
        }

        100% {
            -webkit-box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
            -moz-box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
            box-shadow: 0px 0px 25px 0px rgba(255,231,166,1);
        }
    }

    @keyframes slow-oscillate {
        0% {
            left: 40%;
        }
    
        100% {
            left: 60%;
        }
    }
`

export const Eclipse = styled.div`
    position: absolute;
    width: 45vw;
    height: 45vw;
    left: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30vw;
    background: rgb(27,24,33);
    background: radial-gradient(circle, rgba(27,24,33,1) 80%, rgba(72,67,84,1) 100%);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255,231,166,1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(255,231,166,1);
    box-shadow: 0px 0px 15px 0px rgba(255,231,166,1);
    animation: move-left 20s linear forwards;
    aniamtion-iteration-count: 1;

    @keyframes move-left {
        from {
            left: 60%;
        }
    
        to {
            left: 40%;
        }
    }
`

export const MountainWrapper = styled.div`
    position: absolute;
    height: 100vh;
    width: 100%;
    background-repeat: repeat-x;
    background-position: bottom;
    background-image: url(${props => props.src});

    animation: parallax-right 60s linear infinite;
    @keyframes parallax-right {
        from {
            background-position: bottom 0 left 1200px;
        }
    
        to {
            background-position: bottom 0 left 0;
        }
    }
`

export const Mountain = () => {
    return <MountainWrapper src={MountainImg} />
}

export const DeadNightSky = styled.div`
    position: absolute;
    height: 100vh;
    width: 100%;
    background: rgb(72,67,84);
    background: radial-gradient(circle, rgba(72,67,84,1) 0%, rgba(27,24,33,1) 100%);
`