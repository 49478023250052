import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'
import { Card, CardBack } from "components/cards";
import { Modal } from "components/modal";
import { Padding, useSafearea } from "components/page";
import { useAppContext } from "components/context";

import 'anim.css'
import { ExperienceBar } from "components/rank";

import ButtonMP3 from 'assets/sounds/button.mp3'
import SelectMP3 from 'assets/sounds/select.mp3'
import FinalExtinctionMP3 from 'assets/sounds/final_extinction.mp3'

const GalleryWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
`

const Wrapper = styled.div`
    overflow: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`

const CardsWrapper = styled.div`
    max-width: 600px;
    margin: auto;
    overflow: auto;
    width: 100%;
    height: calc(65vh - ${({ offset }) => offset}px);
    text-align: center;

    @media (max-height: 800px) {
        height: calc(55vh - ${({ offset }) => offset}px);
    }
`

const Button = styled.div`
    font-size: 40px;
`

const ProgressWrapper = styled.div`
    font-size: 32px;
    padding: 0.75em;
    text-align: center;
    animation: shine 3s forwards;
`

const BigCardWrapper = styled.div`
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`

export const ProgressPage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { isLoggedIn, discoveredCards, setSfx, setBgm } = useAppContext();
    const nextPage = searchParams.get('next')

    useEffect(() => {
        if (isLoggedIn === false) navigate('/login')
    }, [isLoggedIn])

    useEffect(() => {
        setBgm(FinalExtinctionMP3);
    }, [])

    const cards = intl.get('CARDS')
    const [modal, setModal] = useState(undefined);
    const { safearea } = useSafearea();
    const total = Object.keys(cards['reaper']).length + Object.keys(cards['orb']).length + Object.keys(cards['spirit']).length + Object.keys(cards['artifact']).length
    const discovered = Object.keys(discoveredCards).length

    return (
        <GalleryWrapper>
            <DeadNightSky />
            <div className="bg-smoke" />
            <Moon>
                <Eclipse />
            </Moon>
            <Mountain />
            <div className="fg-smoke" />
            <Wrapper>
                <CardsWrapper offset={safearea.top + safearea.bottom}>
                    {Object.keys(cards).map((type, i) => {
                        return Object.keys(cards[type]).map((key, j) => {
                            return discoveredCards[key] ? <Card className="clickable" key={`${i}-${j}`} card={{
                                type, key
                            }} onClick={() => {
                                setSfx(SelectMP3)
                                setModal(<Modal>
                                    <BigCardWrapper>
                                        <Card size="big" card={{ type, key }} />
                                        <Button className="button" onClick={() => {
                                            setSfx(ButtonMP3)
                                            setModal(undefined)
                                        }}>{intl.get('ACTIONS.back')}</Button>
                                    </BigCardWrapper>
                                </Modal>)
                            }} /> : <CardBack $size='small' key={`${i}-${j}`} />
                        })
                    })}
                </CardsWrapper>
                <ProgressWrapper>
                    {discovered === 0 ? 0 : Math.floor(100 * discovered / total)}% {intl.get('DISCOVERED')}
                </ProgressWrapper>
                <ExperienceBar />
                <Padding $height={50} />
                {nextPage ? <Button className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    navigate(`/${nextPage}`)
                }}>{intl.get('ACTIONS.next')}</Button> : <Button className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    navigate('/menu')
                }}>{intl.get('ACTIONS.back')}</Button>}
            </Wrapper>
            {modal || ''}
        </GalleryWrapper>
    );
};