import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { useAppContext } from "components/context";
import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'
import { useSafearea } from "components/page";

import ButtonMP3 from 'assets/sounds/button.mp3'
import ErrorMP3 from 'assets/sounds/error.mp3'
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

const Wrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
`

const BigButton = styled.div`
    font-size: 40px;
    margin: 0.5em auto;
`

const HintWrapper = styled.div.attrs(({ $bottom }) => ({
    $bottom,
}))`
    position: fixed;
    transition: bottom 0.5s;
    bottom: calc(-20vh + ${({ $bottom }) => $bottom}px);
    border-radius: 100px;
    background: #2f2f2f;
    border: solid 2px white;
    font-size: 24px;
    padding: 0.5em 1em;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    min-width: 75%;
    text-align: center;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);

    &.show {
        bottom: calc(5px + ${({ $bottom }) => $bottom}px);
    }
`

let hintDebounce

export const MenuPage = () => {
    const navigate = useNavigate();
    const { isLoggedIn, setSfx } = useAppContext();
    const { safearea } = useSafearea();
    const [hint, setHint] = useState('')

    useEffect(() => {
        if (!isLoggedIn) navigate('/login')
    }, [isLoggedIn])

    useEffect(() => {
        if (!hint) return
        if (hintDebounce) clearTimeout(hintDebounce)
        hintDebounce = setTimeout(() => {
            setHint('')
        }, 3000)
    }, [hint])


    return (
        <>
            <DeadNightSky />
            <div className="bg-smoke" />
            <Moon>
                <Eclipse />
            </Moon>
            <Mountain />
            <div className="fg-smoke" />
            <HintWrapper id="hint" className={hint ? 'show' : ""} $bottom={safearea.bottom} onClick={() => {
                setHint('')
            }}>{hint || ""}</HintWrapper>
            <Wrapper>
                <BigButton className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    navigate('/campaigns')
                }}>{intl.get('MENU.CAMPAIGN')}</BigButton>
                <BigButton className="button" onClick={(e) => {
                    setSfx(ButtonMP3)
                    navigate('/char-select')
                }}>{intl.get('MENU.SKIRMISH')}</BigButton>
                <BigButton className="button" onClick={(e) => {
                    setSfx(ErrorMP3)
                    const ele = e.target;
                    ele.className = 'button failed'
                    ele.style.animationName = "none"
                    setHint(intl.get('COMING_SOON'))
                    setTimeout(() => {
                        ele.style.animationName = ""
                    }, 0);
                }}>{intl.get('MENU.VERSUS')}</BigButton>
                <BigButton className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    navigate('/progress')
                }}>{intl.get('MENU.PROGRESS')}</BigButton>
                <BigButton className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    navigate('/store')
                }}>{intl.get('MENU.STORE')}</BigButton>
                <BigButton className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    navigate('/delete-account')
                }}>{intl.get('MENU.DELETE_ACCOUNT')}</BigButton>
            </Wrapper>
        </>
    );
};