import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components'
import Prando from 'prando';
import intl from 'react-intl-universal'
import $ from 'jquery'

import LockedIcon from "assets/icons/lock_24px_outlined.svg"
import { useAppContext } from "components/context";
import { useSafearea } from "components/page";
import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'
import { SettingsButton } from "components/settings";

import VortexGif from 'assets/misc/vortex.gif'
import CloseSvg from 'assets/icons/close.svg'
import { IsProductOwned, PurchaseProduct } from "lib/store";
import { ProductsUnlocked } from "lib/storage";

import ButtonMP3 from 'assets/sounds/button.mp3'
import SelectMP3 from 'assets/sounds/select.mp3'
import ErrorMP3 from 'assets/sounds/error.mp3'
import { Modal } from "components/modal";
import { Button } from "components/button";
import { Card } from "components/cards";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { RateApp } from "capacitor-rate-app";

const View = styled.div`
    overflow: hidden;
`

const SelectWrapper = styled.div`
    white-space: nowrap;
    overflow: auto;
    padding-bottom: 0.5em;
    text-align: center;
`

const HeadShotWrapper = styled.div`
    margin: 0.25em;
    display: inline-block;
    position: relative;
`

const Headshot = styled.img`
    width: 120px;
    border-radius: 15px;
    background: rgb(238,31,31);
    background: radial-gradient(circle, rgba(238,31,31,1) 0%, rgba(212,101,101,1) 20%, rgba(203,94,192,1) 80%, rgba(157,98,119,1) 100%);
    animation: ${props => props.selected ? "0.3s glow" : "none"};
    animation-iteration-count: infinite;
    border: ${props => props.selected ? "solid 2px #FFFFFF" : "solid 2px rgba(0,0,0,0)"};
    opacity: ${props => props.disabled ? 0.7 : 1};
    cursor: pointer;
`

const CharacterArt = styled.img.attrs(({ $top, $bottom }) => ({
    $top,
    $bottom,
}))`
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    max-width: 150%;
    max-height: calc(100vh - 320px - ${({ $top, $bottom }) => $top + $bottom}px);
`

const LockedImg = styled.img`
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0;
    margin: 0.5em;
`

const SpotsWrapper = styled.div`
    width: 100%;
    text-align: center;
    position: relative;
`

const Spot = styled.div`
    display: inline-block;
    position: relative;
`

const SelectedSpot = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 15px;
    border: solid 2px #FFFFFF;
    margin: 0.25em;
`

const LockedSpot = styled.img`
    width: 50px;
    height: 50px;
    position: absolute;
    left: calc(50% - 1px);
    top: calc(50% - 1px);
    transform: translate(-50%, -50%);
    opacity: 0.5;
`

const SelectedCharWrapper = styled.div`
    position: relative;
    cursor: pointer;
    display: inline-block;
`

const SelectedChar = styled(SelectedSpot)`
    background: rgb(238,31,31);
    background: radial-gradient(circle, rgba(238,31,31,1) 0%, rgba(212,101,101,1) 20%, rgba(203,94,192,1) 80%, rgba(157,98,119,1) 100%);
`

const CloseIcon = styled.img`
    position: absolute;
    left: -5px;
    top: -5px;
    padding: 2.5px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: white;
    background: #2f2f2f;
    border: solid 2px #fff;
`

const StyledButton = styled.div`
    position: relative;
`

const CharacterWrapper = styled.div`
    position: relative;
    width: fit-content;
    margin: auto;
`

const Ring = styled.div`
    position: absolute;
    border-radius: 50%;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    width: 300px;
    height: 50px;
    background-image: url(${VortexGif});
    background-size: 100% 100%;
    background-position: center;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(214,55,55,1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(214,55,55,1);
    box-shadow: 0px 0px 20px 0px rgba(214,55,55,1);
`

const CharText = styled.div`
    position: absolute;
    font-size: 20px;
    padding: 0.5em 1em;
    white-space: nowrap;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255,255,255,1);
    -moz-box-shadow: 0px 0px 15px 0px rgba(255,255,255,1);
    box-shadow: 0px 0px 15px 0px rgba(255,255,255,1);
`

const FreeText = styled(CharText)`
    bottom: 20px;
    left: 20px;
    background-color: rgba(47,47,47,0.8);
`

const UnlockText = styled(CharText)`
    cursor: pointer;
    bottom: 20px;
    right: 20px;
    color: #555555;
    background-color: rgb(253,253,11);
`

const UnlockedText = styled(UnlockText)`
    color: #FFFFFF;
    background-color: rgb(150, 44, 145);
`

const HintWrapper = styled.div.attrs(({ $bottom }) => ({
    $bottom,
}))`
    position: fixed;
    transition: bottom 0.5s;
    bottom: calc(-20vh + ${({ $bottom }) => $bottom}px);
    border-radius: 100px;
    background: #2f2f2f;
    border: solid 2px white;
    font-size: 24px;
    padding: 0.5em 1em;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    min-width: 75%;
    text-align: center;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);

    &.show {
        bottom: calc(5px + ${({ $bottom }) => $bottom}px);
    }
`

const CharModal = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
`

const CharsUnlockedText = styled.div`
    position: relative;
    z-index: 30;
    color: white;
    font-size: 32px;
    text-align: center;
    margin: 2em;
`

let hintDebounce

export const CharacterSelectPage = ({ unlockedCharsPage }) => {
    const navigate = useNavigate();
    const { setChars, isLoggedIn, setScene, setSfx, rank, completedLevels } = useAppContext();
    useEffect(() => {
        if (isLoggedIn === false) navigate('/login')
    }, [isLoggedIn])

    const { safearea } = useSafearea();
    const [characters, setCharacters] = useState([]);
    const [availableThisWeek, setAvailableThisWeek] = useState({});
    const [selected, setSelected] = useState(-1);
    const [spots, setSpots] = useState([])
    const [unlockedChars, setUnlockedChars] = useState({});
    const [hint, setHint] = useState(undefined);
    const [justUnlocked, setJustUnlocked] = useState(false);

    const freeCharacters = []

    useEffect(() => {
        if (characters.legnth > 0) return;
        const newCharacters = Object.keys(intl.get('CARDS.reaper')).map((k, i) => {
            return {
                idx: i,
                key: k,
                art: require(`assets/characters/${k}.png`),
                head: require(`assets/characters/headshot/${k}.png`),
            }
        })
        const rng = new Prando(new Date().toISOString().split('T')[0]);
        while (Object.keys(availableThisWeek).length < 2) {
            const c = rng.nextArrayItem(newCharacters);
            if (freeCharacters.indexOf(c.key) !== -1) continue
            availableThisWeek[c.idx] = true;
        }
        setAvailableThisWeek(availableThisWeek);
        setSelected(parseInt(Object.keys(availableThisWeek)[0]))
        setCharacters(newCharacters)
        setScene(undefined)

        if (rank?.currRank >= 3) {
            SecureStoragePlugin.get({ key: 'secondRatingPrompted' }).then(() => { }).catch(err => {
                SecureStoragePlugin.set({ key: 'secondRatingPrompted', value: 'true' })
                RateApp.requestReview()
            })
        }
    }, [])

    useEffect(() => {
        characters.map((ch) => {
            unlockedChars[ch.key] = freeCharacters.indexOf(ch.key) !== -1 ? true : IsProductOwned({ key: ch.key })
        })
        setUnlockedChars({ ...unlockedChars })
        ProductsUnlocked.get().then(products => {
            Object.keys(products).map(key => {
                unlockedChars[key] = true
            })
            setUnlockedChars({ ...unlockedChars })
        }).catch(err => { })
    }, [characters])

    useEffect(() => {
        if (!hint) return
        if (hintDebounce) clearTimeout(hintDebounce)
        hintDebounce = setTimeout(() => {
            setHint('')
        }, 3000)
    }, [hint])

    return (
        <View>
            <SettingsButton />
            <DeadNightSky />
            <div className="bg-smoke" />
            <Moon>
                <Eclipse />
            </Moon>
            <Mountain />
            <div className="fg-smoke" />
            <HintWrapper id="hint" className={hint ? 'show' : ""} $bottom={safearea.bottom} onClick={() => {
                setHint('')
            }}>{hint || ""}</HintWrapper>
            <CharacterWrapper>
                <Ring />
                <CharacterArt id="char" className="slide-left" src={characters[selected]?.art} $top={safearea.top} $bottom={safearea.bottom}></CharacterArt>
                {availableThisWeek[selected] ? <FreeText>{intl.get('FREE_THIS_WEEK')}</FreeText> : ''}
                {unlockedChars[characters[selected]?.key] ? <UnlockedText>
                    {intl.get('ACTIONS.unlocked')}
                </UnlockedText> : <UnlockText onClick={() => {
                    setSfx(SelectMP3)
                    PurchaseProduct({
                        key: characters[selected]?.key, unlockedChars, setUnlockedChars, onUnlock: (key) => {
                            setJustUnlocked({ type: 'reaper', key })
                            setHint(intl.get('YOU_UNLOCKED', {
                                characters: intl.get('CARDS.reaper')[key].name
                            }))
                        }
                    })
                }}>{intl.get('ACTIONS.unlock')}</UnlockText>}
            </CharacterWrapper>
            <SelectWrapper selected={selected}>
                {
                    characters.map((ch, i) => {
                        return <HeadShotWrapper key={i}>
                            <Headshot
                                src={ch?.head}
                                selected={i === selected} disabled={!availableThisWeek[i] && !unlockedChars[characters[i]?.key]}
                                onClick={(e) => {
                                    setSfx(SelectMP3)
                                    const ele = document.getElementById('char');
                                    ele.style.animationName = "none"
                                    setTimeout(() => {
                                        ele.style.animationName = ""
                                    }, 0);
                                    setSelected(i);
                                    if ((!completedLevels[5] && spots.length === 0) || (rank.currRank < 5 && spots.length === 1) || (rank.currRank < 10 && spots.length === 2)) {
                                        return
                                    }
                                    if ((availableThisWeek[i] || unlockedChars[characters[i]?.key]) && spots.length < 3) {
                                        for (let j = 0; j < spots.length; j++) {
                                            if (spots[j].key === ch.key) return
                                        }
                                        spots.push({ ...ch })
                                        setSpots([...spots])
                                    }
                                }}
                            >
                            </Headshot>
                            {!availableThisWeek[i] && !unlockedChars[characters[i]?.key] ? <LockedImg src={LockedIcon} /> : ""}
                        </HeadShotWrapper>
                    })
                }
            </SelectWrapper>
            {unlockedCharsPage ? <CharsUnlockedText>
                {intl.get('CHARACTERS_GALLERY')}
            </CharsUnlockedText> : <>
                <SpotsWrapper>
                    {spots.map((ch, i) => {
                        return <SelectedCharWrapper key={i}
                            onClick={(e) => {
                                spots.splice(i, 1)
                                setSpots([...spots])
                                setSfx(ButtonMP3)
                            }}>
                            <SelectedChar
                                src={ch?.head}
                                $filled
                            />
                            <CloseIcon src={CloseSvg} />
                        </SelectedCharWrapper>
                    })}
                    {[...Array(3 - spots.length).keys()].map((_, i) => {
                        return <Spot key={i} onClick={() => {
                            if (!completedLevels[5] && spots.length === 0) {
                                setHint(intl.get('UNLOCK_AT_STAGE', { level: 5 }))
                                setSfx(ErrorMP3)
                            } else if (rank.currRank < 5 && spots.length + i === 1) {
                                setHint(intl.get('UNLOCK_AT_RANK', { rank: 5 }))
                                setSfx(ErrorMP3)
                            } else if (rank.currRank < 10 && spots.length + i === 2) {
                                setHint(intl.get('UNLOCK_AT_RANK', { rank: 10 }))
                                setSfx(ErrorMP3)
                            }
                        }}>
                            {((!completedLevels[5] && spots.length === 0) || (rank.currRank < 5 && spots.length + i === 1) || (rank.currRank < 10 && spots.length + i === 2)) ? <LockedSpot src={LockedIcon} /> : ''}
                            <SelectedSpot />
                        </Spot>
                    })}
                </SpotsWrapper>
                <StyledButton id="btn-fight" className="button" disabled={spots.length === 0} onClick={(e) => {
                    if (spots.length === 0) {
                        setHint(intl.get('SELECT_1_CHAR'))
                        setSfx(ErrorMP3)
                        const ele = $('#btn-fight')
                        ele.addClass('failed')
                        ele[0].style.animationName = "none"
                        setTimeout(() => {
                            ele[0].style.animationName = ""
                        }, 0);
                        return
                    }
                    setSfx(ButtonMP3)
                    setChars([...spots.map(ch => ch.key)])
                    navigate(`/battle`)
                }}>{intl.get('FIGHT')}</StyledButton>
            </>}
            {justUnlocked ? <Modal>
                <CharModal>
                    <Card className="unlock" size="big" card={justUnlocked} />
                    <Button onClick={() => {
                        setJustUnlocked(false)
                    }}>{intl.get('ACTIONS.continue')}</Button>
                </CharModal>
            </Modal> : ''}
        </View>
    );
};