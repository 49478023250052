import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

export const CardsDiscovered = {
    get: async () => {
        return new Promise((resolve, reject) => {
            SecureStoragePlugin.get({ key: `cards-discovered` }).then((result) => {
                try {
                    resolve(JSON.parse(result?.value))
                } catch (err) {
                    resolve(result.value)
                }
            }).catch(err => { resolve({}) })
        })
    },
    set: async (keys, setDiscoveredCards) => {
        let discovered = {}
        return new Promise((resolve, reject) => {
            SecureStoragePlugin.get({ key: `cards-discovered` }).then((result) => {
                try {
                    discovered = JSON.parse(result?.value)
                } catch (err) { }
            }).catch(err => { }).finally(() => {
                keys.map((k) => {
                    discovered[k] = true
                })
                if (setDiscoveredCards) setDiscoveredCards({ ...discovered })
                SecureStoragePlugin.set({ key: `cards-discovered`, value: JSON.stringify(discovered) }).then((v) => {
                    resolve()
                }).catch(err => { })
            })
        })
    },
}

export const ProductsUnlocked = {
    get: async () => {
        return new Promise((resolve, reject) => {
            SecureStoragePlugin.get({ key: `products-unlocked` }).then((result) => {
                try {
                    resolve(JSON.parse(result.value))
                } catch (err) {
                    resolve(result.value)
                }
            }).catch(err => { })
        })
    },
    unlockProducts: async (productIds) => {
        let products = {}
        return new Promise((resolve, reject) => {
            SecureStoragePlugin.get({ key: `products-unlocked` }).then((result) => {
                try { products = JSON.parse(result.value) } catch (err) { }
            }).catch(err => { }).finally(() => {
                productIds.map((p) => {
                    products[p.key] = true
                })
                SecureStoragePlugin.set({ key: `products-unlocked`, value: JSON.stringify(products) }).then((v) => {
                    resolve()
                }).catch(err => { })
            })
        })
    }
}

export const LevelsProgress = {
    get: async () => {
        return new Promise((resolve, reject) => {
            SecureStoragePlugin.get({ key: `completed-levels` }).then((result) => {
                try {
                    resolve(JSON.parse(result.value))
                } catch (err) {
                    resolve(result.value)
                }
            }).catch(err => { })
        })
    },
    completeLevel: async (level) => {
        let completed = {}
        return new Promise((resolve, reject) => {
            SecureStoragePlugin.get({ key: `completed-levels` }).then((result) => {
                try { completed = JSON.parse(result.value) } catch (err) { }
            }).catch(err => { }).finally(() => {
                completed[level] = true
                SecureStoragePlugin.set({ key: `completed-levels`, value: JSON.stringify(completed) }).then((v) => {
                    resolve()
                }).catch(err => { })
            })
        })
    }
}

// DEBUG
// SecureStoragePlugin.clear()
// SecureStoragePlugin.remove({ key: `rank-exp` })
// SecureStoragePlugin.set({
//     key: `completed-levels`, value: JSON.stringify({
//         0: true,
//         1: true,
//         2: true,
//         3: true,
//         4: true,
//         5: true,
//     })
// })
// CardsDiscovered.set([
//     'red-orb',
//     'green-orb',
//     'blue-orb',
//     'gold-orb',
//     'lesser-spirit',
//     'ordinary-spirit',
//     'greater-spirit',
//     'half-alive-spirit',
// ])

export const RankApi = {
    gainExp: async (condition) => {
        let exp = 100
        return new Promise((resolve, reject) => {
            SecureStoragePlugin.get({ key: `rank-exp` }).then((result) => {
                exp = parseInt(result.value)
            }).catch(err => { }).finally(() => {
                switch (condition) {
                    case 'victory':
                        exp += exp * 0.05
                        break
                    case 'tie':
                        exp += exp * 0.04
                        break
                    case 'defeat':
                        exp += exp * 0.03
                        break
                }
                resolve(exp)
                SecureStoragePlugin.set({ key: `rank-exp`, value: parseInt(exp).toString() })
            })
        })
    },
    getExp: async () => {
        let exp = 0
        return new Promise((resolve, reject) => {
            SecureStoragePlugin.get({ key: `rank-exp` }).then((result) => {
                exp = parseInt(result.value)
            }).catch((err) => { }).finally(() => {
                resolve(exp)
            })
        })
    },
    calcRank: (exp) => {
        let v = 100, i = 0, currExp = parseInt(exp)
        const lvls = []
        do {
            lvls.push(parseInt(v))
            v += v * 0.034
            i++
        } while (parseInt(v) <= currExp)
        lvls.push(parseInt(v))
        while (i % 3 !== 0) {
            lvls.push(parseInt(v))
            v += v * 0.034
            i++
        }
        const currRankExp = parseInt(lvls[lvls.length - 4])
        const nextRankExp = parseInt(lvls[lvls.length - 1])
        const currRank = Math.floor(lvls.length / 3)
        const progress = (currExp - currRankExp) / (nextRankExp - currRankExp)
        if (currRank === 50) return {
            currRank: 50,
            currExp,
            progress: 1
        }
        return {
            currRank,
            currExp,
            currRankExp,
            nextRankExp,
            progress,
        }
    },
}