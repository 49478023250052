import styled from "styled-components"
import intl from "react-intl-universal"
import { useSafearea } from "./page"

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    overflow: hidden;
    transition: opacity 0.4s;
`

const Content = styled.div.attrs(({ $top, $bottom }) => ({
    $top,
    $bottom,
}))`
    top: ${({ $top }) => $top}px;
    position: absolute;
    width: 100%;
    max-width: 600px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100vh - ${({ $top, $bottom }) => $top + $bottom}px);

    & > div {
        max-height: calc(100vh - ${({ $top, $bottom }) => $top + $bottom}px);
        overflow: auto;
    }
`

export const ModalBottom = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`

export const Modal = ({ children }) => {
    const { safearea } = useSafearea();
    return <Wrapper className="cmp-modal">
        <Content $top={safearea.top} $bottom={safearea.bottom}>
            {children}
        </Content>
    </Wrapper>
}

const PlayTurnWrapper = styled(Wrapper)`
    z-index: 10;
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
`

const PlayerTurnText = styled.div`
    position: absolute;
    font-size: 36px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
`


export const PlayerTurnModal = ({ show, user }) => {
    return <PlayTurnWrapper className={show ? "" : "hide"}>
        <PlayerTurnText className="button">
            {intl.get('PLAYER_TURN', { user: user })}
        </PlayerTurnText>
    </PlayTurnWrapper>
}