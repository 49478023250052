import intl from 'react-intl-universal';
import { spiritToSPCount, validateMaxSpiritsRule } from 'lib/field';
import $ from 'jquery';
import { randomElement, shuffle } from './utils';

import { Button, Buttons } from "components/button";
import { Card, Cards, CardBack } from "components/cards";
import { Modal, ModalBottom } from "components/modal";
import { Label } from "components/common";
import { computeCardTarget } from './computer';
import { applyArtifact } from './artifact';

export const applySkillInterrupt = (params) => {
    const { casterUsername, casterReaperIdx, cardIdx, targetUsername, targetReaperIdx, targetCardIdx, reapers, setTurnState, setPlayedCard, setModalContent, cancelTrapCard, graveyard, setGraveyard, hands, setGallery, setHint, clearReaperState, card } = params;
    const reaper = reapers[targetUsername][targetReaperIdx]
    switch (reaper.key) {
        case 'note-eater':
            if (card.key !== 'green-orb' && card.key !== 'gold-orb') return
            if (reaper.computer) {
                if (Math.random() < 0.25) {
                    setGallery([])
                    setTurnState('discard')
                    setPlayedCard(undefined)
                    setModalContent(undefined)
                    cancelTrapCard()
                    clearReaperState()
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1), ...hands[targetUsername].splice(targetCardIdx, 1),);
                    setGraveyard([...graveyard])
                    setHint(intl.get('HINT.play_interrupted', { user: casterUsername }))
                    return true
                }
            }
            break
    }
}

export const applySkill = (params) => {
    const {
        casterUsername, casterReaperIdx, targetUsername, targetReaperIdx, cardIdx, skillIdx, reaperKey,
        hands, setHands, reapers, setReapers, turnState, setTurnState, cardParams, setCardParams, card,
        gallery, setGallery, galleryIdx, setGalleryIdx, setEffects,
        deck, setDeck, graveyard, setGraveyard, skillState, selectedEle,
        setDragTargets, setModalContent, nonSkillSideEffect, drawnCards,
        setHint, dryRun, clearReaperState, setDragAndDropReminder, selectedCards,
        applyCharmingEffectsToCaster, interruptSideEffect, cancelTrapCard, setPlayedCard
    } = params;
    const reaper = reapers[casterUsername][casterReaperIdx]
    const effects = gatherEffects({
        casterUsername, casterReaperIdx,
        targetUsername, targetReaperIdx,
        casterField: reaper?.field,
        targetField: targetUsername && targetReaperIdx ? reapers[targetUsername][targetReaperIdx]?.field : [],
        card, nonSkillSideEffect
    }) || []
    for (let i = 0; i < effects.length; i++) {
        if (effects[i].skipPlayOrDraw) {
            if (dryRun) return true
            consumeFieldCard({ effect: effects[i], graveyard, setGraveyard, reapers, setReapers })
            graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
            setHands({ ...hands });
            setGallery([])
            setTurnState('discard')
            setCardParams({})
            setGraveyard(graveyard)
            return
        }
    }
    setEffects([...effects])
    const t = intl.get('CARDS.reaper')[reaperKey || reaper.key]

    const skillFn = ({
        'pink-void': () => {
            switch (skillIdx) {
                case 0:
                    if (dryRun) {
                        if (reaper.computer) {
                            for (let cIdx = 0; cIdx < hands[casterUsername].length; cIdx++) {
                                const card = hands[casterUsername][cIdx]
                                if (card.type !== 'artifact' || card.key === 'mercenarys-money') continue
                                params.originalCard = { ...card, newCardIdx: cIdx }
                                params.dryRun = true
                                setPlayedCard({ ...card, show: true })
                                setHint(intl.get('HINT.substitute', {
                                    user: casterUsername,
                                    reaper: intl.get('CARDS.reaper.pink-void').name,
                                    card: intl.get('CARDS.reaper.pink-void.skills')[0].name,
                                    substitute: intl.get('CARDS.artifact')[card.key].name,
                                }))
                                params.overridePlayedCard = true
                                const [targetUsername, targetReaperIdx] = computeCardTarget({ ...params, card: card })
                                if (targetUsername && targetReaperIdx !== undefined) {
                                    return true
                                }
                            }
                            return
                        }
                        return true
                    }
                    if (reaper.computer) {
                        const card = hands[casterUsername][params.originalCard.newCardIdx]
                        const [targetUsername, targetReaperIdx] = computeCardTarget({ ...params, card: params.originalCard })
                        hands[casterUsername][cardIdx] = { ...card, cardIdx }
                        applyArtifact({
                            ...params, hands, targetUsername, targetReaperIdx,
                            originalCard: hands[casterUsername][cardIdx], dryRun: false
                        })
                        return
                    }
                    setGallery([...hands[casterUsername]])
                    setGalleryIdx(0)
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Cards>
                                    {hands[casterUsername].map((c, i) => {
                                        c.username = casterUsername
                                        c.reaperIdx = casterReaperIdx
                                        c.reaper = reapers[casterUsername][casterReaperIdx].key
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} className={c.type === 'artifact' && card.key !== 'mercenarys-money' ? 'glow' : ''} onClick={(e) => {
                                            setGalleryIdx(i)
                                            if (c.type !== 'artifact' || card.key === 'mercenarys-money') return
                                            $('.cmp-card.selected').removeClass('selected')
                                            $(e.target).closest('.cmp-card').addClass('selected')
                                            let originalTarget, oldHand
                                            setCardParams({
                                                ...params, onDragCardEnd: (target) => {
                                                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                                                    setHands({ ...hands })
                                                    setGraveyard([...graveyard])
                                                    setGallery([])
                                                    setModalContent(undefined)
                                                    setTurnState('force-discard')
                                                    originalTarget = target
                                                }, afterState: () => {
                                                    const [toUsername, toReaperIdx] = originalTarget.attr('id').split('_')
                                                    oldHand = [...gallery]
                                                    applyArtifact({
                                                        ...params, hands, selectedEle: originalTarget,
                                                        targetUsername: toUsername, targetReaperIdx: toReaperIdx,
                                                        originalCard: { ...c }, dryRun: false
                                                    })
                                                    hands[casterUsername] = oldHand
                                                    hands[casterUsername] = hands[casterUsername].map((newC, i) => {
                                                        newC.cardIdx = i
                                                        return newC
                                                    })
                                                    setHands({ ...hands })
                                                }
                                            })
                                        }} />
                                    })}
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 1:
                    if (dryRun) return true
                    hands[casterUsername].splice(cardIdx, 1)
                    const newCard = { ...card }
                    newCard.description = `${t.name} - ${t.skills[skillIdx].name}: ${t.skills[skillIdx].desc}`
                    newCard.original = { key: reaperKey || reaper.key, skillIdx }
                    newCard.damageReduced = 99
                    newCard.ignoreAllEffects = true
                    if (applyCharmingEffectsToCaster)
                        reapers[casterUsername][casterReaperIdx].field.push(newCard)
                    else
                        reapers[targetUsername][targetReaperIdx].field.push(newCard)
                    setReapers({ ...reapers })
                    setHands({ ...hands })
                    setCardParams({})
                    setTurnState('discard')
                    break
                case 2:
                    if (reaper.computer) {
                        for (let i = 0; i < Object.keys(reapers).length; i++) {
                            const username = Object.keys(reapers)[i]
                            for (let rIdx = 0; rIdx < reapers[username].length; rIdx++) {
                                for (let cIdx = 0; cIdx < reapers[username][rIdx].field.length; cIdx++) {
                                    const card = reapers[username][rIdx].field[cIdx]
                                    if (card.type !== 'artifact') continue
                                    if (card.sub_type === 'curse' && username === casterUsername) {
                                        if (dryRun) return true
                                        graveyard.push(...reapers[username][rIdx].field.splice(cIdx, 1))
                                        setGraveyard([...graveyard])
                                        setReapers({ ...reapers })
                                        setCardParams({})
                                        setTurnState('discard')
                                        return
                                    } else if (card.sub_type === 'charm' && username !== casterUsername) {
                                        if (dryRun) return true
                                        graveyard.push(...reapers[username][rIdx].field.splice(cIdx, 1))
                                        setGraveyard([...graveyard])
                                        setReapers({ ...reapers })
                                        setCardParams({})
                                        setTurnState('discard')
                                        return
                                    }
                                }
                            }
                        }
                        return
                    }
                    if (dryRun) return true
                    const reaperCard = {
                        type: "reaper",
                        key: reapers[targetUsername][targetReaperIdx].key
                    }
                    setDragAndDropReminder(false)
                    setGallery([reaperCard, ...reapers[targetUsername][targetReaperIdx].field])
                    setGalleryIdx(0)
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Cards>
                                    <Card key={-1} i={-1} card={reaperCard} onClick={() => {
                                        setGalleryIdx(0)
                                    }}></Card>
                                    {reapers[targetUsername][targetReaperIdx].field.map((c, i) => {
                                        c.username = targetUsername
                                        c.reaperIdx = targetReaperIdx
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} className={c.type === 'artifact' ? 'glow' : ''} onClick={(e) => {
                                            setGalleryIdx(1 + i)
                                            if (c.type !== 'artifact') return
                                            $('.cmp-card.selected').removeClass('selected')
                                            $(e.target).closest('.cmp-card').addClass('selected')
                                        }} />
                                    })}
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        if ($('.selected.cmp-card').length !== 1) {
                                            setHint(intl.get('HINT.select_artifact'))
                                            return
                                        }
                                        const meta = JSON.parse($('.selected.cmp-card')[0].dataset.meta)
                                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                                        graveyard.push(...reapers[targetUsername][targetReaperIdx].field.splice(meta.cardIdx, 1))
                                        setGraveyard([...graveyard])
                                        setReapers({ ...reapers })
                                        setHands({ ...hands })
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                        setTurnState('discard')
                                    }}>{intl.get('ACTIONS.remove')}</Button>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
            }
        },
        'combo-dia': () => {
            const reaperCard = {
                type: "reaper",
                key: reapers[casterUsername][casterReaperIdx].key
            }
            hands[casterUsername][cardIdx].cardIdx = cardIdx
            if (dryRun || !reaper.computer) {
                params.selectedCards = [{ ...hands[casterUsername][cardIdx] }]
            }
            switch (skillIdx) {
                case 0:
                    if (dryRun) {
                        hands[casterUsername].map(c => {
                            if (c.cardIdx === cardIdx) return
                            if ((c.key === 'blue-orb' || c.key === 'gold-orb') && Math.random() < 0.5 && params.selectedCards.length === 1) {
                                params.selectedCards.push({ ...c })
                            }
                        })
                        hands[casterUsername].map(c => {
                            if (c.cardIdx === cardIdx) return
                            if ((c.key === 'red-orb' || c.key === 'gold-orb') && Math.random() < 0.5 && params.selectedCards.length === 2) {
                                params.selectedCards.push({ ...c })
                            }
                        })
                        if (params.selectedCards.length > 1) {
                            setPlayedCard({ ...card, show: true })
                            setHint(intl.get('HINT.multi_card', {
                                user: casterUsername,
                                reaper: intl.get('CARDS.reaper.combo-dia').name,
                                cards: {
                                    1: intl.get('CARDS.reaper.combo-dia.skills')[0].name,
                                    2: `${intl.get('CARDS.reaper.combo-dia.skills')[0].name}, ${intl.get('CARDS.reaper.combo-dia.skills')[1].name}`,
                                    3: `${intl.get('CARDS.reaper.combo-dia.skills')[0].name}, ${intl.get('CARDS.reaper.combo-dia.skills')[1].name}, ${intl.get('CARDS.reaper.combo-dia.skills')[2].name}`,
                                }[params.selectedCards.length]
                            }))
                            params.overridePlayedCard = true
                        }
                        return true
                    }
                    if (reaper.computer) {
                        reapers[targetUsername][targetReaperIdx].damage += {
                            1: 1,
                            2: 3,
                            3: 3
                        }[params.selectedCards.length];
                        reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                            username: casterUsername,
                            reaperIdx: casterReaperIdx,
                            isSkill: true
                        };
                        hands[casterUsername] = hands[casterUsername].filter(c => {
                            for (let i = 0; i < params.selectedCards.length; i++) {
                                if (params.selectedCards[i].cardIdx === c.cardIdx)
                                    return false
                            }
                            return true
                        })
                        graveyard.push(...params.selectedCards)
                        setReapers({ ...reapers })
                        setGraveyard([...graveyard])
                        setHands({ ...hands });
                        setGallery([])
                        setModalContent(undefined)
                        setTurnState('cause-damage')
                        setCardParams({
                            ...params, afterState: (source) => {
                                if (source !== 'self-damage') return
                                if (params.selectedCards.length === 3) {
                                    setTurnState('force-discard')
                                    setCardParams({
                                        ...params, afterState: (source) => {
                                            if (source !== 'computer-force-discard') return
                                            setTurnState('play-or-draw')
                                            clearReaperState()
                                            setCardParams({})
                                        }
                                    })
                                } else {
                                    setTurnState('discard')
                                    clearReaperState()
                                    setCardParams({})
                                }
                            }
                        })
                        return
                    }
                    setGallery([reaperCard, ...hands[casterUsername]])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Cards>
                                    <Card key={-1} i={-1} card={reaperCard} onClick={() => {
                                        setGalleryIdx(0)
                                    }}></Card>
                                    {hands[casterUsername].map((c, i) => {
                                        c.username = casterUsername
                                        c.reaperIdx = casterReaperIdx
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} cardNumber={i === cardIdx ? 1 : ''} className={c.type === 'orb' ? 'glow' : ''} onClick={(e) => {
                                            setGalleryIdx(1 + i)
                                            if (c.type !== 'orb') return
                                            const cardEle = $(e.target).closest('.cmp-card')
                                            switch (params.selectedCards.length) {
                                                case 1:
                                                    if (c.key === 'blue-orb' || c.key === 'gold-orb') {
                                                        params.selectedCards.push({ ...c })
                                                        $(cardEle).find('.card-number').html(`<div>${params.selectedCards.length}</div>`)
                                                        $(cardEle).addClass('selected')
                                                    }
                                                    break
                                                case 2:
                                                    if ((c.key === 'blue-orb' || c.key === 'gold-orb') && cardEle.hasClass('selected')) {
                                                        $(cardEle).find('.card-number').html('')
                                                        params.selectedCards.pop()
                                                        $(cardEle).removeClass('selected')
                                                    } else if ((c.key === 'red-orb' || c.key === 'gold-orb') && !cardEle.hasClass('selected')) {
                                                        params.selectedCards.push({ ...c })
                                                        $(cardEle).find('.card-number').html(`<div>${params.selectedCards.length}</div>`)
                                                        $(cardEle).addClass('selected')
                                                    }
                                                    break
                                                case 3:
                                                    if ((c.key === 'red-orb' || c.key === 'gold-orb') && cardEle.hasClass('selected')) {
                                                        $(cardEle).find('.card-number').html('')
                                                        params.selectedCards.pop()
                                                        $(cardEle).removeClass('selected')
                                                    }
                                                    break
                                            }
                                        }} />
                                    })}
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        reapers[targetUsername][targetReaperIdx].damage += {
                                            1: 1,
                                            2: 3,
                                            3: 3
                                        }[params.selectedCards.length];
                                        reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                                            username: casterUsername,
                                            reaperIdx: casterReaperIdx,
                                            isSkill: true
                                        };
                                        hands[casterUsername] = hands[casterUsername].filter(c => {
                                            for (let i = 0; i < params.selectedCards.length; i++) {
                                                if (params.selectedCards[i].cardIdx === c.cardIdx)
                                                    return false
                                            }
                                            return true
                                        })
                                        graveyard.push(...params.selectedCards)
                                        setReapers({ ...reapers })
                                        setGraveyard([...graveyard])
                                        setHands({ ...hands });
                                        setGallery([])
                                        setModalContent(undefined)
                                        if (params.selectedCards.length === 3) {
                                            setTurnState('force-discard')
                                            setCardParams({
                                                ...params, afterState: (source) => {
                                                    if (source !== 'self-force-discard') return
                                                    setTurnState('play-or-draw')
                                                    clearReaperState()
                                                    setCardParams({})
                                                }
                                            })
                                        } else {
                                            setTurnState('cause-damage')
                                        }
                                    }}>{intl.get('ACTIONS.attack')}</Button>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break;
                case 1:
                    if (dryRun) {
                        hands[casterUsername].map(c => {
                            if (c.cardIdx === cardIdx) return
                            if ((c.key === 'red-orb' || c.key === 'gold-orb') && Math.random() < 0.5 && params.selectedCards.length === 2) {
                                params.selectedCards.push({ ...c })
                            }
                        })
                        reapers[targetUsername][targetReaperIdx].damage = {
                            1: 2,
                            2: 3
                        }[params.selectedCards.length];
                        if (params.selectedCards.legnth > 1) {
                            setPlayedCard({ ...card, show: true })
                            setHint(intl.get('HINT.multi_card', {
                                user: casterUsername,
                                reaper: intl.get('CARDS.reaper.combo-dia').name,
                                cards: {
                                    1: intl.get('CARDS.reaper.combo-dia.skills')[1].name,
                                    2: `${intl.get('CARDS.reaper.combo-dia.skills')[1].name}, ${intl.get('CARDS.reaper.combo-dia.skills')[2].name}`,
                                }[params.selectedCards.length]
                            }))
                            params.overridePlayedCard = true
                        }
                        return true
                    }
                    if (reaper.computer) {
                        reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                            username: casterUsername,
                            reaperIdx: casterReaperIdx,
                            isSkill: true
                        };
                        hands[casterUsername] = hands[casterUsername].filter(c => {
                            for (let i = 0; i < params.selectedCards.length; i++) {
                                if (params.selectedCards[i].cardIdx === c.cardIdx)
                                    return false
                            }
                            return true
                        })
                        graveyard.push(...params.selectedCards)
                        setReapers({ ...reapers })
                        setGraveyard([...graveyard])
                        setHands({ ...hands });
                        setGallery([])
                        setModalContent(undefined)
                        setTurnState('cause-damage')
                        setCardParams({
                            ...params, afterState: (source) => {
                                if (source !== 'self-damage') return
                                if (params.selectedCards.length === 2) {
                                    setTurnState('force-discard')
                                    setCardParams({
                                        ...params, afterState: (source) => {
                                            if (source !== 'computer-force-discard') return
                                            setTurnState('play-or-draw')
                                            clearReaperState()
                                            setCardParams({})
                                        }
                                    })
                                } else {
                                    setTurnState('draw')
                                    clearReaperState()
                                    setCardParams({})
                                }
                            }
                        })
                        return
                    }
                    setGallery([reaperCard, ...hands[casterUsername]])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Cards>
                                    <Card key={-1} i={-1} card={reaperCard} onClick={() => {
                                        setGalleryIdx(0)
                                    }}></Card>
                                    {hands[casterUsername].map((c, i) => {
                                        c.username = casterUsername
                                        c.reaperIdx = casterReaperIdx
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} cardNumber={i === cardIdx ? 1 : ''} className={c.type === 'orb' ? 'glow' : ''} onClick={(e) => {
                                            setGalleryIdx(1 + i)
                                            if (c.type !== 'orb') return
                                            const cardEle = $(e.target).closest('.cmp-card')
                                            switch (params.selectedCards.length) {
                                                case 1:
                                                    if ((c.key === 'blue-orb' || c.key === 'gold-orb') && cardEle.hasClass('selected')) {
                                                        $(cardEle).find('.card-number').html('')
                                                        params.selectedCards.pop()
                                                        $(cardEle).removeClass('selected')
                                                    } else if ((c.key === 'red-orb' || c.key === 'gold-orb') && !cardEle.hasClass('selected')) {
                                                        params.selectedCards.push({ ...c })
                                                        $(cardEle).find('.card-number').html(`<div>${params.selectedCards.length}</div>`)
                                                        $(cardEle).addClass('selected')
                                                    }
                                                    break
                                                case 2:
                                                    if ((c.key === 'red-orb' || c.key === 'gold-orb') && cardEle.hasClass('selected')) {
                                                        $(cardEle).find('.card-number').html('')
                                                        params.selectedCards.pop()
                                                        $(cardEle).removeClass('selected')
                                                    }
                                                    break
                                            }
                                        }} />
                                    })}
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        reapers[targetUsername][targetReaperIdx].damage += {
                                            1: 2,
                                            2: 3,
                                        }[params.selectedCards.length];
                                        reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                                            username: casterUsername,
                                            reaperIdx: casterReaperIdx,
                                            isSkill: true
                                        };
                                        hands[casterUsername] = hands[casterUsername].filter(c => {
                                            for (let i = 0; i < params.selectedCards.length; i++) {
                                                if (params.selectedCards[i].cardIdx === c.cardIdx)
                                                    return false
                                            }
                                            return true
                                        })
                                        graveyard.push(...params.selectedCards)
                                        setReapers({ ...reapers })
                                        setGraveyard([...graveyard])
                                        setHands({ ...hands });
                                        setGallery([])
                                        setModalContent(undefined)
                                        if (params.selectedCards.length === 2) {
                                            setTurnState('force-discard')
                                            setCardParams({
                                                ...params, afterState: (source) => {
                                                    if (source !== 'self-force-discard') return
                                                    setTurnState('play-or-draw')
                                                    clearReaperState()
                                                    setCardParams({})
                                                }
                                            })
                                        } else {
                                            setTurnState('cause-damage')
                                        }
                                    }}>{intl.get('ACTIONS.attack')}</Button>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break;
                case 2:
                    if (reaper.computer) return
                    if (dryRun) return true
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                    setGraveyard([...graveyard])
                    setHands({ ...hands });
                    setCardParams({
                        ...params, afterState: () => {
                            clearReaperState()
                            setCardParams({})
                            setTurnState('draw')
                        }
                    })
                    setGallery([])
                    setTurnState('force-discard')
                    break;
            }
        },
        'swallia': () => {
            switch (skillIdx) {
                case 0:
                    if (dryRun) return true
                    const reaperCard = {
                        type: "reaper",
                        key: reapers[targetUsername][targetReaperIdx].key
                    }
                    if (reaper.computer) {
                        hands[targetUsername].push(...reapers[targetUsername][targetReaperIdx].field.splice(Math.floor(Math.random() * reapers[targetUsername][targetReaperIdx].field.length), 1))
                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                        setGraveyard([...graveyard])
                        setReapers({ ...reapers })
                        setHands({ ...hands })
                        setCardParams({})
                        setTurnState('discard')
                        setHint(intl.get('HINT.card_returned', { user: targetUsername }))
                        return
                    }
                    setDragAndDropReminder(false)
                    setGalleryIdx(0)
                    setGallery([reaperCard, ...reapers[targetUsername][targetReaperIdx].field])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Cards>
                                    <Card key={-1} i={-1} card={reaperCard} onClick={() => {
                                        setGalleryIdx(0)
                                    }}></Card>
                                    {reapers[targetUsername][targetReaperIdx].field.map((c, i) => {
                                        c.username = targetUsername
                                        c.reaperIdx = targetReaperIdx
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} className={c.type === 'spirit' ? 'glow' : ''} onClick={(e) => {
                                            setGalleryIdx(1 + i)
                                            if (c.type !== 'spirit') return
                                            $('.cmp-card.selected').removeClass('selected')
                                            $(e.target).closest('.cmp-card').addClass('selected')
                                        }} />
                                    })}
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        if ($('.selected.cmp-card').length !== 1) {
                                            setHint(intl.get('HINT.select_option'))
                                            return
                                        }
                                        const meta = JSON.parse($('.selected.cmp-card')[0].dataset.meta)
                                        hands[targetUsername].push(...reapers[targetUsername][targetReaperIdx].field.splice(meta.cardIdx, 1))
                                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                                        setGraveyard([...graveyard])
                                        setReapers({ ...reapers })
                                        setHands({ ...hands })
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                        setTurnState('discard')
                                    }}>{intl.get('ACTIONS.return')}</Button>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 1:
                    if (dryRun) return true
                    reapers[targetUsername][targetReaperIdx].damage += 1;
                    reapers[targetUsername][targetReaperIdx].discard += 1;
                    reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                        username: casterUsername,
                        reaperIdx: casterReaperIdx,
                        isSkill: true
                    };
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                    setTurnState('cause-damage')
                    setReapers({ ...reapers })
                    setGraveyard([...graveyard])
                    setHands({ ...hands });
                    setCardParams({})
                    setGallery([])
                    break;
                case 2:
                    if (dryRun) return true
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                    setGraveyard([...graveyard])
                    card.description = `${t.name} - ${t.skills[skillIdx].name}: ${t.skills[skillIdx].desc}`
                    card.original = { key: reaperKey || reaper.key, skillIdx }
                    card.skipPlayOrDraw = true
                    reapers[targetUsername][targetReaperIdx].field.push({ ...card })
                    setHands({ ...hands });
                    setReapers({ ...reapers })
                    setTurnState('discard');
                    setCardParams({})
                    break
            }
        },
        'note-eater': () => {
            switch (skillIdx) {
                case 0:
                    if (!interruptSideEffect) {
                        setHint(intl.get('HINT.interrupt_only'))
                        return
                    }
                    if (dryRun) return true
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                    setGraveyard([...graveyard])
                    break
                case 1:
                    if (dryRun) {
                        return Object.keys(hands).filter(username => {
                            if (username === casterUsername) return false
                            return hands[username].length > 0
                        }).length > 0
                    }
                    if (reaper.computer) {
                        hands[targetUsername].map((c, i) => {
                            c.idx = i
                        })
                        const mustPlayCardIdx = Math.floor(Math.random() * hands[targetUsername].length)
                        hands[targetUsername][mustPlayCardIdx].mustPlay = true
                        const c = hands[targetUsername][mustPlayCardIdx]
                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                        setGalleryIdx(mustPlayCardIdx)
                        setGraveyard([...graveyard])
                        setReapers({ ...reapers })
                        setHands({ ...hands })
                        setCardParams({})
                        setTurnState('discard')
                        setHint(intl.get('HINT.must_play', { card: intl.get('CARDS')[c.type][c.key].name, user: targetUsername }))
                        return
                    }
                    const [username, _] = selectedEle[0].id.split('_')
                    setDragAndDropReminder(false)
                    setGalleryIdx(0)
                    setGallery([])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Label $emphasize={casterUsername === username}>{username}</Label>
                                <Cards>
                                    {
                                        hands[username].map((c, i) => {
                                            c.cardIdx = i
                                            return <CardBack className="glow" $size="small" key={i} i={i} card={c} onClick={(e) => {
                                                $('.cmp-card-back').removeClass('selected')
                                                $(e.target).closest('.cmp-card-back').toggleClass('selected')
                                            }}></CardBack>
                                        })
                                    }
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        if ($('.selected.cmp-card-back').length !== 1) {
                                            setHint(intl.get('HINT.select_option'))
                                            return
                                        }
                                        const meta = JSON.parse($('.selected.cmp-card-back')[0].dataset.meta)
                                        hands[targetUsername][meta.cardIdx].mustPlay = true
                                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                                        setGraveyard([...graveyard])
                                        setHands({ ...hands })
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                        setTurnState('discard')
                                    }}>{intl.get('ACTIONS.choose')}</Button>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 2:
                    if (dryRun) return true
                    reapers[targetUsername][targetReaperIdx].damage += 2;
                    reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                        username: casterUsername,
                        reaperIdx: casterReaperIdx,
                        isSkill: true
                    };
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                    setGraveyard([...graveyard])
                    setHands({ ...hands });
                    setReapers({ ...reapers })
                    setTurnState('cause-damage')
                    setGallery([])
                    setCardParams({
                        ...params, afterState: () => {
                            setTurnState('discard')
                            setCardParams({})
                        }
                    })
                    break
            }
        },
        'hermona': () => {
            switch (skillIdx) {
                case 0:
                    if (dryRun) return true
                    reapers[targetUsername][targetReaperIdx].damage += 2;
                    reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                        username: casterUsername,
                        reaperIdx: casterReaperIdx,
                        isSkill: true
                    };
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                    setGraveyard([...graveyard])
                    setHands({ ...hands });
                    setReapers({ ...reapers })
                    setTurnState('cause-damage')
                    setGallery([])
                    setCardParams({
                        ...params, afterState: () => {
                            setTurnState('discard')
                            setCardParams({})
                        }
                    })
                    break
                case 1:
                    if (dryRun) {
                        if (reaper.computer) {
                            let lowestStats = 99
                            reaper.field.map(c => {
                                if (c.type !== 'spirit') return
                                const stat = spiritToSPCount(c.key)
                                if (lowestStats > stat.sp + stat.pp)
                                    lowestStats = stat.sp + stat.pp
                            })
                            let shouldSwap
                            Object.keys(reapers).map((username) => {
                                reapers[username].map(r => {
                                    r.field.filter(c => {
                                        if (c.type !== 'spirit') return
                                        const stat = spiritToSPCount(c.key)
                                        if (lowestStats < stat.sp + stat.pp)
                                            shouldSwap = true
                                    })
                                })
                            })
                            return shouldSwap
                        }
                        return true
                    }
                    if (reaper.computer) {
                        let lowestStats = 99, lowestIdx
                        reaper.field.map((c, idx) => {
                            if (c.type !== 'spirit') return
                            const stat = spiritToSPCount(c.key)
                            if (lowestStats > stat.sp + stat.pp) {
                                lowestStats = stat.sp + stat.pp
                                lowestIdx = idx
                            }

                        })
                        let swapped
                        Object.keys(reapers).map((username) => {
                            reapers[username].map((r, reaperIdx) => {
                                r.field.filter((c, idx) => {
                                    if (c.type !== 'spirit' || swapped) return
                                    const stat = spiritToSPCount(c.key)
                                    if (lowestStats < stat.sp + stat.pp) {
                                        swapped = true
                                        const s1 = reapers[casterUsername][casterReaperIdx].field.splice(lowestIdx, 1)
                                        const s2 = reapers[username][reaperIdx].field.splice(idx, 1)
                                        reapers[casterUsername][casterReaperIdx].field.push(...s2)
                                        reapers[username][reaperIdx].field.push(...s1)
                                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                                    }
                                })
                            })
                            setReapers({ ...reapers })
                            setGraveyard([...graveyard])
                            setTurnState('discard')
                        })
                        return
                    }
                    setDragAndDropReminder(false)
                    setGalleryIdx(0)
                    setGallery([])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                {
                                    Object.keys(reapers).map((username, i) => {
                                        return <div key={i}>
                                            <Label $emphasize={casterUsername === username}>{username}</Label>
                                            <Cards className="reapers">
                                                {
                                                    reapers[username].map((r, i) => {
                                                        const reaperCard = { key: r.key, type: 'reaper' };
                                                        return <div key={i}>
                                                            <Card key={-1} card={reaperCard} />
                                                            {
                                                                r.field.map((c, j) => {
                                                                    c.username = username
                                                                    c.cardIdx = j
                                                                    c.reaperIdx = i
                                                                    return <Card key={j} className={c.type === 'spirit' ? 'glow' : ''} card={{ ...c }} i={j} onClick={(e) => {
                                                                        if ($(e.target).closest('.cmp-card').hasClass('selected')) {
                                                                            $(e.target).closest('.cmp-card').toggleClass('selected');
                                                                            return
                                                                        }
                                                                        if ($('.selected.cmp-card').length === 1) {
                                                                            const meta0 = JSON.parse($('.selected.cmp-card')[0].dataset.meta)
                                                                            const meta1 = JSON.parse($(e.target).closest('.cmp-card')[0].dataset.meta)
                                                                            if (meta0.username === meta1.username) return
                                                                        } else if ($('.selected.cmp-card').length === 2) {
                                                                            return
                                                                        }
                                                                        $(e.target).closest('.cmp-card').toggleClass('selected');
                                                                    }} />
                                                                })
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </Cards>
                                        </div>
                                    })
                                }
                                <Buttons>
                                    <Button onClick={() => {
                                        if ($('.selected.cmp-card').length !== 2) {
                                            setHint(intl.get('HINT.swap_2_spirits'))
                                            return
                                        }
                                        const selectedCards = $('.selected.cmp-card')
                                        const meta0 = JSON.parse(selectedCards[0].dataset.meta)
                                        const meta1 = JSON.parse(selectedCards[1].dataset.meta)
                                        reapers[meta0.username][meta0.reaperIdx].field.splice(meta0.cardIdx, 1)
                                        reapers[meta1.username][meta1.reaperIdx].field.splice(meta1.cardIdx, 1)
                                        reapers[meta0.username][meta0.reaperIdx].field.push({ ...meta1 })
                                        reapers[meta1.username][meta1.reaperIdx].field.push({ ...meta0 })
                                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                                        setHands({ ...hands })
                                        setGraveyard([...graveyard])
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                        setTurnState('discard')
                                    }}>{intl.get('ACTIONS.swap')}</Button>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 2:
                    if (dryRun) return true
                    reapers[targetUsername][targetReaperIdx].damage += 1;
                    reapers[targetUsername][targetReaperIdx].discard += 1;
                    reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                        username: casterUsername,
                        reaperIdx: casterReaperIdx,
                        isSkill: true
                    };
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                    setGraveyard([...graveyard])
                    setHands({ ...hands });
                    setReapers({ ...reapers })
                    setTurnState('cause-damage')
                    setGallery([])
                    setCardParams({
                        ...params, afterState: () => {
                            setTurnState('discard')
                            setCardParams({})
                        }
                    })
                    break
            }
        },
        'delta-6': () => {
            switch (skillIdx) {
                case 0:
                    if (dryRun) {
                        if (reapers[casterUsername][casterReaperIdx].computer)
                            return hands[casterUsername].filter(c => c.type === 'spirit').length > 0 && reapers[casterUsername][casterReaperIdx].field.filter(c => c.type === 'spirit').length > 1
                        return true
                    }
                    if (reapers[casterUsername][casterReaperIdx].computer) {
                        for (let i = 0; i < hands[casterUsername].length; i++) {
                            if (hands[casterUsername][i].type === 'spirit') {
                                reapers[targetUsername][targetReaperIdx].damage += spiritToSPCount(hands[casterUsername][i].key).sp;
                                reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                                    username: casterUsername,
                                    reaperIdx: casterReaperIdx,
                                    isSkill: true
                                };
                                graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                                setReapers({ ...reapers })
                                setHands({ ...hands })
                                setGraveyard([...graveyard])
                                setTurnState('cause-damage')
                                setCardParams({
                                    ...params, afterState: () => {
                                        setTurnState('discard')
                                        setCardParams({})
                                    }
                                })
                                break
                            }
                        }
                        for (let i = 0; i < reapers[casterUsername][casterReaperIdx].field.length; i++) {
                            if (reapers[casterUsername][casterReaperIdx].field[i].type === 'spirit') {
                                reapers[targetUsername][targetReaperIdx].damage += spiritToSPCount(reapers[casterUsername][casterReaperIdx].field[i].key).sp;
                                reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                                    username: casterUsername,
                                    reaperIdx: casterReaperIdx,
                                    isSkill: true
                                };
                                graveyard.push(...reapers[casterUsername][casterReaperIdx].field.splice(i, 1))
                                setReapers({ ...reapers })
                                setGraveyard([...graveyard])
                                setTurnState('cause-damage')
                                setCardParams({
                                    ...params, afterState: () => {
                                        setTurnState('discard')
                                        setCardParams({})
                                    }
                                })
                                break
                            }
                        }
                        return
                    }
                    const reaperCard = {
                        type: "reaper",
                        key: reaper.key
                    }
                    setDragAndDropReminder(false)
                    setGalleryIdx(0)
                    setGallery([reaperCard, ...reaper.field, ...hands[casterUsername]])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Cards key={0}>
                                    <Card key={-1} i={-1} card={{
                                        type: "reaper",
                                        key: reaper.key
                                    }} onClick={() => {
                                        setGalleryIdx(0)
                                    }}></Card>
                                    {reaper.field.map((c, i) => {
                                        c.username = casterUsername
                                        c.reaperIdx = casterReaperIdx
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} className={c.type === 'spirit' ? 'glow' : ''} onClick={(e) => {
                                            setGalleryIdx(1 + i)
                                            if (c.type !== 'spirit') return
                                            $('.cmp-card.selected').removeClass('selected')
                                            $(e.target).closest('.cmp-card').addClass('selected')
                                        }} />
                                    })}
                                </Cards>
                                <Cards key={1}>
                                    {hands[casterUsername].map((c, i) => {
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} className={c.type === 'spirit' ? 'glow' : ''} onClick={(e) => {
                                            setGalleryIdx(reaper.field.length + 1 + i)
                                            if (c.type !== 'spirit') return
                                            $('.cmp-card.selected').removeClass('selected')
                                            $(e.target).closest('.cmp-card').addClass('selected')
                                        }} />
                                    })}
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        const eles = $('.selected.cmp-card')
                                        if (eles.length === 0) {
                                            setHint(intl.get('HINT.select_option'))
                                            return
                                        }
                                        const meta = JSON.parse(eles[0].dataset.meta);
                                        reapers[targetUsername][targetReaperIdx].damage += spiritToSPCount(meta.key, meta.username && reaper.field.filter(c => c.type === 'spirit').length === 1).sp;
                                        reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                                            username: casterUsername,
                                            reaperIdx: casterReaperIdx,
                                            isSkill: true
                                        };
                                        hands[casterUsername].splice(cardIdx, 1);
                                        if (meta.username) {
                                            graveyard.push(...reapers[casterUsername][casterReaperIdx].field.splice(meta.cardIdx, 1))
                                        } else {
                                            graveyard.push(...hands[casterUsername].splice(meta.cardIdx > cardIdx ? meta.cardIdx - 1 : meta.cardIdx, 1))
                                        }
                                        setGraveyard([...graveyard])
                                        setHands({ ...hands })
                                        setReapers({ ...reapers })
                                        setGraveyard([...graveyard])
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                        setTurnState('cause-damage')
                                    }}>{intl.get('ACTIONS.sacrifice')}</Button>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 1:
                    const spirits = graveyard.filter(c => c.type === 'spirit')
                    if (dryRun) return spirits.length > 0
                    if (reapers[casterUsername][casterReaperIdx].computer) {
                        for (let i = graveyard.length - 1; i >= 0; i--) {
                            if (graveyard[i].type === 'spirit') {
                                const spirit = graveyard.splice(i, 1)[0]
                                if (reaper.field.filter(c => c.type === 'spirit').length < 3) {
                                    reapers[casterUsername][casterReaperIdx].field.push({ ...spirit })
                                } else {
                                    hands[casterUsername].push({ ...spirit })
                                }
                                break
                            }
                        }
                        setGraveyard([...graveyard])
                        setReapers({ ...reapers })
                        setHands({ ...hands })
                        setCardParams({})
                        setTurnState('discard')
                        return
                    }
                    if (spirits.length === 0) {
                        setHint(intl.get('HINT.no_spirit_in_graveyard'))
                        return
                    }
                    setDragAndDropReminder(false)
                    setGalleryIdx(0)
                    setGallery([])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Cards className="cmp-cards glow field" key={0} onClick={(e) => {
                                    $('.cmp-cards.selected').removeClass('selected')
                                    $(e.target).closest('.cmp-cards').addClass('selected')
                                }}>
                                    <Card key={-1} i={-1} card={{
                                        type: "reaper",
                                        key: reaper.key
                                    }}></Card>
                                    {reaper.field.map((c, i) => {
                                        c.username = casterUsername
                                        c.reaperIdx = casterReaperIdx
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} />
                                    })}
                                </Cards>
                                <Cards className="cmp-cards glow hand" key={1} onClick={(e) => {
                                    $('.cmp-cards.selected').removeClass('selected')
                                    $(e.target).closest('.cmp-cards').addClass('selected')
                                }}>
                                    {hands[casterUsername].map((c, i) => {
                                        c.username = casterUsername
                                        c.reaperIdx = casterReaperIdx
                                        c.cardIdx = i
                                        return <Card key={i} card={c} i={i} />
                                    })}
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        if ($('.selected.cmp-cards').length === 0) {
                                            setHint(intl.get('HINT.select_option'))
                                            return
                                        }
                                        const spirit = spirits[spirits.length - 1]
                                        if ($('.selected.cmp-cards').hasClass('field')) {
                                            if (!validateMaxSpiritsRule(reaper.field, spirit)) {
                                                setHint(intl.get('HINT.spirits_max'))
                                                return
                                            }
                                            reapers[casterUsername][casterReaperIdx].field.push({ ...spirit })
                                            setReapers({ ...reapers })
                                        } else if ($('.selected.cmp-cards').hasClass('hand')) {
                                            hands[casterUsername].push({ ...spirit })
                                        }
                                        for (let i = graveyard.length - 1; i >= 0; i--) {
                                            if (graveyard[i].type === 'spirit') {
                                                graveyard.splice(i, 1)
                                                break
                                            }
                                        }
                                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                                        setHands({ ...hands })
                                        setGraveyard([...graveyard])
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                        setTurnState('discard')
                                    }}>{intl.get('ACTIONS.add_to')}</Button>
                                    <Button onClick={() => {
                                        setGalleryIdx(0)
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 2:
                    if (dryRun) return true
                    reapers[targetUsername][targetReaperIdx].damage += 2;
                    reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                        username: casterUsername,
                        reaperIdx: casterReaperIdx,
                        isSkill: true
                    };
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                    setGraveyard([...graveyard])
                    setHands({ ...hands });
                    setReapers({ ...reapers })
                    setTurnState('cause-damage')
                    setGallery([])
                    setCardParams({
                        ...params, afterState: () => {
                            setTurnState('discard')
                            setCardParams({})
                        }
                    })
                    break
            }
        },
        'bracket-boy': () => {
            switch (skillIdx) {
                case 0:
                    if (dryRun) return true
                    reapers[targetUsername][targetReaperIdx].damage += 1;
                    reapers[targetUsername][targetReaperIdx].ignoreProtection = true;
                    reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                        username: casterUsername,
                        reaperIdx: casterReaperIdx,
                        isSkill: true
                    };
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                    setGraveyard([...graveyard])
                    setHands({ ...hands });
                    setReapers({ ...reapers })
                    setTurnState('cause-damage')
                    setGallery([])
                    setCardParams({
                        ...params, afterState: () => {
                            setTurnState('discard')
                            setCardParams({})
                        }
                    })
                    break
                case 1:
                    if (dryRun) return true
                    switch (skillState) {
                        case 0:
                            if (reaper.computer) {
                                reapers[targetUsername][targetReaperIdx].field.map((c, i) => {
                                    reapers[targetUsername][targetReaperIdx].field[i].cardIdx = i
                                })
                                reapers[targetUsername][targetReaperIdx].damageTargets = [randomElement(reapers[targetUsername][targetReaperIdx].field.filter(c => {
                                    return c.type === 'spirit'
                                }))]
                                reapers[targetUsername][targetReaperIdx].damage += 1;
                                reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                                    username: casterUsername,
                                    reaperIdx: casterReaperIdx,
                                    isSkill: true
                                };
                                graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                                setGraveyard([...graveyard])
                                setHands({ ...hands });
                                setReapers({ ...reapers })
                                setTurnState('cause-damage')
                                setCardParams({
                                    ...params, skillState: 1
                                })
                                return
                            }
                            setDragAndDropReminder(false)
                            setGalleryIdx(0)
                            setModalContent(
                                <Modal>
                                    <ModalBottom>
                                        {Object.keys(reapers).map(username => {
                                            if (username === casterUsername) return
                                            return reapers[username].map((r, j) => {
                                                const reaperCard = {
                                                    type: "reaper",
                                                    key: r.key
                                                }
                                                return <Cards key={j}>
                                                    <Card card={reaperCard} i={-1} onClick={() => {
                                                        setGallery([reaperCard, ...r.field])
                                                        setGalleryIdx(0)
                                                    }} />
                                                    {r.field.map((c, i) => {
                                                        c.username = username
                                                        c.reaperIdx = j
                                                        c.cardIdx = i
                                                        return <Card className={c.type === 'spirit' ? 'glow' : ''} key={i} card={c} i={i} onClick={(e) => {
                                                            setGallery([reaperCard, ...r.field])
                                                            setGalleryIdx(i + 1)
                                                            if (c.type !== 'spirit') return;
                                                            $('.cmp-card').removeClass('selected')
                                                            $(e.target).closest('.cmp-card').toggleClass('selected')
                                                        }} />
                                                    })}
                                                </Cards>
                                            })
                                        })}
                                        <Buttons>
                                            <Button onClick={() => {
                                                if ($('.selected.cmp-card').length === 0) {
                                                    setHint(intl.get('HINT.select_spirit'))
                                                    return
                                                }
                                                setCardParams({ ...params, skillState: 1 })
                                            }}>{intl.get('ACTIONS.attack')}</Button>
                                            <Button onClick={() => {
                                                setGalleryIdx(0)
                                                setGallery([])
                                                setModalContent(undefined)
                                                setCardParams({})
                                            }}>{intl.get('ACTIONS.back')}</Button>
                                        </Buttons>
                                    </ModalBottom>
                                </Modal>
                            )
                            break
                        case 1:
                            if (reaper.computer) {
                                setTurnState('discard')
                                setCardParams({})
                                return
                            }
                            const eles = $('.selected.cmp-card')
                            if (eles.length === 0) return
                            const meta = JSON.parse(eles[0].dataset.meta);
                            reapers[targetUsername][targetReaperIdx].damageTargets = [meta]
                            reapers[targetUsername][targetReaperIdx].damage += 1;
                            reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                                username: casterUsername,
                                reaperIdx: casterReaperIdx,
                                isSkill: true
                            };
                            graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                            setGraveyard([...graveyard])
                            setHands({ ...hands });
                            setReapers({ ...reapers })
                            setTurnState('cause-damage')
                            setGallery([])
                            setCardParams({})
                            setModalContent(undefined)
                            break
                    }
                    break
                case 2:
                    if (dryRun) return true
                    if (reapers[casterUsername][casterReaperIdx].computer) {
                        graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                        const newDrawnCards = shuffle(deck.splice(Math.max(deck.length - 3, 0), 3))
                        hands[casterUsername].push(newDrawnCards[0])
                        setGraveyard([...graveyard])
                        deck.push(...newDrawnCards.splice(1))
                        setHands({ ...hands })
                        setDeck([...deck])
                        setCardParams({})
                        setTurnState('discard')
                        return
                    }
                    switch (skillState) {
                        case 0:
                            const newDrawnCards = deck.splice(Math.max(deck.length - 3, 0), 3);
                            setGallery([...newDrawnCards])
                            setCardParams({ ...params, skillState: 1, drawnCards: newDrawnCards })
                            break
                        case 1:
                            setDragAndDropReminder(false)
                            setModalContent(
                                <Modal>
                                    <ModalBottom>
                                        <Cards className="drawn-cards">
                                            {drawnCards.map((c, i) => {
                                                return <Card key={i} card={c} i={i}
                                                    onClick={(e) => {
                                                        const ele = $(e.target).closest('.cmp-card');
                                                        if (ele.hasClass('selected')) {
                                                            for (let sIdx = 0; sIdx < selectedCards.length; sIdx++) {
                                                                if (selectedCards[sIdx] === i)
                                                                    selectedCards.splice(sIdx, 1)
                                                            }
                                                        } else {
                                                            if (selectedCards?.length === 2) return
                                                            selectedCards.push(i)
                                                        }
                                                        $('.drawn-cards .cmp-card .card-number').html('')
                                                        $('.drawn-cards .cmp-card').each((idx, cardEle) => {
                                                            for (let sIdx = 0; sIdx < selectedCards.length; sIdx++) {
                                                                if (selectedCards[sIdx] === idx)
                                                                    $(cardEle).find('.card-number').html(`<div>${sIdx + 1}</div>`)
                                                            }
                                                        })
                                                        setCardParams({ ...params, selectedCards: [...selectedCards] })
                                                        ele.toggleClass('selected')
                                                        setGalleryIdx(i)
                                                    }}></Card>
                                            })}
                                        </Cards>
                                        <Buttons>
                                            <Button onClick={() => {
                                                if (selectedCards.length !== 2) {
                                                    setHint(intl.get('HINT.replace_cards'))
                                                    return
                                                }
                                                deck.splice(deck.length - 3, 3);
                                                selectedCards.map((idx) => {
                                                    deck.push({ ...drawnCards[idx] })
                                                })
                                                selectedCards.sort().map((idx, i) => {
                                                    drawnCards.splice(idx - i, 1)
                                                })
                                                hands[casterUsername].push({ ...drawnCards[0] })
                                                graveyard.push(...hands[casterUsername].splice(cardIdx, 1));
                                                setGraveyard([...graveyard])
                                                setDeck([...deck])
                                                setHands({ ...hands })
                                                setModalContent(undefined)
                                                setGallery([])
                                                setTurnState('discard')
                                            }}>{intl.get('ACTIONS.return')}</Button>
                                            <Button onClick={() => {
                                                setGallery([])
                                                setModalContent(undefined)
                                                setCardParams({})
                                            }}>{intl.get('ACTIONS.back')}</Button>
                                        </Buttons>
                                    </ModalBottom>
                                </Modal>
                            )
                            break
                    }
                    break
            }
        },
        'jelly-princess': () => {
            switch (skillIdx) {
                case 0:
                    if (dryRun) return true
                    reapers[targetUsername][targetReaperIdx].damage += 1;
                    reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                        username: casterUsername,
                        reaperIdx: casterReaperIdx,
                        isSkill: true
                    };
                    graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                    setGraveyard([...graveyard])
                    setTurnState('cause-damage')
                    setReapers({ ...reapers })
                    setHands({ ...hands });
                    setCardParams({
                        ...params, afterState: () => {
                            clearReaperState()
                            setTurnState('force-discard')
                            setCardParams({
                                ...params, afterState: () => {
                                    setCardParams({})
                                    setTurnState(reaper.computer ? 'action' : 'play-or-draw')
                                }
                            })
                        }
                    })
                    setGallery([])
                    break;
                case 1:
                    if (dryRun) return true
                    hands[casterUsername].splice(cardIdx, 1)
                    card.description = `${t.name} - ${t.skills[skillIdx].name}: ${t.skills[skillIdx].desc}`
                    card.original = { key: reaperKey || reaper.key, skillIdx }
                    card.damageReduced = 1
                    if (applyCharmingEffectsToCaster)
                        reapers[casterUsername][casterReaperIdx].field.push({ ...card })
                    else
                        reapers[targetUsername][targetReaperIdx].field.push({ ...card })
                    setHands({ ...hands });
                    setReapers({ ...reapers })
                    setTurnState('discard');
                    setCardParams({})
                    break;
                case 2:
                    switch (skillState) {
                        case 0:
                            const oneSpCards = graveyard.filter((c) => {
                                return c.type === 'spirit' && spiritToSPCount(c.key).sp === 1
                            }).map((c, i) => {
                                return { ...c, graveyardIdx: i }
                            })
                            if (oneSpCards.length === 0) {
                                setHint(intl.get('HINT.no_spirit_in_graveyard'))
                                setGallery([])
                                setCardParams({})
                                setModalContent(undefined)
                                return
                            }
                            if (dryRun) return true
                            if (reaper.computer) {
                                const spiritCard = randomElement(oneSpCards)
                                if (validateMaxSpiritsRule(reapers[casterUsername][casterReaperIdx].field, spiritCard)) {
                                    reapers[casterUsername][casterReaperIdx].field.push({ ...spiritCard })
                                    setReapers({ ...reapers })
                                } else {
                                    hands[casterUsername].push({ ...spiritCard })
                                    setHands({ ...hands });
                                }
                                graveyard.splice(card.graveyardIdx, 1)
                                setGraveyard([...graveyard])
                                setTurnState('discard')
                                setCardParams({})
                                setHint(intl.get('HINT.spirit_summoned', { user: casterUsername }))
                            } else {
                                setGallery([...oneSpCards])
                                setDragTargets([
                                    `#${casterUsername}_${casterReaperIdx}`,
                                    '#your-hand'
                                ])
                                setGalleryIdx(0);
                                setCardParams({
                                    ...params, skillState: 1
                                })
                            }
                            break
                        case 1:
                            if (!selectedEle) return
                            const spiritCard = gallery[galleryIdx]
                            if ($(selectedEle).hasClass('reaper')) {
                                const [username, reaperIdx] = selectedEle[0].id.split('_')
                                if (validateMaxSpiritsRule(reapers[username][parseInt(reaperIdx)].field, spiritCard)) {
                                    reapers[username][parseInt(reaperIdx)].field.push({ ...spiritCard })
                                    setReapers({ ...reapers })
                                    graveyard.splice(card.graveyardIdx, 1)
                                    setGraveyard([...graveyard])
                                    setTurnState('discard')
                                    setCardParams({})
                                }
                            } else if (selectedEle[0].id === 'your-hand') {
                                hands[casterUsername].push({ ...spiritCard })
                                setHands({ ...hands });
                                graveyard.splice(spiritCard.graveyardIdx, 1)
                                setGraveyard([...graveyard])
                                setCardParams({})
                                setTurnState('discard')
                            }
                            break
                    }
                    break
            }
        },
    }[reaperKey || reapers[casterUsername][casterReaperIdx].key])

    return skillFn()
}

export const getReaperSkillTargets = {
    'pink-void': ({ reapers, username, skillIdx, skillState, orbReaperIdx, fromArtifact, turnState }) => {
        const targets = [];
        switch (skillIdx) {
            case 0:
                targets.push('#graveyard')
                break
            case 1:
                targets.push(`#${username}_${orbReaperIdx}`)
                break
            case 2:
                Object.keys(reapers).map(name => {
                    reapers[name].map((r, i) => {
                        targets.push(`#${name}_${i}`)
                    })
                })
                break
        }
        return targets
    },
    'combo-dia': ({ reapers, username, skillIdx, skillState, orbReaperIdx, fromArtifact, turnState }) => {
        const targets = [];
        switch (skillIdx) {
            case 0:
            case 1:
            case 2:
                Object.keys(reapers).map(name => {
                    if (username === name) return
                    reapers[name].map((r, i) => {
                        targets.push(`#${name}_${i}`)
                    })
                })
                break
        }
        return targets
    },
    'swallia': ({ reapers, username, skillIdx, skillState, orbReaperIdx, fromArtifact, turnState }) => {
        const targets = [];
        switch (skillIdx) {
            case 0:
            case 1:
            case 2:
                Object.keys(reapers).map(name => {
                    if (username === name) return
                    reapers[name].map((r, i) => {
                        targets.push(`#${name}_${i}`)
                    })
                })
                break
        }
        return targets
    },
    'note-eater': ({ reapers, username, skillIdx, skillState, orbReaperIdx, fromArtifact, turnState }) => {
        const targets = [];
        switch (skillIdx) {
            case -1:
            case 0:
                targets.push('#graveyard')
                break
            case 1:
            case 2:
                if (turnState === 'interrupt') break
                Object.keys(reapers).map(name => {
                    if (username === name) return
                    reapers[name].map((r, i) => {
                        targets.push(`#${name}_${i}`)
                    })
                })
                break
        }
        return targets
    },
    'hermona': ({ reapers, username, skillIdx, skillState, orbReaperIdx, fromArtifact }) => {
        const targets = [];
        switch (skillIdx) {
            case 1:
                targets.push('#graveyard')
                break
            case 0:
            case 2:
                Object.keys(reapers).map(name => {
                    if (username === name) return
                    reapers[name].map((r, i) => {
                        targets.push(`#${name}_${i}`)
                    })
                })
                break
        }
        return targets
    },
    'delta-6': ({ reapers, username, skillIdx, skillState, orbReaperIdx, fromArtifact }) => {
        const targets = [];
        switch (skillIdx) {
            case 1:
                targets.push('#graveyard')
                break
            case 0:
            case 2:
                Object.keys(reapers).map(name => {
                    if (username === name) return
                    reapers[name].map((r, i) => {
                        targets.push(`#${name}_${i}`)
                    })
                })
                break
        }
        return targets
    },
    'bracket-boy': ({ reapers, username, skillIdx, skillState, orbReaperIdx, fromArtifact }) => {
        const targets = [];
        switch (skillIdx) {
            case 0:
            case 1:
                Object.keys(reapers).map(name => {
                    if (username === name) return
                    reapers[name].map((r, i) => {
                        targets.push(`#${name}_${i}`)
                    })
                })
                break;
            case 2:
                targets.push('#graveyard')
                break
        }
        return targets
    },
    'jelly-princess': ({ reapers, username, skillIdx, skillState, orbReaperIdx, fromArtifact }) => {
        const targets = [];
        switch (skillIdx) {
            case 0:
                Object.keys(reapers).map(name => {
                    if (username === name) return
                    reapers[name].map((r, i) => {
                        targets.push(`#${name}_${i}`)
                    })
                })
                break;
            case 1:
                targets.push(`#${username}_${orbReaperIdx}`)
                break;
            case 2:
                if (skillState === 1 || fromArtifact)
                    targets.push(...[
                        `#${username}_${orbReaperIdx}`,
                        '#your-hand'
                    ])
                else
                    targets.push('#graveyard')
                break
        }
        return targets
    }
}

export const getReaperSkillDP = {
    'pink-void': (skillIdx) => {
        switch (skillIdx) {
            case 1:
                return { dp: 99 }
        }
    },
    'jelly-princess': (skillIdx) => {
        switch (skillIdx) {
            case 1:
                return { dp: 1 }
        }
    }
}

export const interruptableCards = (reaper, hand) => {
    switch (reaper.key) {
        case 'note-eater':
            return hand.filter((c, i) => {
                c.cardIdx = i
                return c.type === 'orb' && (c.key === 'green-orb' || c.key === 'gold-orb')
            }).sort((a, b) => {
                return a.key === 'green-orb' ? -1 : 1
            })
    }
}

const gatherEffects = ({ targetUsername, targetReaperIdx, casterUsername, casterReaperIdx, targetField, casterField, nonSkillSideEffect }) => {
    if (nonSkillSideEffect) return []
    return [...casterField.map((c, i) => {
        let effect = {
            cardIdx: i, username: casterUsername, reaperIdx: parseInt(casterReaperIdx),
            skipPlayOrDraw: c.skipPlayOrDraw, key: c.key, casterUsername, casterReaperIdx
        }
        switch (c.key) {
            case 'perfect-aim':
                effect.ignoreProtection = true
                break
            case 'phantom-amplifier':
                effect.targetDamage = 1
                break
            case 'chancellor-canceler':
                effect.skipPlayOrDraw = true
                break
            case 'dream-sucker':
                effect.casterDamage = 1
                break
        }
        return effect
    }), ...targetField.map((c, i) => {
        let effect = { cardIdx: i, username: targetUsername, reaperIdx: parseInt(targetReaperIdx), key: c.key, casterUsername, casterReaperIdx }
        switch (c.key) {
            case 'anti-chip-damage-charm':
                effect.targetDamage = -1
                break
            case 'nightmare-repeater':
                effect.casterDamage = 1
                break
            case 'moon-silencer':
                effect.targetDamage = -1
                break
        }
        return effect
    })]
}

export const applyEffects = (props) => {
    const { targetUsername, targetReaperIdx, effects, setEffects, reapers, setReapers, graveyard, setGraveyard, setHint } = props;
    const consumedCards = []
    const locateCardIdx = (effect) => {
        return (reapers[effect.username][effect.reaperIdx].field.filter((c, i) => {
            c.cardIdx = i
            return c.key === effect.key
        }) || [{ cardIdx: -1 }])[0].cardIdx
    }
    let noChangeTurnState
    effects.map((effect) => {
        if (reapers[effect.username][effect.reaperIdx].damageTargets) return
        if (effect.ignoreProtection && reapers[targetUsername][targetReaperIdx].damage > 0) {
            reapers[targetUsername][targetReaperIdx].ignoreProtection = true
            consumedCards.push(...reapers[effect.username][effect.reaperIdx].field.splice(locateCardIdx(effect), 1))
        }
        if (effect.targetDamage !== undefined) {
            if (reapers[targetUsername][targetReaperIdx].ignoreProtection) return
            reapers[targetUsername][targetReaperIdx].damage += effect.targetDamage
            if (reapers[targetUsername][targetReaperIdx].damage < 0) reapers[targetUsername][targetReaperIdx].damage = 0
            reapers[targetUsername][targetReaperIdx].damageChanged = true
            consumedCards.push(...reapers[effect.username][effect.reaperIdx].field.splice(locateCardIdx(effect), 1))
        }
        if (effect.casterDamage !== undefined) {
            if (reapers[effect.casterUsername][effect.casterReaperIdx].ignoreProtection) return
            reapers[effect.casterUsername][effect.casterReaperIdx].damage += effect.casterDamage
            if (reapers[effect.casterUsername][effect.casterReaperIdx].damage < 0) reapers[effect.casterUsername][effect.casterReaperIdx].damage = 0
            reapers[effect.casterUsername][effect.casterReaperIdx].damageChanged = true
            consumedCards.push(...reapers[effect.username][effect.reaperIdx].field.splice(locateCardIdx(effect), 1))
            noChangeTurnState = true
            setHint(intl.get('HINT.effects_damage_applied', {
                user: effect.casterUsername,
                reaper: intl.get('CARDS.reaper')[reapers[effect.casterUsername][effect.casterReaperIdx].key].name,
                damage: reapers[effect.casterUsername][effect.casterReaperIdx].damage,
            }))
        }
    })
    if (consumedCards.length === 0) {
        return
    }
    graveyard.push(...consumedCards)
    setReapers({ ...reapers })
    setGraveyard([...graveyard])
    setEffects([])
    return noChangeTurnState
}

const consumeFieldCard = ({ effect, graveyard, setGraveyard, reapers, setReapers }) => {
    graveyard.push(...reapers[effect.username][effect.reaperIdx].field.splice(effect.cardIdx, 1))
    setReapers({ ...reapers })
    setGraveyard([...graveyard])
}
