import React, { useState } from "react";
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import { useSafearea } from "./page";
import 'index.css'

import SettingsIcon from 'assets/icons/settings_24px.svg'
import intl from "react-intl-universal";
import { useAppContext } from "./context";

import ButtonMP3 from 'assets/sounds/button.mp3'
import SelectMP3 from 'assets/sounds/select.mp3'
import DoorSvg from 'assets/icons/door.svg'
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

const Wrapper = styled.img`
    position: fixed;
    right: 0;
    top: ${({ offset }) => offset}px;
    width: 32px;
    height: 32px;
    z-index: 1;
    margin: 0.5em;
`

const SettingsMenu = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 10;
`

const Backdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.8);
`

const ContentWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`

const Button = styled.div`
    font-size: 40px;
    margin: 0.5em auto;
`

const ExitButton = styled.div`
    position: absolute;
    top: 0;
    font-size: 24px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    & > * {
        display: inline-flex;
        margin: 0.25em;
    }
`

const DoorImg = styled.img`
`

const ButtonText = styled.div`
    margin-top: 0.5em;
    margin-left: 0;
`

export const SettingsButton = () => {
    const { safearea } = useSafearea();
    const [opened, setOpened] = useState(false);
    const { setSfx, muteSound, setMuteSound } = useAppContext();
    const navigate = useNavigate();
    return <>
        <Wrapper src={SettingsIcon} className="clickable" offset={safearea.top} onClick={() => {
            setSfx(SelectMP3)
            setOpened(!opened)
        }} />
        {opened ? <SettingsMenu className="cmp-settings-menu fade-in">
            <Backdrop onClick={() => {
                setOpened(!opened)
            }} />
            <ContentWrapper>
                <Button className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    window.open("https://teenreapers.com", '_blank');
                }}>{intl.get('SETTINGS.WEBSITE')}</Button>
                <Button className="button" onClick={(e) => {
                    setSfx(ButtonMP3)
                    window.open("https://discord.com/invite/pzSk3agwpx", '_blank');
                }}>{intl.get('SETTINGS.COMMUNITY')}</Button>
                <Button className="button" onClick={() => {
                    setMuteSound(!muteSound)
                    SecureStoragePlugin.set({ key: 'muteSound', value: JSON.stringify(!muteSound) })
                }}>{muteSound ? intl.get('SETTINGS.UNMUTE') : intl.get('SETTINGS.MUTE')}</Button>
                <Button className="button" onClick={() => {
                    setSfx(ButtonMP3)
                    setOpened(!opened)
                }}>{intl.get('SETTINGS.BACK')}</Button>
            </ContentWrapper>
        </SettingsMenu> : ''}
        <ExitButton onClick={() => {
            setSfx(ButtonMP3)
            navigate('/menu')
        }}>
            <DoorImg src={DoorSvg} />
            <ButtonText>{intl.get('ACTIONS.exit')}</ButtonText>
        </ExitButton>
    </>
}