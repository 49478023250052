import { Capacitor } from "@capacitor/core";

export const shuffle = (oldArray) => {
    let array = [...oldArray]
    let currentIndex = array.length, randomIndex;
    while (currentIndex > 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}

export const isMobile = () => {
    return Capacitor.getPlatform() !== 'web'
}

export const arrayRotate = (arr, reverse) => {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
}

export const randomElement = (array) => {
    return array[Math.floor(Math.random() * array.length)];
}
