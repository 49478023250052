import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

import { DeleteAccount } from "lib/api";

import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'
import { Text, Error, Button } from "components/input";
import { useAppContext } from "components/context";

import ButtonMP3 from 'assets/sounds/button.mp3'
import ErrorMP3 from 'assets/sounds/error.mp3'

const Wrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    & > * {
        margin: 0.5em auto;
    }
`

export const DeleteAccountPage = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const { isLoggedIn, setIsLoggedIn, setSfx } = useAppContext()

    useEffect(() => {
        if (!isLoggedIn) navigate('/login')
    }, [isLoggedIn])

    return (
        <>
            <DeadNightSky />
            <div className="bg-smoke" />
            <Moon>
                <Eclipse />
            </Moon>
            <Mountain />
            <div className="fg-smoke" />
            <Wrapper>
                <Text>{intl.get('DELETE_ACCT_PAGE.delete_account')}</Text>
                {success !== '' ? '' : <Button onClick={() => {
                    setSfx(ButtonMP3)
                    DeleteAccount().then(() => {
                        SecureStoragePlugin.clear()
                        setIsLoggedIn(false)
                        setSuccess(intl.get("DELETE_ACCT_PAGE.account_deleted"))
                        setError('')
                    }).catch(err => {
                        setError(err)
                        setSuccess('')
                        setSfx(ErrorMP3)
                    })
                }}>{intl.get('ACTIONS.confirm')}</Button>}
                <Button onClick={() => {
                    setSfx(ButtonMP3)
                    navigate(success !== '' ? '/' : '/menu')
                }}>{intl.get('ACTIONS.back')}</Button>
                {error ? <Error>{error}</Error> : ''}
                {success ? <Text $size="sm">{success}</Text> : ''}
            </Wrapper>
        </>
    );
};