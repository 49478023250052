import axios from 'axios'

import { createContext, useContext, useEffect, useState } from "react"
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"
import { useLocation, useNavigate } from "react-router-dom"
import ReactAudioPlayer from 'react-audio-player';
import { AdMob, InterstitialAdPluginEvents } from '@capacitor-community/admob';

import { CardsDiscovered, LevelsProgress, RankApi } from "lib/storage"
import { AssertLoggedIn } from "lib/api"
import { InitializeIAP } from 'lib/store';

import FinalExtinctionMP3 from 'assets/sounds/final_extinction.mp3'

let audioContext = null;

export const AppContext = createContext({})

export const useAppContext = () => useContext(AppContext)

export const AppProvider = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [chars, setChars] = useState([])
    const [acctUsername, setAcctUsername] = useState('guest')
    const [acctEmail, setAcctEmail] = useState('guest@email.com')
    const [accessToken, setAccessToken] = useState(undefined)
    const [isLoggedIn, setIsLoggedIn] = useState(undefined)
    const [scene, setScene] = useState(undefined)
    const [level, setLevel] = useState(0)
    const [completedLevels, setCompletedLevels] = useState({})
    const [discoveredCards, setDiscoveredCards] = useState({})
    const [noAds, setNoAds] = useState(false)
    const [rank, setRank] = useState({})
    const [bgm, setBgm] = useState(false)
    const [sfx, setSfx] = useState(false)
    const [muteSound, setMuteSound] = useState(undefined)
    const [playingAd, setPlayingAd] = useState(false)
    const [termsAccepted, setTermsAccepted] = useState(false)

    useEffect(() => {
        SecureStoragePlugin.get({ key: 'access_token' }).then((result) => {
            if (result?.value === '') navigate('/menu')
            else {
                setAccessToken(result.value)
            }
        }).catch(err => {
            setIsLoggedIn(false)
        })
        SecureStoragePlugin.get({ key: 'display_name' }).then(result => {
            setAcctUsername(result.value)
        }).catch(err => { })
        SecureStoragePlugin.get({ key: 'email' }).then(result => {
            setAcctEmail(result.value)
        }).catch(err => { })
        SecureStoragePlugin.get({ key: 'no-ads' }).then(result => {
            setNoAds(true)
        }).catch(err => { })
        LevelsProgress.get().then(result => {
            setCompletedLevels(result)
        }).catch(err => { console.log(completedLevels) })
        CardsDiscovered.get().then(result => {
            result['green-orb'] = true
            result['blue-orb'] = true
            result['red-orb'] = true
            result['gold-orb'] = true
            result['lesser-spirit'] = true
            result['ordinary-spirit'] = true
            result['greater-spirit'] = true
            result['perfect-aim'] = true
            result['exorcise-exercise'] = true
            result['half-alive-spirit'] = true
            result['anti-chip-damage-charm'] = true
            result['seal-of-disapproval'] = true
            result['hanging-wire'] = true
            result['roulette-eye'] = true
            setDiscoveredCards(result)
        })
        RankApi.getExp().then(result => {
            // DEBUG
            const r = RankApi.calcRank(result)
            // r.currRank = 12
            setRank(r)
        })
        SecureStoragePlugin.get({ key: 'muteSound' }).then(result => {
            setMuteSound(JSON.parse(result.value))
        }).catch(err => { setMuteSound(false) })
        SecureStoragePlugin.get({ key: 'termsPrompt' }).then(result => {
            setTermsAccepted(JSON.parse(result.value))
        }).catch(err => { setTermsAccepted(false) })
        InitializeIAP()

        window.onblur = function () {
            setMuteSound(true)
        };
        window.onfocus = function () {
            SecureStoragePlugin.get({ key: 'muteSound' }).then(result => {
                setMuteSound(JSON.parse(result.value))
            }).catch(err => { setMuteSound(false) })
        };

        // Initialize AdMob
        AdMob.initialize();
        AdMob.trackingAuthorizationStatus().then((trackingInfo) => {
            if (trackingInfo.status === 'notDetermined') {
                AdMob.requestTrackingAuthorization();
            }
            const authorizationStatus = AdMob.trackingAuthorizationStatus();
            if (authorizationStatus.status === 'authorized') {
                AdMob.showConsentForm();
            }
            AdMob.prepareInterstitial({
                adId: 'ca-app-pub-7717261113231788/8431980551',
            });
        })
        AdMob.addListener(InterstitialAdPluginEvents.Dismissed, () => {
            setPlayingAd(false)
        });
        AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, () => {
            setPlayingAd(false)
        });
        AdMob.addListener(InterstitialAdPluginEvents.FailedToShow, () => {
            setPlayingAd(false)
        });
    }, [])

    useEffect(() => {
        switch (location.pathname) {
            case '/':
            case '/login':
            case '/delete-account':
            case '/game':
            case '/menu':
            case '/campaigns':
            case '/char-select':
            case '/progress':
                setBgm(FinalExtinctionMP3)
                break
        }
    }, [location])

    useEffect(() => {
        if (!accessToken) {
            try { delete axios.defaults.headers.common["Authorization"] }
            catch (err) { }
            return
        }
        axios.interceptors.request.use(function (config) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
            config.headers['Content-Type'] = 'application/json';
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
        AssertLoggedIn().then(() => {
            setIsLoggedIn(true)
        }).catch(err => { })
    }, [accessToken])

    useEffect(() => {
        if (!sfx || muteSound) return
        audioPlay(sfx)
        setSfx(false)
    }, [sfx])

    const audioPlay = (() => {
        return async url => {
            if (audioContext) audioContext.close();
            audioContext = new AudioContext();
            const source = audioContext.createBufferSource();
            source.buffer = await fetch(url)
                .then(res => res.arrayBuffer())
                .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer));
            source.connect(audioContext.destination);
            source.start();
        };
    })();

    return (
        <AppContext.Provider value={{
            chars, setChars,
            acctUsername, setAcctUsername,
            acctEmail, setAcctEmail,
            setAccessToken,
            isLoggedIn, setIsLoggedIn,
            scene, setScene,
            level, setLevel,
            completedLevels, setCompletedLevels,
            discoveredCards, setDiscoveredCards,
            rank, setRank,
            setBgm, setSfx,
            muteSound, setMuteSound,
            playingAd, setPlayingAd,
            noAds, setNoAds,
            termsAccepted, setTermsAccepted
        }}>
            {props.children}
            {bgm && !muteSound && !playingAd ? <ReactAudioPlayer
                id="bgm"
                src={bgm}
                autoPlay
                loop
            /> : ''}
        </AppContext.Provider>
    )
}