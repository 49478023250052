import { arrayRotate, randomElement, shuffle } from './utils';
import intl from 'react-intl-universal';
import { Button, Buttons } from "components/button";
import { Label } from "components/common";
import { Card, Cards, CardBack } from "components/cards";
import { Modal, ModalBottom } from "components/modal";
import $ from 'jquery'
import { validateMaxSpiritsRule } from './field';
import { applySkill, getReaperSkillTargets } from './reapers';
import { SkillChoices, TargetChoices } from 'components/choices';

export const applyArtifact = (params) => {
    const {
        casterUsername, casterReaperIdx, targetUsername, targetReaperIdx, cardIdx,
        hands, setHands, reapers, setReapers, turnState, setTurnState, setCardParams, card, originalCard,
        gallery, setGallery, galleryIdx, setGalleryIdx, deck, setDeck, clearReaperState,
        graveyard, setGraveyard, selectedCards, cardState, selectedEle, setDamageCaused,
        setDragTargets, modalContent, setModalContent, setDragAndDropReminder,
        setCardMovePos, setMovingCard, setOnCardMoveEnd, reaperKey, skillIdx, setHint, dryRun
    } = params;
    const targetCard = reapers[targetUsername] ? {
        type: "reaper",
        key: reapers[targetUsername][targetReaperIdx].key
    } : {};
    const handChoices = []
    Object.keys(hands).forEach((username) => {
        if (hands[username].length > 0)
            handChoices.push({
                username,
                hand: hands[username]
            })
    })
    const reaperChoices = []
    Object.keys(reapers).forEach((username) => {
        if (reapers[username].length > 0)
            reaperChoices.push({
                username,
                reaper: reapers[username]
            })
    })

    const effects = gatherEffects({
        casterUsername, casterReaperIdx,
        targetUsername, targetReaperIdx,
        casterField: reapers[casterUsername][casterReaperIdx].field,
        targetField: targetReaperIdx ? reapers[targetUsername][targetReaperIdx].field : [],
        card
    }) || []
    for (let i = 0; i < effects.length; i++) {
        if (effects[i].skipPlayOrDraw) {
            if (dryRun) return true
            consumeFieldCard({ effect: effects[i], graveyard, setGraveyard, reapers, setReapers })
            graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
            setHands({ ...hands });
            setGallery([])
            setTurnState('discard')
            setCardParams({})
            setGraveyard(graveyard)
            return
        }
    }

    switch (originalCard.key) {
        case 'an-art-4-an-art':
            let totalReapersWithArtifact = 0
            Object.keys(reapers).map(username => {
                reapers[username].map((r) => {
                    if (r.field.filter(c => {
                        return c.type === 'artifact'
                    }).length > 0)
                        totalReapersWithArtifact++
                })
            })
            if (dryRun) return totalReapersWithArtifact >= 2
            if (totalReapersWithArtifact < 2) {
                setHint(intl.get('HINT.no_artifacts_swap'))
                return
            }
            if (reapers[casterUsername][casterReaperIdx].computer) {
                const swaps = {}
                Object.keys(reapers).map(username => {
                    swaps[username] = []
                    reapers[username].map((r, reaperIdx) => {
                        r.field.map((c, cardIdx) => {
                            if (c.type === 'artifact') {
                                c.cardIdx = cardIdx
                                c.reaperIdx = reaperIdx
                                swaps[username].push({ ...c })
                            }
                        })
                    })
                })
                const users = shuffle(Object.keys(swaps)).slice(0, 2)
                const card0 = randomElement(swaps[users[0]])
                const card1 = randomElement(swaps[users[1]])
                reapers[users[0]][card0.reaperIdx].field[card0.cardIdx] = { ...card1 }
                reapers[users[1]][card1.reaperIdx].field[card1.cardIdx] = { ...card0 }
                setReapers({ ...reapers })
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                setTurnState('discard')
                return
            }
            setDragAndDropReminder(false)
            setModalContent(
                <Modal>
                    <ModalBottom>
                        {Object.keys(reapers).map(username => {
                            return reapers[username].map((r, j) => {
                                const reaperCard = {
                                    type: "reaper",
                                    key: r.key
                                }
                                return <Cards key={j}>
                                    <Card card={reaperCard} i={-1} onClick={() => {
                                        setGallery([reaperCard, ...r.field])
                                        setGalleryIdx(0)
                                    }} />
                                    {r.field.map((c, i) => {
                                        c.username = username
                                        c.reaperIdx = j
                                        c.cardIdx = i
                                        return <Card className={c.type === 'artifact' ? 'glow' : ''} key={i} card={c} i={i} onClick={(e) => {
                                            setGallery([reaperCard, ...r.field])
                                            setGalleryIdx(i + 1)
                                            if (c.type !== 'artifact') return;
                                            $(e.target).closest('.cmp-card').toggleClass('selected')
                                        }} />
                                    })}
                                </Cards>
                            })
                        })}
                        <Buttons>
                            <Button onClick={() => {
                                const eles = $('.selected')
                                if (eles.length !== 2) {
                                    setHint(intl.get('HINT.swap_2_artifacts'))
                                    return
                                }
                                const meta0 = JSON.parse(eles[0].dataset.meta);
                                const meta1 = JSON.parse(eles[1].dataset.meta);
                                reapers[meta0.username][meta0.reaperIdx].field[meta0.cardIdx] = { ...meta1 }
                                reapers[meta1.username][meta1.reaperIdx].field[meta1.cardIdx] = { ...meta0 }
                                setReapers({ ...reapers })
                                setModalContent(undefined)
                                setGallery([])
                                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                setTurnState('discard')
                            }}>{intl.get('ACTIONS.swap')}</Button>
                            <Button onClick={() => {
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'exchange-hands':
            if (dryRun) return true
            if (reapers[casterUsername][casterReaperIdx].computer) {
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                const username = randomElement(Object.keys(hands).filter((username) => {
                    return username !== casterUsername
                }))
                const userHand = [...hands[username]]
                hands[username] = [...hands[casterUsername]]
                hands[casterUsername] = userHand
                setHands({ ...hands })
                setTurnState('discard')
                setHint(intl.get('HINT.hands_exchanged', {
                    user_1: username,
                    user_2: casterUsername,
                }))
                return
            }
            setDragAndDropReminder(false)
            setGallery([])
            setModalContent(
                <Modal>
                    <ModalBottom>
                        {handChoices.map((user, i) => {
                            if (hands[user.username].length === 0) return
                            return <div key={i}>
                                <Label $emphasize={casterUsername === user.username}>{user.username} ({hands[user.username].length} {intl.get('CARDS_IN_HAND')})</Label>
                                <Cards className={`cmp-cards ${user.username === casterUsername ? "selected" : "glow"}`} onClick={e => {
                                    if (user.username === casterUsername) return;
                                    $(e.target).closest('.cmp-cards').toggleClass("selected");
                                }} data-meta={JSON.stringify({
                                    username: user.username,
                                    hand: hands[user.username]
                                })}>
                                    {user.hand.map((c, j) => {
                                        return user.username === casterUsername ?
                                            <Card key={j} card={c} i={-1} /> :
                                            <CardBack key={j} $size="small" />
                                    })}
                                </Cards>
                            </div>
                        })}
                        <Buttons>
                            <Button onClick={() => {
                                const eles = $('.selected')
                                if (eles.length !== 2) {
                                    setHint(intl.get('HINT.swap_2_hands'))
                                    return
                                }
                                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                const meta0 = JSON.parse(eles[0].dataset.meta);
                                const meta1 = JSON.parse(eles[1].dataset.meta);
                                if (meta0.username === casterUsername) {
                                    meta0.hand.splice(cardIdx, 1)
                                } else {
                                    meta1.hand.splice(cardIdx, 1)
                                }
                                hands[meta0.username] = meta1.hand
                                hands[meta1.username] = meta0.hand
                                setHands({ ...hands })
                                setModalContent(undefined)
                                setGallery([])
                                setHint(intl.get('HINT.hands_exchanged', {
                                    user_1: meta0.username,
                                    user_2: meta1.username,
                                }))
                                setTurnState('discard')
                            }}>{intl.get('ACTIONS.swap')}</Button>
                            <Button onClick={() => {
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'exorcise-exercise':
            if (dryRun) return true
            if (reapers[casterUsername][casterReaperIdx].computer) {
                const username = randomElement(Object.keys(hands).filter((username) => {
                    return username !== casterUsername
                }))
                const reaperIdx = Math.floor(Math.random() * reapers[username].length);
                reapers[username][reaperIdx].field.forEach((ele, idx) => {
                    ele.idx = idx
                })
                if (applyEffects({ effects, graveyard, setGraveyard, reapers, setReapers, casterUsername, casterReaperIdx, targetUsername, targetReaperIdx, setHint })) {
                    consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                    setTurnState('discard')
                    setModalContent(undefined)
                    setGallery([])
                    return
                }
                const spirit = randomElement(reapers[username][reaperIdx].field.filter(c => c.type === 'spirit'))
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                graveyard.push(...reapers[username][reaperIdx].field.splice(spirit.idx, 1))
                setGraveyard([...graveyard])
                setReapers({ ...reapers })
                setTurnState('discard')
                setHint(intl.get('HINT.card_destroyed', {
                    user: username
                }))
                return
            }
            setDragAndDropReminder(false)
            setGalleryIdx(0)
            setGallery([targetCard, ...reapers[targetUsername][targetReaperIdx].field])
            if (applyEffects({ effects, graveyard, setGraveyard, reapers, setReapers, casterUsername, casterReaperIdx, targetUsername, targetReaperIdx, setHint })) {
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                setTurnState('discard')
                setModalContent(undefined)
                setGallery([])
                return
            }
            setModalContent(
                <Modal>
                    <ModalBottom>
                        <Cards>
                            <Card key={-1} card={targetCard} onClick={() => {
                                setGalleryIdx(0)
                            }}></Card>
                            {reapers[targetUsername][targetReaperIdx].field.map((c, i) => {
                                return <Card className={c.type === 'spirit' ? 'glow' : ''} key={i} card={c} i={i} onClick={(e) => {
                                    setGalleryIdx(i + 1)
                                    $('.cmp-card').removeClass('selected')
                                    if (c.type !== 'spirit') return;
                                    $(e.target).closest('.cmp-card').addClass('selected')
                                }} />
                            })}
                        </Cards>
                        <Buttons>
                            <Button onClick={() => {
                                const eles = $('.selected')
                                if (eles.length !== 1) {
                                    setHint(intl.get('HINT.select_spirit'))
                                    return
                                }
                                const idx = JSON.parse(eles[0].dataset.meta).idx;
                                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                graveyard.push(...reapers[targetUsername][targetReaperIdx].field.splice(idx, 1))
                                setGraveyard([...graveyard])
                                setReapers({ ...reapers })
                                setModalContent(undefined)
                                setGallery([])
                                setTurnState('discard')
                            }}>{intl.get('ACTIONS.destroy')}</Button>
                            <Button onClick={() => {
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'frostbite':
            if (dryRun) return true
            consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
            setCardParams({ ...params, cardState: 1 })
            reapers[targetUsername][targetReaperIdx].damage += 1;
            reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                username: casterUsername,
                reaperIdx: casterReaperIdx,
            };
            setReapers({ ...reapers })
            setTurnState('cause-damage')
            setCardParams({
                ...params, afterState: () => {
                    setHint(intl.get('HINT.turned_skipped', { user: targetUsername }))
                    setTurnState('play-or-draw')
                    setCardParams({})
                }
            })
            break
        case 'hanging-wire':
            if (dryRun) {
                let hasArtifact
                Object.keys(reapers).map(username => {
                    if (hasArtifact) return
                    reapers[username].map(r => {
                        if (hasArtifact) return
                        r.field.map(c => {
                            if (c.type === 'artifact' &&
                                (
                                    (c.sub_type === 'charm' && username !== casterUsername) ||
                                    (c.sub_type === 'curse' && username === casterUsername)
                                ))
                                hasArtifact = true
                        })
                    })
                })
                return hasArtifact
            }
            if (reapers[casterUsername][casterReaperIdx].computer) {
                let moved
                Object.keys(reapers).map(username => {
                    reapers[username].map((r, reaperIdx) => {
                        r.field.map((c, cardIdx) => {
                            if (moved) return
                            if (c.type === 'artifact') {
                                if (c.sub_type === 'charm' && username !== casterUsername) {
                                    reapers[casterUsername][casterReaperIdx].field.push(...reapers[username][reaperIdx].field.splice(cardIdx, 1))
                                    moved = [username, casterUsername]
                                } else if (c.sub_type === 'curse' && username === casterUsername) {
                                    const randUsername = randomElement(Object.keys(hands).filter((u) => {
                                        return u !== casterUsername
                                    }))
                                    const randReaperIdx = Math.floor(Math.random() * reapers[randUsername].length);
                                    reapers[randUsername][randReaperIdx].field.push(...reapers[casterUsername][casterReaperIdx].field.splice(cardIdx, 1))
                                    moved = [casterUsername, randUsername]
                                }
                            }
                        })
                    })
                })
                setHint(intl.get('HINT.card_moved', {
                    from: moved[0],
                    to: moved[1]
                }))
                setReapers({ ...reapers })
                setTurnState('discard')
                return
            }
            switch (cardState) {
                case 0:
                    setDragTargets(undefined)
                    setCardParams({ ...params, cardState: 1 })
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                {Object.keys(reapers).map(username => {
                                    return reapers[username].map((r, j) => {
                                        const reaperCard = {
                                            type: "reaper",
                                            key: r.key
                                        }
                                        return <Cards key={j}>
                                            <Card card={reaperCard} i={-1} onClick={() => {
                                                setGallery([reaperCard, ...r.field])
                                                setGalleryIdx(0)
                                            }} />
                                            {r.field.map((c, i) => {
                                                c.username = username
                                                c.reaperIdx = j
                                                c.cardIdx = i
                                                return <Card className={c.type === 'artifact' ? 'glow' : ''} key={i} card={c} i={i} onClick={(e) => {
                                                    setGallery([reaperCard, ...r.field])
                                                    setGalleryIdx(i + 1)
                                                    $('.cmp-card').removeClass('selected')
                                                    if (c.type !== 'artifact') {
                                                        setDragTargets(undefined)
                                                        return;
                                                    }
                                                    $(e.target).closest('.cmp-card').toggleClass('selected')
                                                    setDragTargets(allReapers(reapers, `${username}_${j}`))
                                                    setCardParams({
                                                        ...params, cardState: 1, chosen: {
                                                            username,
                                                            reaperIdx: j
                                                        }
                                                    })
                                                }} />
                                            })}
                                        </Cards>
                                    })
                                })}
                                <Buttons>
                                    <Button onClick={() => {
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 1:
                    if (selectedEle.hasClass('reaper')) {
                        if (!params.chosen) return
                        const [toUsername, toReaperIdx] = selectedEle.attr('id').split('_')
                        const { username, reaperIdx } = params.chosen
                        reapers[toUsername][parseInt(toReaperIdx)].field.push(
                            ...reapers[username][reaperIdx].field.splice(galleryIdx - 1, 1)
                        )
                        hands[casterUsername].splice(cardIdx, 1)
                        setReapers({ ...reapers })
                        setGallery([])
                        setModalContent(undefined)
                        setTurnState('discard')
                        setHands({ ...hands })
                        setCardParams({})
                    }
                    break;
            }
            break;
        case 'heavy-rotation':
            if (dryRun) return true
            let usernames = Object.keys(reapers).filter((username) => {
                return reapers[username].filter(r => {
                    return r.field.filter(c => {
                        return c.type === 'spirit'
                    }).length > 0
                }).length > 0
            })
            while (usernames[0] !== casterUsername) usernames = arrayRotate(usernames)
            for (let i = 0; i < usernames.length - 1; i++) {
                if (!usernames[i + 1]) continue
                const randomIdx = Math.floor(Math.random() * hands[usernames[i + 1]].length)
                hands[usernames[i]].push(...hands[usernames[i + 1]].splice(randomIdx, 1))
            }
            setHands({ ...hands })
            setGallery([])
            setCardParams({})
            consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
            setHint(intl.get('HINT.cards_rotated'))
            setTurnState('discard')
            break;
        case 'hell-fire':
            if (dryRun) return true
            consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
            setCardParams({ ...params, cardState: 1 })
            reapers[targetUsername][targetReaperIdx].damage += 2;
            reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                username: casterUsername,
                reaperIdx: casterReaperIdx,
            };
            setReapers({ ...reapers })
            setTurnState('cause-damage')
            setCardParams({
                ...params, afterState: () => {
                    setTurnState('discard')
                    setCardParams({})
                }
            })
            break;
        case 'jacko-hammer':
            if (dryRun) return Object.keys(reapers).filter((username) => {
                return reapers[username].filter(r => {
                    return r.field.filter(c => {
                        return c.type === 'artifact' && (
                            (username === casterUsername && c.sub_type === 'curse') ||
                            (username !== casterUsername && c.sub_type === 'charm')
                        )
                    }).length > 0
                }).length > 0
            }).length > 0
            if (reapers[casterUsername][casterReaperIdx].computer) {
                let chosenCard
                Object.keys(reapers).map((username) => {
                    reapers[username].map((r, reaperIdx) => {
                        if (chosenCard) return
                        r.field.map((c, cardIdx) => {
                            if (chosenCard) return
                            if (c.type === 'artifact') {
                                if (username === casterUsername && c.sub_type === 'curse') {
                                    chosenCard = { ...c, username, reaperIdx, cardIdx }
                                } else if (username !== casterUsername && c.sub_type === 'charm') {
                                    chosenCard = { ...c, username, reaperIdx, cardIdx }
                                }
                            }
                        })
                    })
                })

                const { username, reaperIdx, cardIdx } = chosenCard
                graveyard.push(...reapers[username][reaperIdx].field.splice(cardIdx, 1))
                setGraveyard([...graveyard])
                setReapers({ ...reapers })
                setTurnState('discard')
                setHint(intl.get('HINT.card_destroyed', {
                    user: username
                }))
                return
            }
            setDragAndDropReminder(false)
            setGalleryIdx(0)
            setGallery([targetCard, ...reapers[targetUsername][targetReaperIdx].field])
            if (applyEffects({ effects, graveyard, setGraveyard, reapers, setReapers, casterUsername, casterReaperIdx, targetUsername, targetReaperIdx, setHint })) {
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                setTurnState('discard')
                setModalContent(undefined)
                setGallery([])
                return
            }
            setModalContent(
                <Modal>
                    <ModalBottom>
                        <Cards>
                            <Card key={-1} card={targetCard} onClick={() => {
                                setGalleryIdx(0)
                            }}></Card>
                            {reapers[targetUsername][targetReaperIdx].field.map((c, i) => {
                                return <Card className={c.type === 'artifact' ? 'glow' : ''} key={i} card={c} i={i} onClick={(e) => {
                                    setGalleryIdx(i + 1)
                                    $('.cmp-card').removeClass('selected')
                                    if (c.type !== 'artifact') return;
                                    $(e.target).closest('.cmp-card').addClass('selected')
                                }} />
                            })}
                        </Cards>
                        <Buttons>
                            <Button onClick={() => {
                                const eles = $('.selected')
                                if (eles.length !== 1) {
                                    setHint(intl.get('HINT.select_artifact'))
                                    return
                                }
                                const idx = JSON.parse(eles[0].dataset.meta).idx;
                                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                graveyard.push(...reapers[targetUsername][targetReaperIdx].field.splice(idx, 1))
                                setGraveyard([...graveyard])
                                setReapers({ ...reapers })
                                setModalContent(undefined)
                                setGallery([])
                                setTurnState('discard')
                            }}>{intl.get('ACTIONS.destroy')}</Button>
                            <Button onClick={() => {
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'lucky-drop':
            if (dryRun) return true
            const newDrawnCards = deck.splice(0, Math.min(deck.length, 2));
            setDeck([...deck]);
            const deckPos = document.getElementById('deck')?.getBoundingClientRect();
            const handPos = document.getElementById(`${casterUsername}_${casterReaperIdx}`)?.getBoundingClientRect();
            setCardMovePos({ startX: deckPos.x, startY: deckPos.y, endX: handPos.x + handPos.width / 2 - 60, endY: handPos.y })
            setMovingCard('start')
            setGallery([])
            setModalContent(undefined)
            graveyard.push(...hands[casterUsername].splice(galleryIdx, 1))
            setHands({ ...hands })
            setGraveyard([...graveyard])
            setOnCardMoveEnd(() => () => {
                hands[casterUsername].push(...newDrawnCards)
                setHands({ ...hands })
                setOnCardMoveEnd(false)
                setTurnState('discard')
            })
            setHint(intl.get('HINT.drawn_2', { user: casterUsername }))
            break
        case 'magic-slap':
            if (dryRun) return Object.keys(hands).filter((username) => {
                return hands[username].length > 0
            }).length > 0
            if (reapers[casterUsername][casterReaperIdx].computer) {
                const username = randomElement(Object.keys(hands).filter((username) => {
                    return username !== casterUsername
                }))
                graveyard.push(...hands[username].splice(Math.floor(Math.random() * hands[username].length), 1))
                setGraveyard([...graveyard])
                setHands({ ...hands })
                setTurnState('discard')
                setHint(intl.get('HINT.card_destroyed', {
                    user: username
                }))
                return
            }
            setDragAndDropReminder(false)
            setGalleryIdx(0)
            setGallery([])
            if (applyEffects({ effects, graveyard, setGraveyard, reapers, setReapers, casterUsername, casterReaperIdx, targetUsername, targetReaperIdx, setHint })) {
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                setTurnState('discard')
                setModalContent(undefined)
                setGallery([])
                return
            }
            setModalContent(
                <Modal>
                    <ModalBottom>
                        {handChoices.map((user, i) => {
                            if (hands[user.username].length === 0) return
                            return <div key={i}>
                                <Label $emphasize={casterUsername === user.username}>{user.username} ({hands[user.username].length} {intl.get('CARDS_IN_HAND')})</Label>
                                <Cards>
                                    {user.hand.map((c, j) => {
                                        c.username = user.username
                                        c.idx = j
                                        return user.username === casterUsername ?
                                            <Card key={j} card={c} i={-1} /> :
                                            <CardBack key={j} $selectable $size="small" className="glow" onClick={(e) => {
                                                $('.cmp-card-back').removeClass("selected")
                                                $(e.target).closest('.cmp-card-back').toggleClass("selected");
                                            }} card={c} />
                                    })}
                                </Cards>
                            </div>
                        })}
                        <Buttons>
                            <Button onClick={() => {
                                const eles = $('.selected')
                                if (eles.length !== 1) {
                                    setHint(intl.get('HINT.destroy_card'))
                                    return
                                }
                                const meta = JSON.parse(eles[0].dataset.meta);
                                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                graveyard.push(...hands[meta.username].splice(meta.idx, 1))
                                setGraveyard([...graveyard])
                                setHands({ ...hands })
                                setModalContent(undefined)
                                setGallery([])
                                setTurnState('discard')
                            }}>{intl.get('ACTIONS.destroy')}</Button>
                            <Button onClick={() => {
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'marys-magnet':
            if (dryRun) return graveyard.filter(c => c.type === 'artifact').length > 0
            if (graveyard.filter(c => c.type === 'artifact').length === 0) {
                setHint(intl.get('HINT.no_artifact_in_graveyard'))
                return
            }
            if (reapers[casterUsername][casterReaperIdx].computer) {
                graveyard.forEach((ele, idx) => ele.idx = idx)
                const artifacts = shuffle(graveyard.filter(c => c.type === 'artifact'))
                graveyard.splice(artifacts[0].idx, 1)
                graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                hands[casterUsername].push({ ...artifacts[0] })
                setGraveyard([...graveyard])
                setHands({ ...hands })
                setTurnState('discard')
                setHint(intl.get('HINT.cards_from_grave', {
                    user: casterUsername
                }))
                return
            }
            setDragAndDropReminder(false)
            setGalleryIdx(0)
            setGallery([...graveyard])
            setModalContent(
                <Modal>
                    <ModalBottom>
                        <Cards>
                            {graveyard.map((c, j) => {
                                c.idx = j
                                return <Card className={c.type === 'artifact' ? "glow" : ""} key={j} card={c} i={j} onClick={(e) => {
                                    $('.cmp-card').removeClass("selected")
                                    if (c.type !== 'artifact') return
                                    $(e.target).closest('.cmp-card').toggleClass("selected");
                                    setGalleryIdx(j)
                                }} />
                            })}
                        </Cards>
                        <Buttons>
                            <Button onClick={() => {
                                const eles = $('.selected')
                                if (eles.length !== 1) {
                                    setHint(intl.get('HINT.select_artifact'))
                                    return
                                }
                                const meta = JSON.parse(eles[0].dataset.meta);
                                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                hands[casterUsername].push(...graveyard.splice(meta.idx, 1))
                                setGraveyard([...graveyard])
                                setHands({ ...hands })
                                setModalContent(undefined)
                                setGallery([])
                                setTurnState('discard')
                            }}>{intl.get('ACTIONS.take')}</Button>
                            <Button onClick={() => {
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'mercenarys-money':
            if (dryRun) return true
            if (reapers[casterUsername][casterReaperIdx].computer) {
                switch (cardState) {
                    case 0:
                        const randUsername = randomElement(Object.keys(reapers).filter(username => {
                            return username !== casterUsername
                        }))
                        const randReaperIdx = Math.floor(Math.random() * reapers[randUsername].length);
                        const reaperKey = reapers[randUsername][randReaperIdx].key;
                        const skillIdx = Math.floor(Math.random() * 3)
                        const skillText = intl.get('CARDS.reaper')[reaperKey].skills[skillIdx]
                        setCardParams({
                            ...params,
                            skillState: 0, cardState: 1, skillIdx, reaperKey,
                            nonSkillSideEffect: true, targetUsername: randUsername, targetReaperIdx: randReaperIdx,
                            applyCharmingEffectsToCaster: true,
                        })
                        setHint(intl.get('HINT.others_skill_used', { user: casterUsername, other: randUsername, skill_name: skillText.name, skill_desc: skillText.desc }))
                        break
                    case 1:
                        applySkill(params)
                        break
                }
                return
            }
            switch (cardState) {
                case 0:
                    const [username, reaperIdx] = selectedEle.attr('id').split('_')
                    setDragAndDropReminder(false)
                    setGalleryIdx(0)
                    setGallery([])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                {selectedEle.hasClass('reaper') ? <SkillChoices
                                    className="glow"
                                    reaper={reapers[username][reaperIdx].key}
                                    onClick={(e) => {
                                        $('.cmp-choice').removeClass('selected')
                                        $(e.target).closest('.cmp-choice').toggleClass('selected')
                                    }}
                                /> : ""}
                                <Buttons>
                                    <Button onClick={() => {
                                        const eles = $('.selected')
                                        if (eles.length !== 1) {
                                            setHint(intl.get('HINT.select_skill'))
                                            return
                                        }
                                        const meta = JSON.parse(eles[0].dataset.meta);
                                        setCardParams({ ...params, cardState: 1, skillIdx: meta.skillIdx, reaperKey: meta.reaper })
                                    }}>{intl.get('ACTIONS.choose')}</Button>
                                    <Button onClick={() => {
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 1:
                    const targets = getReaperSkillTargets[reaperKey]({ reapers, username: casterUsername, skillIdx, cardState, orbReaperIdx: casterReaperIdx })
                    setDragAndDropReminder(false)
                    setGalleryIdx(0)
                    setGallery([])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <TargetChoices targets={targets} reapers={reapers} />
                                <Buttons>
                                    <Button onClick={() => {
                                        const eles = $('.selected')
                                        if (eles.length !== 1) {
                                            setHint(intl.get('HINT.select_skill'))
                                            return
                                        }
                                        const meta = JSON.parse(eles[0].dataset.meta);
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({ ...params, skillState: 0, cardState: 2, nonSkillSideEffect: true, targetUsername: meta.username || meta.id, targetReaperIdx: meta.idx })
                                    }}>{intl.get('ACTIONS.choose')}</Button>
                                    <Button onClick={() => {
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 2:
                    applySkill(params)
                    break
            }
            break
        case 'phantom-blade':
            if (dryRun) return true
            if (reapers[casterUsername][casterReaperIdx].computer) {
                const card = randomElement(reapers[targetUsername][targetReaperIdx].field.filter(c => c.type === 'spirit'))
                graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                graveyard.push(...reapers[targetUsername][targetReaperIdx].field.splice(card.cardIdx, 1))
                setGraveyard([...graveyard])
                setReapers({ ...reapers })
                setTurnState('discard')
                setCardParams({})
                setGallery([])
                setModalContent(undefined)
                return
            }
            const reaperCard = {
                type: "reaper",
                key: reapers[targetUsername][targetReaperIdx].key
            }
            setDragAndDropReminder(false)
            setGallery([reaperCard, ...reapers[targetUsername][targetReaperIdx].field])
            setGalleryIdx(0)
            setModalContent(
                <Modal>
                    <ModalBottom>
                        <Cards>
                            <Card key={-1} i={-1} card={reaperCard} onClick={() => {
                                setGalleryIdx(0)
                            }}></Card>
                            {reapers[targetUsername][targetReaperIdx].field.map((c, i) => {
                                c.username = targetUsername
                                c.reaperIdx = targetReaperIdx
                                c.cardIdx = i
                                return <Card key={i} card={c} i={i} className={c.type === 'spirit' ? 'glow' : ''} onClick={(e) => {
                                    setGalleryIdx(1 + i)
                                    if (c.type !== 'spirit') return
                                    $('.cmp-card.selected').removeClass('selected')
                                    $(e.target).closest('.cmp-card').addClass('selected')
                                }} />
                            })}
                        </Cards>
                        <Buttons>
                            <Button onClick={() => {
                                if ($('.selected.cmp-card').length !== 1) {
                                    setHint(intl.get('HINT.select_spirit'))
                                    return
                                }
                                const meta = JSON.parse($('.selected.cmp-card')[0].dataset.meta)
                                graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                                graveyard.push(...reapers[targetUsername][targetReaperIdx].field.splice(meta.cardIdx, 1))
                                setGraveyard([...graveyard])
                                setReapers({ ...reapers })
                                setTurnState('discard')
                                setCardParams({})
                                setGallery([])
                                setModalContent(undefined)
                            }}>{intl.get('ACTIONS.attack')}</Button>
                            <Button onClick={() => {
                                setGalleryIdx(0)
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'roulette-eye':
            if (dryRun) return handChoices.length > 0
            if (reapers[casterUsername][casterReaperIdx].computer) {
                const username = randomElement(Object.keys(hands).filter((username) => {
                    return username !== casterUsername
                }))
                hands[casterUsername].push(...hands[username].splice(Math.floor(Math.random() * hands[username].legnth), 1))
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                setHands({ ...hands })
                setTurnState('discard')
                setHint(intl.get('HINT.card_moved', {
                    from: username,
                    to: casterUsername
                }))
                return
            }
            setDragAndDropReminder(false)
            setGalleryIdx(0)
            setGallery([])
            setModalContent(
                <Modal>
                    <ModalBottom>
                        {handChoices.map((user, i) => {
                            if (hands[user.username].length === 0) return
                            return <div key={i}>
                                <Label $emphasize={casterUsername === user.username}>{user.username} ({hands[user.username].length} {intl.get('CARDS_IN_HAND')})</Label>
                                <Cards>
                                    {user.hand.map((c, j) => {
                                        c.username = user.username
                                        c.idx = j
                                        return user.username === casterUsername ?
                                            <Card key={j} card={{ ...c }} i={-1} /> :
                                            <CardBack key={j} card={{ ...c }} $selectable $size="small" className="glow" onClick={(e) => {
                                                $('.cmp-card-back').removeClass("selected")
                                                $(e.target).closest('.cmp-card-back').toggleClass("selected");
                                            }} data-meta={JSON.stringify({
                                                username: user.username,
                                                idx: j
                                            })} />
                                    })}
                                </Cards>
                            </div>
                        })}
                        <Buttons>
                            <Button onClick={() => {
                                const eles = $('.selected')
                                if (eles.length !== 1) {
                                    setHint(intl.get('HINT.steal_card'))
                                    return
                                }
                                const meta = JSON.parse(eles[0].dataset.meta);
                                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                hands[casterUsername].push(...hands[meta.username].splice(meta.idx, 1))
                                setGraveyard([...graveyard])
                                setHands({ ...hands })
                                setModalContent(undefined)
                                setGallery([])
                                setTurnState('discard')
                            }}>{intl.get('ACTIONS.steal')}</Button>
                            <Button onClick={() => {
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'bad-guru':
            if (dryRun) {
                let hasSpirit
                Object.keys(reapers).map(username => {
                    if (hasSpirit) return
                    reapers[username].map(r => {
                        if (hasSpirit) return
                        r.field.map(c => {
                            if (c.type === 'spirit')
                                hasSpirit = true
                        })
                    })
                })
                return hasSpirit
            }
            if (reapers[casterUsername][casterReaperIdx].computer) {
                let moved
                Object.keys(reapers).map(username => {
                    reapers[username].map((r, reaperIdx) => {
                        r.field.map((c, cardIdx) => {
                            if (moved) return
                            if (c.type === 'spirit') {
                                reapers[casterUsername][casterReaperIdx].field.push(...reapers[username][reaperIdx].field.splice(cardIdx, 1))
                                moved = [username, casterUsername]
                            }
                        })
                    })
                })
                setHint(intl.get('HINT.card_moved', {
                    from: moved[0],
                    to: moved[1]
                }))
                setReapers({ ...reapers })
                setTurnState('discard')
                return
            }
            switch (cardState) {
                case 0:
                    setDragTargets(undefined)
                    setCardParams({ ...params, cardState: 1 })
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                {Object.keys(reapers).map(username => {
                                    return reapers[username].map((r, j) => {
                                        const reaperCard = {
                                            type: "reaper",
                                            key: r.key
                                        }
                                        return <Cards key={j}>
                                            <Card card={reaperCard} i={-1} onClick={() => {
                                                setGallery([reaperCard, ...r.field])
                                                setGalleryIdx(0)
                                            }} />
                                            {r.field.map((c, i) => {
                                                c.username = username
                                                c.reaperIdx = j
                                                c.cardIdx = i
                                                return <Card className={c.type === 'spirit' ? 'glow' : ''} key={i} card={c} i={i} onClick={(e) => {
                                                    setGallery([reaperCard, ...r.field])
                                                    setGalleryIdx(i + 1)
                                                    $('.cmp-card').removeClass('selected')
                                                    if (c.type !== 'spirit') {
                                                        setDragTargets(undefined)
                                                        return;
                                                    }
                                                    $(e.target).closest('.cmp-card').toggleClass('selected')
                                                    setDragTargets(allReapers(reapers, `${username}_${j}`))
                                                    setCardParams({
                                                        ...params, cardState: 1, chosen: {
                                                            username,
                                                            reaperIdx: j
                                                        }
                                                    })
                                                }} />
                                            })}
                                        </Cards>
                                    })
                                })}
                                <Buttons>
                                    <Button onClick={() => {
                                        setGallery([])
                                        setModalContent(undefined)
                                        setCardParams({})
                                    }}>{intl.get('ACTIONS.back')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
                case 1:
                    if (selectedEle.hasClass('reaper')) {
                        if (!params.chosen) return
                        const { username, reaperIdx } = params.chosen
                        const [toUsername, toReaperIdx] = selectedEle.attr('id').split('_')
                        if (!validateMaxSpiritsRule(reapers[toUsername][parseInt(toReaperIdx)].field, reapers[username][reaperIdx].field[galleryIdx - 1])) {
                            setHint(intl.get('HINT.spirits_max'))
                            return
                        }
                        reapers[toUsername][parseInt(toReaperIdx)].field.push(
                            ...reapers[username][reaperIdx].field.splice(galleryIdx - 1, 1)
                        )
                        hands[casterUsername].splice(cardIdx, 1)
                        setReapers({ ...reapers })
                        setGallery([])
                        setModalContent(undefined)
                        setTurnState('discard')
                        setHands({ ...hands })
                        setCardParams({})
                    }
                    break;
            }
            break;
        case 'summon-em':
            if (dryRun) return graveyard.filter(c => c.type === 'spirit').length > 0
            if (reapers[casterUsername][casterReaperIdx].computer) {
                graveyard.forEach((ele, idx) => ele.idx = idx)
                const spirits = shuffle(graveyard.filter(c => c.type === 'spirit'))
                graveyard.splice(spirits[0].idx, 1)
                graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
                if (reapers[casterUsername][casterReaperIdx].field.filter(c => c.type === 'spirit').length < 3) {
                    reapers[casterUsername][casterReaperIdx].field.push({ ...spirits[0] })
                    setReapers({ ...reapers })
                } else {
                    hands[casterUsername].push({ ...spirits[0] })
                }
                setGraveyard([...graveyard])
                setHands({ ...hands })
                setTurnState('discard')
                setHint(intl.get('HINT.cards_from_grave', {
                    user: casterUsername
                }))
                return
            }
            switch (cardState) {
                case 0:
                    const spiritCards = graveyard.filter((ele) => ele.type === 'spirit')
                    if (spiritCards.length === 0) {
                        setHint(intl.get('HINT.empty_graveyard'))
                        return
                    }
                    setGraveyard(graveyard.map((ele, i) => {
                        ele.idx = i
                        return ele
                    }))
                    setGalleryIdx(0)
                    setGallery([...spiritCards])
                    setDragTargets([
                        `#${casterUsername}_${casterReaperIdx}`,
                        '#your-hand'
                    ])
                    setCardParams({ ...params, cardState: 1 })
                    break
                case 1:
                    if (selectedEle.attr('id') === 'your-hand') {
                        hands[casterUsername].push({ ...gallery[galleryIdx] })
                        setHands({ ...hands })
                    } else if (selectedEle.hasClass('reaper')) {
                        if (!validateMaxSpiritsRule(reapers[casterUsername][casterReaperIdx].field, gallery[galleryIdx])) {
                            setHint(intl.get('HINT.spirits_max'))
                            return
                        }
                        reapers[casterUsername][casterReaperIdx].field.push({ ...gallery[galleryIdx] })
                        setReapers({ ...reapers })
                    } else break
                    graveyard.splice(gallery[galleryIdx].idx, 1)
                    setGraveyard([...graveyard])
                    setGallery([])
                    setModalContent(undefined)
                    setTurnState('discard')
                    setCardParams({})
                    break
            }
            break
        case 'table-has-turned':
            if (dryRun) return Object.keys(reapers).filter(username => {
                return reapers[username].filter(r => {
                    return r.field.filter(c => {
                        return c.type === 'artifact'
                    }).length > 0
                }).length > 0
            }).length > 0
            if (reapers[casterUsername][casterReaperIdx].computer) {
                const randUsername = randomElement(Object.keys(hands).filter((u) => {
                    return u !== casterUsername
                }))
                const yourReaperIdx = Math.floor(Math.random() * reapers[casterUsername].length);
                const randReaperIdx = Math.floor(Math.random() * reapers[randUsername].length);
                const yourReaperField = [...reapers[casterUsername][yourReaperIdx].field];
                const randReaperField = [...reapers[randUsername][randReaperIdx].field];
                reapers[casterUsername][yourReaperIdx].field = [...randReaperField.filter(ele => ele.type === 'artifact'), ...yourReaperField.filter(ele => ele.type === 'spirit')]
                reapers[randUsername][randReaperIdx].field = [...yourReaperField.filter(ele => ele.type === 'artifact'), ...randReaperField.filter(ele => ele.type === 'spirit')]
                setReapers({ ...reapers })
                setCardParams({})
                setTurnState('discard')
                setHint(intl.get('HINT.artifacts_swapped', {
                    user: casterUsername,
                    target: randUsername
                }))
                return
            }
            const [selectedUsername, selectedReaperIdx] = selectedEle.attr('id').split('_')
            setDragAndDropReminder(false)
            setGallery([])
            setModalContent(
                <Modal>
                    <ModalBottom>
                        {reaperChoices.map((user, i) => {
                            if (reapers[user.username].length === 0) return
                            return <div key={i}>
                                <Label $emphasize={casterUsername === user.username}>{user.username}</Label>
                                {reapers[user.username].map((r, j) => {
                                    const reaperCard = {
                                        type: "reaper",
                                        key: r.key
                                    }
                                    return <Cards className={`cmp-cards ${user.username === selectedUsername ? "selected" : "glow"}`} onClick={e => {
                                        if (user.username === selectedUsername) return;
                                        $(e.target).closest('.cmp-cards').toggleClass("selected");
                                    }} data-meta={JSON.stringify({
                                        username: user.username,
                                        field: [...r.field],
                                        idx: j,
                                    })}>
                                        <Card card={reaperCard} i={-1} onClick={() => {
                                            setGallery([reaperCard, ...r.field])
                                            setGalleryIdx(0)
                                        }} />
                                        {r.field.map((c, j) => {
                                            return <Card key={j} card={c} i={-1} />
                                        })}
                                    </Cards>
                                })}
                            </div>
                        })}
                        <Buttons>
                            <Button onClick={() => {
                                const eles = $('.selected')
                                if (eles.length !== 2) {
                                    setHint(intl.get('HINT.swap_hands'))
                                    return
                                }
                                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                const meta0 = JSON.parse(eles[0].dataset.meta);
                                const meta1 = JSON.parse(eles[1].dataset.meta);
                                reapers[meta0.username][meta0.idx].field = [...meta1.field.filter(ele => ele.type === 'artifact'), ...meta0.field.filter(ele => ele.type === 'spirit')]
                                reapers[meta1.username][meta1.idx].field = [...meta0.field.filter(ele => ele.type === 'artifact'), ...meta1.field.filter(ele => ele.type === 'spirit')]
                                setReapers({ ...reapers })
                                setModalContent(undefined)
                                setGallery([])
                                setTurnState('discard')
                            }}>{intl.get('ACTIONS.swap')}</Button>
                            <Button onClick={() => {
                                setGallery([])
                                setModalContent(undefined)
                                setCardParams({})
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </Buttons>
                    </ModalBottom>
                </Modal>
            )
            break
        case 'third-eye':
            if (dryRun) return true
            if (reapers[casterUsername][casterReaperIdx].computer) {
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                const drawnCards = shuffle(deck.slice(deck.length - Math.min(3, deck.length), deck.length));
                hands[casterUsername].push(drawnCards[0])
                deck.push(...drawnCards.slice(1, 3))
                setHands({ ...hands })
                setDeck([...deck])
                setCardParams({})
                setTurnState('discard')
                return
            }
            switch (cardState) {
                case 0:
                    const drawnCards = deck.slice(deck.length - Math.min(3, deck.length), deck.length);
                    setGalleryIdx(0)
                    setGallery([...drawnCards])
                    setModalContent(
                        <Modal>
                            <ModalBottom>
                                <Cards className="drawn-cards">
                                    {drawnCards.map((c, i) => {
                                        return <Card key={i} card={c} i={i}
                                            onClick={(e) => {
                                                const ele = $(e.target).closest('.cmp-card');
                                                if (ele.hasClass('selected')) {
                                                    for (let sIdx = 0; sIdx < selectedCards.length; sIdx++) {
                                                        if (selectedCards[sIdx] === i)
                                                            selectedCards.splice(sIdx, 1)
                                                    }
                                                } else {
                                                    if (selectedCards?.length === 2) return
                                                    selectedCards.push(i)
                                                }
                                                $('.drawn-cards .cmp-card .card-number').html('')
                                                $('.drawn-cards .cmp-card').each((idx, cardEle) => {
                                                    for (let sIdx = 0; sIdx < selectedCards.length; sIdx++) {
                                                        if (selectedCards[sIdx] === idx)
                                                            $(cardEle).find('.card-number').html(`<div>${sIdx + 1}</div>`)
                                                    }
                                                })
                                                setCardParams({ ...params, selectedCards: [...selectedCards] })
                                                ele.toggleClass('selected')
                                                setGalleryIdx(i)
                                            }}></Card>
                                    })}
                                </Cards>
                                <Buttons>
                                    <Button onClick={() => {
                                        if (selectedCards.length !== 2) {
                                            setHint(intl.get('HINT.replace_cards'))
                                            return
                                        }
                                        hands[casterUsername].splice(cardIdx, 1);
                                        deck.splice(deck.length - 3, 3);
                                        selectedCards.map((idx) => {
                                            deck.push({ ...drawnCards[idx] })
                                        })
                                        selectedCards.sort().map((idx, i) => {
                                            drawnCards.splice(idx - i, 1)
                                        })
                                        hands[casterUsername].push({ ...drawnCards[0] })
                                        consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
                                        setDeck([...deck])
                                        setHands({ ...hands })
                                        setModalContent(undefined)
                                        setGallery([])
                                        setTurnState('discard')
                                    }}>{intl.get('ACTIONS.return')}</Button>
                                </Buttons>
                            </ModalBottom>
                        </Modal>
                    )
                    break
            }
            break
        case 'thunder-bolt':
            if (dryRun) return true
            consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
            reapers[targetUsername][targetReaperIdx].damage += 1;
            reapers[targetUsername][targetReaperIdx].discard += 1;
            reapers[targetUsername][targetReaperIdx].damageCausedBy = {
                username: casterUsername,
                reaperIdx: casterReaperIdx,
            };
            setReapers({ ...reapers })
            setTurnState('cause-damage')
            setCardParams({
                ...params, afterState: () => {
                    setTurnState('discard')
                    setCardParams({})
                }
            })
            break
        case 'everdream':
        case 'magic-mirror':
            break
        default:
            if (dryRun) return true
            if (selectedEle.hasClass('reaper')) {
                const [username, reaperIdx] = selectedEle.attr('id').split('_')
                reapers[username][parseInt(reaperIdx)]?.field.push(originalCard)
                hands[casterUsername].splice(cardIdx, 1)
                setHands({ ...hands })
                setReapers({ ...reapers })
                setGallery([])
            } else if (selectedEle.attr('id') === 'graveyard') {
                consumeCard({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery })
            }
            setTurnState('discard')
    }
}

const consumeCard = ({ graveyard, setGraveyard, casterUsername, cardIdx, hands, setHands, setGallery }) => {
    graveyard.push(...hands[casterUsername].splice(cardIdx, 1))
    setHands({ ...hands })
    setGallery([])
    setGraveyard([...graveyard])
}

const selfReapers = (reapers, username) => {
    const targets = [];
    for (let i = 0; i < reapers[username].length; i++) {
        targets.push(`#${username}_${i}`)
    }
    return targets
}

const opponentReapers = (reapers, username) => {
    const targets = [];
    Object.keys(reapers).map((otherUsername) => {
        if (username === otherUsername) return
        for (let i = 0; i < reapers[otherUsername].length; i++) {
            targets.push(`#${otherUsername}_${i}`)
        }
    })
    return targets
}

const allReapers = (reapers, exceptId) => {
    const targets = [];
    Object.keys(reapers).map((otherUsername) => {
        for (let i = 0; i < reapers[otherUsername].length; i++) {
            if (exceptId === `${otherUsername}_${i}`) continue
            targets.push(`#${otherUsername}_${i}`)
        }
    })
    return targets
}

export const getArtifactTargets = ({ reapers, username, card, turnState }) => {
    if (turnState === 'interrupt') {
        switch (card.key) {
        }
        return []
    }
    let override
    Object.keys(reapers).map((username) => {
        reapers[username].map((reaper) => {
            reaper.field.map((c) => {
                switch (c.key) {
                    case 'seal-of-disapproval':
                        override = allReapers(reapers)
                        break
                }
            })
        })
    })
    if (override) return override
    switch (card.key) {
        case "everdream":
        case "magic-mirror":
            return ['none']
        case "seal-of-disapproval":
        case "exorcise-exercise":
        case "jacko-hammer":
        case "table-has-turned":
            return allReapers(reapers)
        case "mercenarys-money":
        case "phantom-blade":
        case "hell-fire":
        case "frostbite":
        case "thunder-bolt":
            return opponentReapers(reapers, username)
    }
    switch (card.sub_type) {
        case "charm":
            return selfReapers(reapers, username)
        case "curse":
            return opponentReapers(reapers, username)
        case "spell":
            return ['#graveyard']
    }
}


const gatherEffects = ({ targetUsername, targetReaperIdx, casterUsername, casterReaperIdx, targetField, casterField, card, nonSkillSideEffect }) => {
    if (nonSkillSideEffect) return []
    return [...casterField.map((c, i) => {
        let effect = { cardIdx: i, username: casterUsername, reaperIdx: parseInt(casterReaperIdx) }
        switch (c.key) {

        }
        return effect
    }), ...targetField.map((c, i) => {
        let effect = { key: c.key, cardIdx: i, username: targetUsername, reaperIdx: parseInt(targetReaperIdx) }
        switch (c.key) {
            case 'seal-of-disapproval':
                effect.skipPlayOrDraw = true
                break
            case 'blade-crusher':
                effect.destroyInstead = true
                c.damageReduced = 99
                break
        }
        return effect
    })]
}


const applyEffects = (props) => {
    const { effects, reapers, targetUsername, targetReaperIdx, casterUsername, casterReaperIdx, setHint } = props;
    let finishPlay = false
    effects.map((effect) => {
        let applied = false;
        if (effect.destroyInstead && casterUsername !== targetUsername) {
            reapers[targetUsername][targetReaperIdx].ignoreProtection = true
            applied = true
            finishPlay = true
            setHint(intl.get('HINT.destroyed_instead', { card: intl.get('CARDS.artifact')[effect.key].name }))
        }
        if (applied) consumeFieldCard({ effect, ...props })
    })
    return finishPlay
}


const consumeFieldCard = ({ effect, graveyard, setGraveyard, reapers, setReapers }) => {
    graveyard.push(...reapers[effect.username][effect.reaperIdx].field.splice(effect.cardIdx, 1))
    setReapers({ ...reapers })
    setGraveyard([...graveyard])
}

export const getTrapCardsFromHand = (hand) => {
    return hand.filter((c) => {
        switch (c.key) {
            case 'magic-mirror':
                return true
        }
    })
}

export const applyTrapEffects = ({ selfPlayerName, currPlayer, players, setCurrPlayer, username, idx, hands, setHands, reapers, setReapers, graveyard, setGraveyard, setHint, turnState, setTurnState, setPlayedCard, cardParams, setCardParams, consumedCards }) => {
    if (turnState === 'trap-activated') return
    const reaper = reapers[username][idx]
    for (let i = 0; i < hands[username].length; i++) {
        switch (hands[username][i]?.key) {
            case 'everdream':
                if (reaper.field.filter(c => c.type === 'spirit').length !== 0) return
                displayTrapCard({
                    card: hands[username][i], turnState, setTurnState, setPlayedCard, cardParams, setCardParams, onComplete: () => {
                        const idxs = []
                        let newGraveyardRev = [...graveyard, ...consumedCards].reverse()
                        newGraveyardRev.map((c, j) => {
                            if (c.type === 'spirit' && idxs.length < 2)
                                idxs.push(j)
                        })
                        idxs.map((j, k) => {
                            reapers[username][idx].field.push(...newGraveyardRev.splice(j - k, 1))
                        })
                        newGraveyardRev = newGraveyardRev.reverse()
                        newGraveyardRev.push(...hands[username].splice(i, 1))
                        setHands({ ...hands })
                        setReapers({ ...reapers })
                        setGraveyard([...newGraveyardRev])
                        setHint(intl.get('HINT.revived', { user: username }))
                        setTurnState('discard')
                    }
                })
                return true
            case 'magic-mirror':
                if (!reaper.damageCausedBy || selfPlayerName === username) return
                displayTrapCard({
                    card: hands[username][i], turnState, setTurnState, setPlayedCard, cardParams, setCardParams, onComplete: () => {
                        setHint(intl.get('HINT.damage_reflected', { user: username, damage: reaper.damage }))
                        reapers[reaper.damageCausedBy.username][reaper.damageCausedBy.reaperIdx].damage = reaper.damage
                        reapers[username][idx].damage = 0
                        reapers[reaper.damageCausedBy.username][reaper.damageCausedBy.reaperIdx].trapApplied = true
                        graveyard.push(...hands[username].splice(i, 1))
                        setReapers({ ...reapers })
                        setHands({ ...hands })
                        setGraveyard([...graveyard])
                        setCurrPlayer((currPlayer + 1) % players.length)
                    }
                })
                return true
        }
    }
}

let displayTrapCardTimeout

const displayTrapCard = ({ card, turnState, setTurnState, setPlayedCard, onComplete, cardParams, setCardParams }) => {
    card.show = true
    setPlayedCard({ ...card })
    setTurnState('trap-activated')
    if (displayTrapCardTimeout) clearTimeout(displayTrapCardTimeout)
    displayTrapCardTimeout = setTimeout(() => {
        if (turnState === 'interrupt') return
        onComplete()
        setPlayedCard(undefined)
        setCardParams({ ...cardParams })
    }, 2000)
}

export const cancelTrapCard = () => {
    if (displayTrapCardTimeout) clearTimeout(displayTrapCardTimeout)
}