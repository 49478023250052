import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components'
import intl from 'react-intl-universal'
import $ from 'jquery'

import { useAppContext } from "components/context";
import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'
import { useSafearea } from "components/page";

import LockedIcon from "assets/icons/lock_24px_outlined.svg"
import StarIcon from "assets/icons/star.png"
import VortexGif from 'assets/misc/vortex.gif'
import 'anim.css'
import { SettingsButton } from "components/settings";
import { Card, Cards } from "components/cards";
import { shuffle } from "lib/utils";
import { RateApp } from 'capacitor-rate-app';

import ButtonMP3 from 'assets/sounds/button.mp3'
import SelectMP3 from 'assets/sounds/select.mp3'
import ErrorMP3 from 'assets/sounds/error.mp3'
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

const Wrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: calc(100vh - ${({ offset }) => offset}px);
    text-align: center;
`

const Button = styled.div`
    font-size: 40px;
    margin: 0.5em auto;
    z-index: 1;
`

const LevelWrapper = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 60px;
    display: inline-block;
    margin: 0.5em;
    position: relative;
    cursor: pointer;
    background: #2f2f2f;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
`

const LevelText = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    text-shadow: 0 0 5px #000;
`

const Vortex = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 60px;
    position: absolute;
    left: 0;
`

const Lock = styled.img`
    width: 15px;
    height: 15px;
    padding: 5px;
    position: absolute;
    left: -5px;
    top: -12px;
    border-radius: 30px;
    background: #555;

    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
`

const Star = styled.img`
    width: 15px;
    height: 15px;
    padding: 5px;
    position: absolute;
    right: -5px;
    top: -12px;
    border-radius: 30px;
    background: #555;

    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
`

const LevelsWrapper = styled.div`
    height: 40vh;
    max-width: 600px;
    padding: 50px 1em;
    margin: auto;
    overflow: auto;
`

const BottomWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`

const ProgressWrapper = styled.div`
    font-size: 32px;
    padding-bottom: 0.75em;
    text-align: center;
    animation: shine 3s forwards;
`

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
`

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
`

const ModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`

const Padding = styled.div`
    height: 1em;
    width: 100%;
`

const Text = styled.div`
    font-size: 32px;
`

const UnlockablesWrapper = styled.div`
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    padding: 1em 0;
    border-top: solid 2px;
    border-bottom: solid 2px;
`

const startingSpirits = [
    {
        type: 'spirit',
        key: 'lesser-spirit'
    }, {
        type: 'spirit',
        key: 'lesser-spirit'
    }, {
        type: 'spirit',
        key: 'ordinary-spirit'
    },
]

const getChar = (key, computer, cards) => {
    return {
        key: key,
        field: [...startingSpirits, ...(cards || [])],
        damage: 0,
        discard: 0,
        computer
    }
}

export const CampaignsPage = () => {
    const navigate = useNavigate();
    const { acctUsername, isLoggedIn, setScene, level, setLevel, completedLevels, setSfx } = useAppContext();
    const { safearea } = useSafearea();
    const [selected, setSelected] = useState(-1)
    const [galleryIdx, setGalleryIdx] = useState(-1);
    const [completed, setCompleted] = useState({})

    const levels = [
        {
            name: 'learn the turns',
            reapers: {
                [acctUsername]: [getChar('hermona')],
                computer: [getChar('bracket-boy', true)]
            },
            hands: {
                [acctUsername]: [{ type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }],
                computer: [{ type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }]
            },
            deck: [{ type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }],
            newCards: [
                { type: 'orb', key: 'green-orb' },
                { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' },
            ],
            intro: true,
            showPrompts: true
        },
        {
            name: 'skills acquired',
            reapers: {
                [acctUsername]: [getChar('hermona')],
                computer: [getChar('bracket-boy', true)]
            },
            hands: {
                [acctUsername]: [{ type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'red-orb' }],
                computer: [{ type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'red-orb' }]
            },
            deck: [
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'orb', key: 'gold-orb' }, { type: 'orb', key: 'gold-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'blue-orb' },
            ],
            newCards: [
                { type: 'orb', key: 'blue-orb' },
                { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'gold-orb' }
            ],
            intro: true
        },
        {
            name: 'in a pinch',
            reapers: {
                [acctUsername]: [getChar('hermona')],
                computer: [getChar('bracket-boy', true)]
            },
            hands: {
                [acctUsername]: [{ type: 'orb', key: 'red-orb' }],
                computer: [{ type: 'orb', key: 'blue-orb' }]
            },
            deck: [
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'spirit', key: 'greater-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
            ],
            newCards: [
                { type: 'spirit', key: 'greater-spirit' },
            ],
            intro: true
        },
        {
            name: 'spirit exercise',
            reapers: {
                [acctUsername]: [getChar('hermona')],
                computer: [getChar('bracket-boy', true)]
            },
            hands: {
                [acctUsername]: [{ type: 'orb', key: 'red-orb' }],
                computer: []
            },
            deck: [
                { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'artifact', key: 'exorcise-exercise', sub_type: 'spell' }, { type: 'artifact', key: 'perfect-aim', sub_type: 'charm' },
                { type: 'spirit', key: 'half-alive-spirit' }, { type: 'orb', key: 'red-orb' },
            ],
            newCards: [
                { type: 'artifact', key: 'perfect-aim', sub_type: 'charm' },
                { type: 'artifact', key: 'exorcise-exercise', sub_type: 'spell' },
                { type: 'spirit', key: 'half-alive-spirit' },
            ],
            intro: true
        },
        {
            name: 'true test',
            reapers: {
                [acctUsername]: [getChar('hermona')],
                computer: [getChar('bracket-boy', true)]
            },
            hands: {
                [acctUsername]: [],
                computer: []
            },
            deck: [...shuffle([
                { type: 'artifact', key: 'anti-chip-damage-charm', sub_type: 'charm' },
                { type: 'artifact', key: 'seal-of-disapproval', sub_type: 'curse' },
                { type: 'artifact', key: 'hanging-wire', sub_type: 'spell' },
                { type: 'artifact', key: 'roulette-eye', sub_type: 'spell' },
                { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'gold-orb' }, { type: 'orb', key: 'gold-orb' },
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'ordinary-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'greater-spirit' },
            ])],
            newCards: [
                { type: 'reaper', key: 'hermona' },
                { type: 'artifact', key: 'anti-chip-damage-charm', sub_type: 'charm' },
                { type: 'artifact', key: 'seal-of-disapproval', sub_type: 'curse' },
                { type: 'artifact', key: 'hanging-wire', sub_type: 'spell' },
                { type: 'artifact', key: 'roulette-eye', sub_type: 'spell' },
            ],
            unlocks: [{
                key: 'hermona'
            }],
            intro: true,
            addStar: true,
        },
        {
            name: 'role reversal',
            reapers: {
                [acctUsername]: [getChar('bracket-boy')],
                computer: [getChar('hermona', true)]
            },
            hands: {
                [acctUsername]: [],
                computer: [{ type: 'spirit', key: 'half-alive-spirit' }]
            },
            deck: [...shuffle([
                { type: 'artifact', key: 'anti-chip-damage-charm', sub_type: 'charm' },
                { type: 'artifact', key: 'seal-of-disapproval', sub_type: 'curse' },
                { type: 'artifact', key: 'hanging-wire', sub_type: 'spell' },
                { type: 'artifact', key: 'roulette-eye', sub_type: 'spell' },
                { type: 'artifact', key: 'hell-fire', sub_type: 'spell' },
                { type: 'artifact', key: 'phantom-blade', sub_type: 'spell' },
                { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'gold-orb' }, { type: 'orb', key: 'gold-orb' },
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'ordinary-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'greater-spirit' },
            ])],
            newCards: [
                { type: 'artifact', key: 'hell-fire', sub_type: 'spell' },
                { type: 'artifact', key: 'phantom-blade', sub_type: 'spell' },
            ],
            intro: true,
        },
        {
            name: 'mirror mirror',
            reapers: {
                [acctUsername]: [getChar('bracket-boy')],
                computer: [getChar('hermona', true)]
            },
            hands: {
                [acctUsername]: [],
                computer: [{ type: 'artifact', key: 'magic-mirror', sub_type: 'charm' }]
            },
            deck: [...shuffle([
                { type: 'artifact', key: 'anti-chip-damage-charm', sub_type: 'charm' },
                { type: 'artifact', key: 'exchange-hands', sub_type: 'spell' },
                { type: 'artifact', key: 'hanging-wire', sub_type: 'spell' },
                { type: 'artifact', key: 'roulette-eye', sub_type: 'spell' },
                { type: 'artifact', key: 'hell-fire', sub_type: 'spell' },
                { type: 'artifact', key: 'phantom-blade', sub_type: 'spell' },
                { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'gold-orb' }, { type: 'orb', key: 'gold-orb' },
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'ordinary-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'greater-spirit' },
            ])],
            newCards: [
                { type: 'artifact', key: 'magic-mirror', sub_type: 'charm' },
                { type: 'artifact', key: 'exchange-hands', sub_type: 'spell' },
            ],
            intro: true,
        },
        {
            name: 'bite and suck',
            reapers: {
                [acctUsername]: [getChar('bracket-boy', false)],
                computer: [getChar('hermona', true)]
            },
            hands: {
                [acctUsername]: [],
                computer: []
            },
            deck: [...shuffle([
                { type: 'artifact', key: 'anti-chip-damage-charm', sub_type: 'charm' },
                { type: 'artifact', key: 'exchange-hands', sub_type: 'spell' },
                { type: 'artifact', key: 'hanging-wire', sub_type: 'spell' },
                { type: 'artifact', key: 'roulette-eye', sub_type: 'spell' },
                { type: 'artifact', key: 'hell-fire', sub_type: 'spell' },
                { type: 'artifact', key: 'frostbite', sub_type: 'spell' },
                { type: 'artifact', key: 'dream-sucker', sub_type: 'curse' },
                { type: 'artifact', key: 'phantom-blade', sub_type: 'spell' },
                { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'gold-orb' }, { type: 'orb', key: 'gold-orb' },
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'half-alive-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'ordinary-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'greater-spirit' },
            ])],
            newCards: [
                { type: 'artifact', key: 'frostbite', sub_type: 'spell' },
                { type: 'artifact', key: 'dream-sucker', sub_type: 'curse' },
            ],
            intro: true,
        },
        {
            name: 'hammer of justice',
            reapers: {
                [acctUsername]: [getChar('bracket-boy')],
                computer: [getChar('hermona', true)]
            },
            hands: {
                [acctUsername]: [],
                computer: []
            },
            deck: [...shuffle([
                { type: 'artifact', key: 'anti-chip-damage-charm', sub_type: 'charm' },
                { type: 'artifact', key: 'roulette-eye', sub_type: 'spell' },
                { type: 'artifact', key: 'hell-fire', sub_type: 'spell' },
                { type: 'artifact', key: 'frostbite', sub_type: 'spell' },
                { type: 'artifact', key: 'dream-sucker', sub_type: 'charm' },
                { type: 'artifact', key: 'phantom-blade', sub_type: 'spell' },
                { type: 'artifact', key: 'jacko-hammer', sub_type: 'spell' },
                { type: 'artifact', key: 'everdream', sub_type: 'charm' },
                { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'gold-orb' }, { type: 'orb', key: 'gold-orb' },
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'half-alive-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'ordinary-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'greater-spirit' },
            ])],
            newCards: [
                { type: 'artifact', key: 'chancellor-canceler', sub_type: 'curse' },
                { type: 'artifact', key: 'jacko-hammer', sub_type: 'spell' },
                { type: 'artifact', key: 'everdream', sub_type: 'charm' },
            ],
            intro: true,
        },
        {
            name: 'unknown',
            reapers: {
                [acctUsername]: [getChar('bracket-boy'), getChar('hermona', true)],
                computer: [getChar('combo-dia', true), getChar('swallia', true)]
            },
            hands: {
                [acctUsername]: [],
                computer: []
            },
            deck: [...shuffle([
                { type: 'artifact', key: 'anti-chip-damage-charm', sub_type: 'charm' },
                { type: 'artifact', key: 'roulette-eye', sub_type: 'spell' },
                { type: 'artifact', key: 'hell-fire', sub_type: 'spell' },
                { type: 'artifact', key: 'frostbite', sub_type: 'spell' },
                { type: 'artifact', key: 'dream-sucker', sub_type: 'charm' },
                { type: 'artifact', key: 'phantom-blade', sub_type: 'spell' },
                { type: 'artifact', key: 'jacko-hammer', sub_type: 'spell' },
                { type: 'artifact', key: 'magic-mirror', sub_type: 'charm' },
                { type: 'artifact', key: 'summon-em', sub_type: 'spell' },
                { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
                { type: 'orb', key: 'gold-orb' }, { type: 'orb', key: 'gold-orb' }, { type: 'orb', key: 'gold-orb' },
                { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
                { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
                { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'half-alive-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'ordinary-spirit' },
                { type: 'spirit', key: 'ordinary-spirit' }, { type: 'spirit', key: 'ordinary-spirit' },
                { type: 'spirit', key: 'half-dead-spirit' }, { type: 'spirit', key: 'greater-spirit' },
            ])],
            newCards: [
                { type: 'reaper', key: 'bracket-boy', sub_type: 'curse' },
                { type: 'spirit', key: 'half-dead-spirit' },
                { type: 'artifact', key: 'summon-em', sub_type: 'spell' },
            ],
            unlocks: [{
                key: 'bracket-boy'
            }],
            intro: true,
            addStar: true
        },
        // DEBUG
        // Test Level
        // {
        //     name: 'test',
        //     reapers: {
        //         [acctUsername]: [getChar('hermona')],
        //         computer: [getChar('hermona', true)]
        //     },
        //     hands: {
        //         [acctUsername]: [{ type: 'orb', key: 'green-orb' }],
        //         computer: [{ type: 'artifact', key: 'dream-sucker', sub_type: 'curse' },]
        //     },
        //     deck: [...shuffle([
        //         { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
        //         { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
        //         { type: 'spirit', key: 'lesser-spirit' }, { type: 'spirit', key: 'lesser-spirit' },
        //         // { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
        //         // { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
        //         // { type: 'orb', key: 'green-orb' }, { type: 'orb', key: 'green-orb' },
        //         // { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
        //         // { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
        //         // { type: 'orb', key: 'blue-orb' }, { type: 'orb', key: 'blue-orb' },
        //         // { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
        //         // { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
        //         // { type: 'orb', key: 'red-orb' }, { type: 'orb', key: 'red-orb' },
        //     ])],
        // }
    ]

    useEffect(() => {
        if (!isLoggedIn) navigate('/login')
    }, [isLoggedIn])

    useEffect(() => {
        if ($(`#lvl-${level}`).length > 0)
            $(`#lvl-${level}`)[0].scrollIntoView();
        if (completedLevels[3]) {
            SecureStoragePlugin.get({ key: 'firstRatingPrompted' }).then(() => { }).catch(err => {
                SecureStoragePlugin.set({ key: 'firstRatingPrompted', value: 'true' })
                RateApp.requestReview()
            })
        }
    }, [])

    const unlockables = levels[selected]?.newCards || []
    const opened = unlockables[galleryIdx]

    const enableAll = false // Debug
    const latestCompletedLevel = enableAll ? 999 : parseInt(Object.keys(completedLevels).sort().reverse()[0] || 0)

    return (
        <>
            <DeadNightSky />
            <div className="bg-smoke" />
            <Moon>
                <Eclipse />
            </Moon>
            <Mountain />
            <div className="fg-smoke" />
            <SettingsButton />
            <Wrapper offset={safearea.top + safearea.bottom}>
                <LevelsWrapper>
                    {levels.map((s, i) => {
                        return <LevelWrapper key={i} className={'level ' + (i === selected ? 'selected' : '') + (i === latestCompletedLevel ? ' glow' : '')} onClick={(e) => {
                            setLevel(i)
                            setScene(s)
                            setSelected(i)
                            setSfx(SelectMP3)
                        }}>
                            {completedLevels[i + 1] || i === latestCompletedLevel || enableAll ? <Vortex src={VortexGif} /> : <Lock src={LockedIcon} />}
                            {levels[i].addStar ? <Star src={StarIcon} /> : ''}
                            <LevelText>{i + 1}</LevelText>
                        </LevelWrapper>
                    })}
                </LevelsWrapper>
                <BottomWrapper>
                    {opened ? <ModalWrapper className="fade-in">
                        <Backdrop />
                        <ModalContent>
                            <Card card={levels[selected].newCards[galleryIdx]} size="big" />
                            <Padding />
                            <Cards>
                                {
                                    unlockables.map((c, i) => {
                                        return <Card className={galleryIdx === i ? 'selected' : ''} card={c} key={i} onClick={() => {
                                            setSfx(SelectMP3)
                                            setGalleryIdx(i)
                                        }} />
                                    })
                                }
                            </Cards>
                            <Button id="btn-back" className="button" onClick={() => {
                                setSfx(ButtonMP3)
                                setGalleryIdx(-1)
                            }}>{intl.get('ACTIONS.back')}</Button>
                        </ModalContent>
                    </ModalWrapper> : <>
                        <ProgressWrapper>
                            {latestCompletedLevel === 0 ? 0 : Math.floor(100 * latestCompletedLevel / levels.length)}% {intl.get('COMPLETED')}
                        </ProgressWrapper>
                        {unlockables.length > 0 ? <UnlockablesWrapper className="fade-in">
                            <Text>{intl.get('NEW_CARDS')}</Text>
                            <Padding />
                            <Cards>
                                {
                                    unlockables.map((c, i) => {
                                        return <Card className={galleryIdx === i ? 'selected' : ''} card={c} key={i} onClick={() => {
                                            setSfx(SelectMP3)
                                            setGalleryIdx(i)
                                        }} />
                                    })
                                }
                            </Cards>
                        </UnlockablesWrapper> : ''}
                        <Button id="btn-enter" className="button" onClick={() => {
                            if (selected === 0 || completedLevels[selected] || enableAll) {
                                setSfx(ButtonMP3)
                                navigate('/battle')
                                return
                            }
                            setSfx(ErrorMP3)
                            const ele = $('#btn-enter')
                            ele.addClass('failed')
                            ele[0].style.animationName = "none"
                            setTimeout(() => {
                                ele[0].style.animationName = ""
                            }, 0);
                        }}>{intl.get('ACTIONS.enter')}</Button>
                    </>}

                </BottomWrapper>
            </Wrapper>
        </>
    );
};