import axios from 'axios';
import { validateEmail } from 'lib/form';
import intl from 'react-intl-universal'

// const SERVER_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : 'https://teenreapers.com/api'
const SERVER_URL = 'https://teenreapers.com/api'

export const EmailOTP = (email) => {
    email = email.trim()
    return new Promise((resolve, reject) => {
        if (!validateEmail(email)) reject(intl.get('ERR.invalid_email'))
        axios.post(`${SERVER_URL}/email-otp`, {
            email
        }).then(() => {
            resolve(email)
        }).catch(err => {
            console.error(err)
            reject(intl.get('ERR.request_failed'))
        })
    })
}

export const ValidateEmailOTP = (email, otp) => {
    return new Promise((resolve, reject) => {
        axios.post(`${SERVER_URL}/validate-email-otp`, {
            email,
            otp
        }).then((result) => {
            resolve(result.data)
        }).catch(err => {
            console.error(err)
            reject(intl.get('ERR.request_failed'))
        })
    })
}

export const DeleteAccount = () => {
    return new Promise((resolve, reject) => {
        axios.post(`${SERVER_URL}/delete-account`).then((result) => {
            resolve(result.data)
        }).catch(err => {
            console.error(err)
            reject(intl.get('ERR.request_failed'))
        })
    })
}

export const AssertLoggedIn = () => {
    return new Promise((resolve, reject) => {
        axios.post(`${SERVER_URL}/assert-logged-in`).then((result) => {
            resolve(result.data)
        }).catch(err => {
            console.error(err)
            reject(intl.get('ERR.request_failed'))
        })
    })
}

export const RegisterProduct = (product_id) => {
    return new Promise((resolve, reject) => {
        axios.post(`${SERVER_URL}/register-product`, JSON.stringify({ product_id })).then((result) => {
            resolve(result.data)
        }).catch(err => {
            console.error(err)
            reject(intl.get('ERR.request_failed'))
        })
    })
}

export const ListPurchasedProducts = () => {
    return new Promise((resolve, reject) => {
        axios.post(`${SERVER_URL}/list-purchased-products`).then((result) => {
            resolve(result.data)
        }).catch(err => {
            console.error(err)
            reject(intl.get('ERR.request_failed'))
        })
    })
}
