import styled from "styled-components"
import 'anim.css'

import VSImg from 'assets/misc/vs.png'
import VortexGif from 'assets/misc/vortex.gif'
import { useSafearea } from "./page"

const CenterWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100vh - ${({ offset }) => offset}px);
    z-index: 10;
    background: rgba(0,0,0,0.8);
`

const Wrapper = styled.div`
    position: absolute;
    width: 50vh;
    max-width: 100%;
    height: calc(100vh - ${({ offset }) => offset}px);
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
`

const Char = styled.div.attrs(({ $count }) => ({
    $count
}))`
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    height: calc(50vh - ${({ offset }) => offset}px);
    width: ${({ $count }) => 100 / $count}%;
    max-width: 100%;
    display: inline-block;
`

const VS = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 100%;
`

const TopWrapper = styled.div`
    position: relative;
    text-align: center;
    z-index: 1;
`

const BottomWrapper = styled.div`
    position: relative;
    text-align: center;
`

const Vortex = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    height: 8vh;
    border-radius: 90px;
`

const PlayerName = styled.div`
    z-index: 5;
    position: fixed;
    font-size: 30px;
    background: #2f2f2f;
    padding: 0.2em 0.75em;
    border-radius: 50px;
    margin: 0.25em;
    border: solid 2px #FFFFFF;
`

const TopPlayerName = styled(PlayerName)`
    top: 0;
    left: 0;
`

const BottomPlayerName = styled(PlayerName)`
    bottom: 0;
    right: 0;
`

export const VSScreen = ({ reapers, players }) => {
    const { safearea } = useSafearea();
    return <CenterWrapper offset={safearea.top + safearea.bottom}>
        <Wrapper className="cmp-vs-screen fade-in" offset={safearea.top + safearea.bottom}>
            <VS src={VSImg} />
            <Vortex src={VortexGif} />
            <TopWrapper>
                {reapers[players[1].name].map((r, i) => {
                    return <Char key={i} $count={reapers[players[1].name].length} src={require(`assets/cards/reaper/${r.key}.jpg`)} offset={safearea.top} />
                })}
                <TopPlayerName>{players[1].name}</TopPlayerName>
            </TopWrapper>
            <BottomWrapper>
                {reapers[players[0].name].map((r, i) => {
                    return <Char key={i} $count={reapers[players[0].name].length} src={require(`assets/cards/reaper/${r.key}.jpg`)} offset={safearea.top} />
                })}
                <BottomPlayerName>{players[0].name}</BottomPlayerName>
            </BottomWrapper>
        </Wrapper>
    </CenterWrapper>
}