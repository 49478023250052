import styled from "styled-components";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Capacitor } from "@capacitor/core";
import { createContext, useContext, useState, useMemo, useEffect } from "react";
import 'index.css'

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  font-size: 1rem;
`;

const Content = styled.div.attrs(({ $top, $bottom }) => ({
    $top,
    $bottom,
}))`
  position: relative;
  width: 100%;
  height: calc(100vh - ${({ $top, $bottom }) => $top + $bottom}px);
`;

export const Padding = styled.div.attrs(({ $height }) => ({
    $height
}))`
    width: 100%;
    height: ${({ $height }) => $height}px;
`

export const SafeAreaCtx = createContext({});
export const useSafearea = () => useContext(SafeAreaCtx);

export const Page = props => {
    const { children } = props;
    const [safearea, setSafearea] = useState({ top: 0, bottom: 0 });

    useEffect(() => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            switch (Capacitor.getPlatform()) {
                case "ios":
                    setSafearea(insets);
                    break
            }
        });
    }, []);

    return (
        <SafeAreaCtx.Provider value={useMemo(() => ({ safearea }), [safearea])}>
            <Wrapper className="cmp-page">
                <Padding $height={safearea.top} />
                <Content $top={safearea.top} $bottom={safearea.bottom}>
                    {children}
                </Content>
                <Padding $height={safearea.bottom} />
            </Wrapper>
        </SafeAreaCtx.Provider>
    );
};

export const Backdrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.7);
`