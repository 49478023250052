import React from "react";
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";

import Princess from 'assets/misc/princess.png'
import Ball from 'assets/misc/ball.png'
import { Moon, Eclipse, Mountain, DeadNightSky } from 'components/art'

import intl from "react-intl-universal";
import TitleMp3 from 'assets/sounds/title.mp3'

import "anim.css"
import { Capacitor } from "@capacitor/core";
import { useAppContext } from "components/context";
import { Modal } from "components/modal";
import { Button } from "components/button";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";

const Wrapper = styled.div`
    position: absolute;
    left: 50%;
    bottom: 2vh;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
`

const Title = styled.div`
    font-size: 50px;
    font-family: Atalon;
`

const PrincessImg = styled.img`
    animation-delay: -2s;
    margin-bottom: 10vh;
`

const BallImg = styled.img`
    animation-delay: -1s;
    position: absolute;
    margin-left: -50px;
    margin-top: 100px;
`

const StyledButton = styled.div`
    margin: auto;
`

const AnimWrapper = styled.div`
    position: relative;
`

const TermsModal = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 350px;
`

const PromptBox = styled.div`
    margin-bottom: 1em;
    padding: 1em;
    background: #2F2F2F;
    border-radius: 5px;
`

const TermsPrompt = styled.div`
    font-size: 28px;
    text-align: center;
`

const Link = styled.div`
    text-align: center;
    margin: 0.5em;
    
    & * {
        font-size: 24px;
        color: white;
    }
`

export const TitlePage = ({ isGame }) => {
    const navigate = useNavigate();
    const { setSfx, termsAccepted, setTermsAccepted } = useAppContext();
    if (Capacitor.getPlatform() === 'web' && !isGame)
        window.location.href = '/landing.html'
    return (
        <>
            <DeadNightSky />
            <div className="bg-smoke" />
            <Moon>
                <Eclipse />
            </Moon>
            <Mountain />
            <div className="fg-smoke" />
            <Wrapper>
                <AnimWrapper className="slide-up">
                    <PrincessImg className="float" src={Princess} />
                    <BallImg className="float" src={Ball} />
                </AnimWrapper>
                <Title>Teen Reapers</Title>
                <StyledButton className="button" onClick={() => {
                    setSfx(TitleMp3)
                    navigate('/login')
                }}>{intl.get('PLAY')}</StyledButton>
            </Wrapper>
            {!termsAccepted ? <Modal>
                <TermsModal>
                    <PromptBox>
                        <TermsPrompt>{intl.get('TERMS.PROMPT')}</TermsPrompt>
                        <Link>
                            <a href="https://teenreapers.com/privacy.html" target="_blank">{intl.get('TERMS.PRIVACY')}</a>
                        </Link>
                        <Link>
                            <a href="https://teenreapers.com/terms-and-conditions.html" target="_blank">{intl.get('TERMS.TERMS')}</a>
                        </Link>
                    </PromptBox>
                    <Button onClick={() => {
                        setTermsAccepted(true)
                        SecureStoragePlugin.set({ key: 'termsPrompt', value: JSON.stringify(true) }).catch(err => { })
                    }}>{intl.get('ACTIONS.accept')}</Button>
                </TermsModal>
            </Modal> : ''}
        </>
    );
};