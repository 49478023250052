export const StoryTexts = ({ level, acctUsername }) => {
    switch (level) {
        case 0:
            return {
                introEles: ['#nothing', `#self-reapers`, '#opponent-reapers', '#deck', '#graveyard', '#your-hand', '#your-hand', '#nothing', '#deck', '#nothing', '#nothing'],
                introExplOffsets: [5, 45, 55, 25, 25, 25, 25, 20, 5, 10, 5],
                onIntroState: ({ introState, setTurnState, setResumeIntro, setHideHint, setPreventDrawOnPlay, setPreventPlayOnPlay }) => {
                    switch (introState) {
                        case 0:
                            setHideHint(true)
                            break
                        case 5:
                            setPreventDrawOnPlay(true)
                            setTurnState('next-player')
                            setResumeIntro({ state: 'play-or-draw' })
                            break
                        case 6:
                            setTurnState('play-or-draw')
                            setResumeIntro({ isOpponentTurn: true, state: 'cause-damage' })
                            break
                        case 7:
                            setPreventDrawOnPlay(false)
                            setTurnState('cause-damage')
                            setResumeIntro({ state: 'play-or-draw' })
                            break
                        case 8:
                            setPreventPlayOnPlay(true)
                            setTurnState('play-or-draw')
                            setResumeIntro({ state: 'discard' })
                            break
                        case 9:
                            setPreventPlayOnPlay(false)
                            setTurnState('discard')
                            setResumeIntro({ isYourTurn: true, state: 'draw' })
                            break
                        case 10:
                            setTurnState('draw')
                            setResumeIntro(false)
                            setHideHint(false)
                            break
                    }
                }
            }
        case 1:
        case 2:
        case 3:
            return {
                introEles: ['#nothing', '#nothing', '#nothing'],
                introExplOffsets: [5, 5, 5],
                onIntroState: ({ introState, setTurnState, setResumeIntro, setHideHint, setPreventDrawOnPlay }) => {
                    switch (introState) {
                        case 0:
                            setHideHint(false)
                            setPreventDrawOnPlay(true)
                            break
                        case 2:
                            setTurnState('draw')
                            setResumeIntro(false)
                            break
                    }
                }
            }
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
            return {
                introEles: ['#nothing', '#nothing', '#nothing'],
                introExplOffsets: [5, 5, 5],
                onIntroState: ({ introState, setTurnState, setResumeIntro, setHideHint, setPreventDrawOnPlay }) => {
                    switch (introState) {
                        case 0:
                            setHideHint(false)
                            setPreventDrawOnPlay(false)
                            break
                        case 2:
                            setTurnState('draw')
                            setResumeIntro(false)
                            break
                    }
                }
            }
    }
}